<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Reschedule Appointment</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" color="light" fxLayout="column" fxLayoutGap="24px">
  <div>
    <h2>
      {{ appointment.appointmentType.name }} for
      {{ appointment.patients[0].firstName }}
      {{ appointment.patients[0].lastName }}
    </h2>
    <h3>Currently on {{ appointment.startDateTime | date : 'short' }}</h3>
  </div>

  <div class="form-wrapper">
    <app-change-appointment-type-form
      [originalAppointment]="appointment"
      [form]="form"
    ></app-change-appointment-type-form>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button [disabled]="!form.valid" (click)="submit()" color="secondary" aria-label="submit">
      <ion-icon name="checkmark"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
