import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { UtilityService } from '../../../core/services/utility.service';
import { AlertService } from '../../../core/services/alert.service';
import {
  AppointmentService,
  AppointmentTypeFormatContract,
  AvailableAppointmentType,
  AvailableAppointmentTypesRequest,
  ProviderAvailabilityForTime,
  ProviderCalendarSummary,
  SchedulingErrorState,
} from '../../../core/services/appointment.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDateForApi } from '@utility';
import { State } from '@enums';

export type AppointmentSchedulingFormValues = VideoOrChatAppointmentValues;

export interface VideoOrChatAppointmentValues {
  appointmentTypeId: number;
  providerId: number | null;
  appointmentDate: Date;
  appointmentTimeSlot: ProviderAvailabilityForTime;
  note: string;
}

@Component({
  selector: 'app-appointment-scheduling-form',
  templateUrl: './appointment-scheduling-form.component.html',
  styleUrls: ['./appointment-scheduling-form.component.scss'],
})
export class AppointmentSchedulingFormComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup = AppointmentSchedulingFormComponent.formModel();
  @Input() selectedState: State;
  @Input() intakeSubmissionId: string;
  @Input() schedulingErrorState: SchedulingErrorState;

  @Output() resetErrorState = new EventEmitter();

  public loadingTimesSlotsForSelection = false;
  public showNoAppointSlotsAvailableMessage = true;
  public displayRetry = false;
  public minDate: string;
  public maxDate: string;

  public availableAppointmentTypes: Observable<AvailableAppointmentType[]>;
  public availableTimeSlots: Observable<ProviderAvailabilityForTime[]>;
  public availableProviders: Observable<ProviderCalendarSummary[]>;

  public ngModelSelectedAppointmentTypeId: number = null;
  // public datePickerConfig = {
  //   inputDate: new Date(),
  //   fromDate: new Date(),
  //   toDate: DateTime.local().plus({ days: 30 }).toJSDate(),
  //   titleLabel: 'Date',
  //   dateFormat: 'YYYY-MM-DD',
  //   weeksList: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  //   mondayFirst: false,
  //   closeOnSelect: true,
  //   setLabel: 'OK',
  //   todayLabel: 'TODAY',
  //   closeLabel: 'CANCEL',
  //   clearButton: false,
  //   btnProperties: {
  //     fill: 'clear',
  //     size: 'small',
  //     color: 'primary',
  //   },
  // };

  private readonly errorId = -9999;

  private currentIntakeSubmissionId = new BehaviorSubject<string>(null);
  private selectedAppointmentTypeId = new BehaviorSubject<number>(null);
  private selectedProviderId = new BehaviorSubject<number>(null);
  private selectedDateAndTime = new BehaviorSubject<DateTime>(DateTime.now());

  constructor(
    private alertService: AlertService,
    private appointmentService: AppointmentService,
    public utilityService: UtilityService
  ) {}

  static formModel() {
    return new UntypedFormGroup({
      appointmentTypeId: new UntypedFormControl(undefined, Validators.required),
      providerId: new UntypedFormControl(undefined),
      appointmentDate: new UntypedFormControl(new Date(), Validators.required),
      appointmentTimeSlot: new UntypedFormControl(undefined, Validators.required),
      note: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.currentIntakeSubmissionId.next(this.intakeSubmissionId);

    // Set up the pipe that will change available providers when the appointment type changes.
    this.availableProviders = combineLatest([this.selectedAppointmentTypeId, this.currentIntakeSubmissionId]).pipe(
      switchMap(([currentAppointmentTypeId, currentIntakeSubmissionId]) => {
        if (currentAppointmentTypeId && currentIntakeSubmissionId) {
          return this.appointmentService.getCalendars({
            appointmentTypeId: currentAppointmentTypeId,
            intakeSubmissionId: currentIntakeSubmissionId,
            visitState: this.selectedState,
          });
        } else {
          return [];
        }
      }),
      catchError((error) => {
        return this.handleAvailabilityRequestError(error);
      }),
      shareReplay(1),
      startWith([])
    );
    this.availableTimeSlots = this.createAvailabilityObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.intakeSubmissionId && changes.intakeSubmissionId.currentValue) {
      this.onIntakeSubmissionChange();
    }

    if (changes.schedulingErrorState && changes.schedulingErrorState.currentValue) {
      this.displayRetry = true;

      // Take the randomly generated error type and apply an error state for it.
      switch (changes.schedulingErrorState.currentValue as SchedulingErrorState) {
        case SchedulingErrorState.APPOINTMENT_TYPES:
          this.currentIntakeSubmissionId.next(this.errorId + '');
          break;
        case SchedulingErrorState.AVAILABILITY:
          this.selectedProviderId.next(this.errorId);
          break;
        case SchedulingErrorState.CALENDARS:
          this.selectedAppointmentTypeId.next(this.errorId);
          break;
        default:
          // This scenario shouldn't happen...
          this.selectedAppointmentTypeId.next(this.errorId);
          break;
      }
    }

    this.onAppointmentTypeChange();
  }

  onIntakeSubmissionChange() {
    if (this.intakeSubmissionId !== this.currentIntakeSubmissionId.value) {
      this.currentIntakeSubmissionId.next(this.intakeSubmissionId);
    }

    const availableAppointmentTypesRequest: AvailableAppointmentTypesRequest = {
      intakeSubmissionId: this.currentIntakeSubmissionId.value,
    };

    this.availableAppointmentTypes = this.appointmentService
      .getAvailableAppointmentTypes(availableAppointmentTypesRequest)
      .pipe(
        map((response) => {
          const orderedAppointmentTypes = this.appointmentService.orderAvailableAppointmentTypes(
            response.appointmentTypes
          );

          if (orderedAppointmentTypes && orderedAppointmentTypes.length > 0) {
            this.ngModelSelectedAppointmentTypeId = orderedAppointmentTypes[0].id;
            this.onAppointmentTypeChange();
          }

          return orderedAppointmentTypes;
        }),
        catchError((error) => {
          return this.handleAvailabilityRequestError(error);
        })
      );
  }

  onAppointmentTypeChange() {
    if (
      this.ngModelSelectedAppointmentTypeId &&
      this.selectedAppointmentTypeId.value !== this.ngModelSelectedAppointmentTypeId &&
      this.selectedAppointmentTypeId.value !== this.errorId
    ) {
      this.selectedAppointmentTypeId.next(this.ngModelSelectedAppointmentTypeId);
    }
  }

  onDateChange(change: MatDatepickerInputEvent<Date>) {
    if (change && change.value) {
      const selectedDate = moment(
        formatDateForApi(change.value.getFullYear(), change.value.getMonth() + 1, change.value.getDate())
      );
      this.selectedDateAndTime.next(DateTime.fromMillis(selectedDate.valueOf()));
    } else {
      console.error('Error in appointment date selection.');
    }
  }

  onSelectedProviderChange(change: CustomEvent) {
    const providerId = change.detail.value;
    this.selectedProviderId.next(providerId);
  }

  handleRetryRequest() {
    // Reset is in order of operations required to get time slots:
    //   1. Get appointment types for a valid intake submission ID.
    //   2. Get calendars with valid intake submission ID and date.
    //   3. Get availability with reset types, calendars, providers and date.
    this.currentIntakeSubmissionId.next(this.intakeSubmissionId);
    this.onIntakeSubmissionChange();

    this.availableTimeSlots = this.createAvailabilityObservable();
    this.selectedAppointmentTypeId.next(this.ngModelSelectedAppointmentTypeId);
    this.onAppointmentTypeChange();

    this.selectedProviderId.next(this.form.controls.providerId.value);

    setTimeout(() => {
      this.displayRetry = false;
      this.resetErrorState.emit();
    });
  }

  getAppointmentTypeDisplayText(appointmentType: AvailableAppointmentType): string {
    if (appointmentType) {
      return `${AppointmentTypeFormatContract[appointmentType.formatType]}: ${
        appointmentType.appointmentDurationInMinutes
      } min`;
    }

    // This should never reach this point.  If so, we have some other issue that needs to be fixed.
    return 'Unavailable';
  }

  /**
   * Returns an observable that emits available time slots
   * for the selected appointment type, calendar and date
   */
  private createAvailabilityObservable(): Observable<ProviderAvailabilityForTime[]> {
    return combineLatest(
      this.selectedAppointmentTypeId,
      this.selectedProviderId,
      this.selectedDateAndTime,
      this.availableProviders,
      this.currentIntakeSubmissionId
    ).pipe(
      tap(() => {
        setTimeout(() => {
          this.loadingTimesSlotsForSelection = true;
          this.form.controls.appointmentTimeSlot.setValue(null);
        });
      }),
      switchMap(([appointmentTypeId, providerId, date, availableProviders, currentIntakeSubmissionId]) => {
        if (currentIntakeSubmissionId === null) {
          // Error scenario only.  We will essentially skip this request and wait for the intake submission ID to reset.
          return of([]);
        }

        if (availableProviders.length === 0) {
          return of([]);
        }
        if (providerId) {
          if (providerId === this.errorId) {
            return of(
              this.handleAvailabilityRequestError(new Error('Availability requested for an invalid provider.'))
            );
          }
          return this.appointmentService.getAvailability(
            date,
            appointmentTypeId,
            currentIntakeSubmissionId,
            this.selectedState,
            [providerId]
          );
        }

        // Get availability of all providers.
        return this.appointmentService.getAvailability(
          date,
          appointmentTypeId,
          currentIntakeSubmissionId,
          this.selectedState,
          availableProviders.map((p) => p.providerId)
        );
      }),
      catchError((error) => {
        return of(this.handleAvailabilityRequestError(error));
      }),
      tap((timeSlots) => {
        this.showNoAppointSlotsAvailableMessage = timeSlots.length === 0;
      }),
      tap(() => {
        setTimeout(() => {
          this.loadingTimesSlotsForSelection = false;
        });
      })
    );
  }

  private handleAvailabilityRequestError(error: any): any[] {
    this.alertService.error('Error loading available appointment times, please try again.');
    return [];
  }
}
