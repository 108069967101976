<div>
  <div class="patient-app-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div>
      <app-title-bar></app-title-bar>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="patient-app-content-header">
        <ion-toolbar>
          <ion-segment [value]="chatService.activeChatChannel">
            <ion-segment-button value="provider" (click)="setChannelToProvider()">
              Care Team
              <span style="color: red" *ngIf="chatService.providerUnreadCount | async"
                >({{ chatService.providerUnreadCount | async }})</span
              >
            </ion-segment-button>
            <ion-segment-button value="coordinator" (click)="setChannelToCoordinator()">
              Patient Support
              <span style="color: red" *ngIf="chatService.coordinatorUnreadCount | async"
                >({{ chatService.coordinatorUnreadCount | async }})</span
              >
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </div>
      <div class="patient-app-content">
        <div class="chat-container ph-no-capture" (scroll)="checkIfScrollNeeded($event)">
          <div class="ion-text-center reset-button" *ngIf="showResetScroll"></div>
          <div
            #providerContainer
            id="provider-chat-container"
            class="message-area ion-padding"
            [hidden]="channel !== channelType.provider"
          >
            <div class="message-box-container" *ngFor="let message of providerMessages | async">
              <div [ngClass]="{ staff: message.fromNiceStaff, patient: !message.fromNiceStaff }">
                <div class="message-box" inViewport (inViewportAction)="onIntersection(message, $event)">
                  <div class="sender">
                    <p>{{ message.senderName }}&nbsp;&nbsp;&nbsp;{{ message.createdOn | date : 'M/d/yy h:mm a' }}</p>
                  </div>
                  <div class="message">
                    <p
                      class="message-text"
                      [innerHTML]="message.text | linky : { className: 'chat-link', stripPrefix: false }"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            #coordinatorContainer
            id="coordinator-chat-container"
            class="message-area ion-padding"
            [hidden]="channel !== channelType.coordinator"
          >
            <div *ngFor="let message of coordinatorMessages | async">
              <div [ngClass]="{ staff: message.fromNiceStaff, patient: !message.fromNiceStaff }">
                <div class="message-box" inViewport (inViewportAction)="onIntersection(message, $event)">
                  <div class="sender">
                    <p>{{ message.senderName }}&nbsp;&nbsp;&nbsp;{{ message.createdOn | date : 'M/d/yy h:mm a' }}</p>
                  </div>
                  <div class="message">
                    <p
                      class="message-text"
                      [innerHTML]="message.text | linky : { className: 'chat-link', stripPrefix: false }"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="patient-app-content-footer">
  <ion-footer>
    <ion-toolbar>
      <ion-grid class="send-container">
        <ion-row>
          <ion-col size="9">
            <ion-textarea
              [disabled]="!(patientIsActive | async) && channel === channelType.provider"
              (keyup.enter)="sendMessage()"
              autocomplete="“true”"
              autocapitalize="sentences"
              spellcheck="“true”"
              type="text"
              placeholder="Write a message"
              [formControl]="input"
            ></ion-textarea>
            <div *ngIf="input.invalid" class="input-error">
              {{ forbiddenErrorMessage }}
            </div>
          </ion-col>
          <ion-col size="3" class="ion-text-end">
            <app-progress-button
              [disabled]="
                !input ||
                input.invalid ||
                (emptyMessage | async) ||
                (!(patientIsActive | async) && channel === channelType.provider)
              "
              text="Send"
              [submitting]="sending"
              (submit)="sendMessage()"
            ></app-progress-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</div>
