import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsPageComponent } from './tabs-page.component';
import { AppointmentsPageModule } from '../pages/appointments/appointments.module';
import { DocumentsPageModule } from '../pages/documents/documents.module';
import { PatientsPageModule } from '../pages/patients/patients.module';
import { ChatPageModule } from '../pages/chat/chat.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AppointmentsPageModule,
    DocumentsPageModule,
    PatientsPageModule,
    ChatPageModule,
  ],
  declarations: [TabsPageComponent],
})
export class TabsPageModule {}
