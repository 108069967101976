import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from '../../core/version';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryConfig.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        environment.apiUrl,
        environment.apiV2Url,
        environment.apiV3Url,
        environment.webUrl,
        environment.niceServiceUrl,
        environment.returnToUrl,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  release: `${version.current}`,
  environment: environment.sentryConfig.environmentName,
  // Set tracesSampleRate to 1.0 to capture 100% in dev, 20% in prod.
  tracesSampleRate: environment.sentryConfig.sampleRate,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
