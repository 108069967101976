export enum ChartTypeEnum {
  medical,
  mentalHealth,
}

export enum ChartPlanProceduresRequested {
  headToToe = 'Head to Toe Exam w/Vitals',
  basicStrep = 'Strep Exam (ENT, Heart, Lungs, Vitals',
  basicEar = 'Ear Exam (ENT, Heart, Lungs, Vitals)',
  basicRespiratory = 'Respiratory Exam (ENT, Heart, Lungs, Vitals)',
  diabetic = 'Monofilament Test',
  thyroid = 'Thyroid Exam',
  abdomen = 'Abdominal Exam',
  musculoskeletal = 'Musculoskeletal Exam',
  wartTreatment = 'Wart Treatment',
  nebulizer = 'Nebulizer',
  peakFlowMeter = 'Peak Flow Measurement',
  orthostatic = 'Orthostatic Vitals',
  earLavage = 'Ear Lavage',
  sutureRemoval = 'Suture Removal',
  physicalSportsPreOpEtc = 'Physical (Sports, PreOp, etc)',
}

export enum ChartPlanInHousePocLabs {
  rapidStrepA = 'Rapid Strep A',
  dipstick = 'Dipstick UA',
  flu = 'Flu A&B',
  monoSpot = 'Mono Spot',
  urinePregnancyTest = 'Urine Pregnancy Test',
}

export enum ChartPlanInterventions {
  affectIdentificationAndExpression = 'Affect Identification and Expression',
  angerManagement = 'Anger Management',
  assertivenessTraining = 'Assertiveness Training',
  behavioralActivation = 'Behavioral Activation',
  cognitiveRestructuring = 'Cognitive Restructuring',
  communicationTraining = 'Communication Training',
  decisionOptionExploring = 'Decision Option Exploring',
  empathy = 'Empathy',
  engageSignificantOtherInTreatment = 'Engage Significant Other in Treatment',
  griefWork = 'Grief Work',
  imageryRelaxationTraining = 'Imagery/Relaxation Training',
  medicationManagement = 'Medication Management',
  parentResponseToTechniques = 'Parent Response to Techniques',
  patternIdentificationAndInterruption = 'Pattern Identification and Interruption',
  preventiveStrategies = 'Preventive Strategies',
  problemSolvingSkillsTraining = 'Problem Solving Skills Training',
  psychoeducation = 'Psychoeducation',
  referralPlanned = 'Referral Planned',
  relapsePrevention = 'Relapse Prevention',
  selfOtherBoundariesTraining = 'Self/Other Boundaries Training',
  skillBuilding = 'Skill Building',
  solutionFocusedTechniques = 'Solution Focused Techniques',
  stressManagement = 'Stress Management',
  supportiveTherapy = 'Supportive Therapy',
  validation = 'Validation',
}

export enum ChartEpisodeOfCareClientResources {
  attendanceTo12StepMeetings = 'Attendance to 12 Step Meetings',
  determination = 'Determination',
  effectiveCommunicationSkills = 'Effective Communication Skills',
  effectiveCopingSkills = 'Effective Coping Skills',
  effectiveFinancialManagementSkills = 'Effective Financial Management Skills',
  intelligence = 'Intelligence',
  internalMotivation = 'Internal Motivation',
  honesty = 'Honesty',
  hopefulness = 'Hopefulness',
  maturity = 'Maturity',
  openMinded = 'Open Minded',
  patience = 'Patience',
  resiliency = 'Resiliency',
  selfAdvocacy = 'Self Advocacy',
  selfConfidenceGoodSelfEsteem = 'Self Confidence/Good Self-Esteem',
  stableEmployment = 'Stable Employment',
  strongFaithConnection = 'Strong Faith Connection',
  supportiveFamilyFriends = 'Supportive Family, Friends, Etc',
}
