import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../../shared/shared.module';

import { AddPatientComponent } from './add-patient/add-patient.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { PatientsPageComponent } from './patients-page.component';
import { MatCardModule } from '@angular/material/card';

const routes: Routes = [
  {
    path: '',
    component: PatientsPageComponent,
  },
  // {
  //   path: 'add',
  //   component: AddPatientComponent
  // },
  // {
  //   path: 'edit/:patientId',
  //   component: EditPatientComponent
  // }
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModule, MatCardModule],
  declarations: [AddPatientComponent, EditPatientComponent, PatientCardComponent, PatientsPageComponent],
})
export class PatientsPageModule {}
