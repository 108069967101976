import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';

import { environment } from '../../../environments/environment';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';
import { IdleService } from './idle.service';
import { ClientConfiguration } from '../../../../../core/version';
import { VersionService } from './version.service';
import { setUserId } from '../utilities/analytics';
import { logUserLoggedInEvent } from '../utilities/analyticsHelper';
import { NewPatientSummary, SignUpRequest } from '@dto';

export interface AuthState {
  accessToken: string;
  profile: { sub: string; email?: string; email_verified?: boolean };
  tokenPayload: { iat: number };
  scope: string;
  expiresAt: Date;
}

export interface RefreshTokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: string;
  token_type: string;
}

enum Scope {
  IdentityCreate = 'identity:CreateIdentities',
  IdentityRead = 'identity:ReadIdentities',
  IdentityUpdate = 'identity:UpdateIdentities',
  IdentityReadAddress = 'identity:ReadAddress',
  IdentityUpdateAddress = 'identity:UpdateAddress',
  IdentityDeleteAddress = 'identity:DeleteAddress',
  ScheduleCreate = 'schedule:CreateSchedules',
  ScheduleRead = 'schedule:ReadSchedules',
  ScheduleRequestRead = 'schedule:ReadScheduleRequest',
  ScheduleRequestCreate = 'schedule:CreateScheduleRequest',
  ScheduleRequestUpdate = 'schedule:UpdateScheduleRequest',
}

/**
 * Creates a space-delimited string of scopes from our {@link Scope} enum values.
 *
 * @return a space-delimited string of scopes.
 */
function getAllScopes(baseScopes?: string): string {
  const scopes: string[] = Object.values(Scope);
  // Add default scopes
  if (baseScopes && baseScopes.length > 0) {
    return scopes.join(' ') + ' ' + baseScopes;
  } else {
    return scopes.join(' ');
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _auth0Client: auth0.WebAuth;
  private _auth0Config: ClientConfiguration;

  private _authState = new BehaviorSubject<AuthState>(null);
  public authState = this._authState.asObservable();

  private _initialized = new BehaviorSubject<boolean>(null);
  public readonly initialized = this._initialized.asObservable().pipe(
    filter((initialized) => !!initialized),
    take(1)
  );

  public redirectUrl: string = null;

  public refreshSubscription: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private idleService: IdleService,
    private versionService: VersionService
  ) {
    this._authState.subscribe((authState) => {
      if (authState === null) {
        return;
      }

      if (this.redirectUrl) {
        return this.router.navigateByUrl(this.redirectUrl).then(() => (this.redirectUrl = null));
      }
    });

    this.initializeAuth0Client().then(() => {
      this.checkForExistingSession()
        .then(async (session) => {
          await this.setSession(session);
        })
        .finally(() => {
          this._initialized.next(true);
        });
    });
  }

  get currentAuthState() {
    return this._authState.getValue();
  }

  get loading() {
    return this._initialized.getValue() === null;
  }

  public isAuthenticated(): boolean {
    const authState = this._authState.getValue();

    if (authState === null) {
      return false;
    }

    const expiresAt = authState.expiresAt.getTime();
    return new Date().getTime() < expiresAt;
  }

  public getAuthorizationToken(): string | null {
    const authState = this._authState.getValue();

    if (authState === null) {
      return null;
    }

    const expiresAt = authState.expiresAt.getTime();
    if (new Date().getTime() > expiresAt) {
      alert('Your session has expired, and we have logged you out to protect your privacy.');
      this.logout();
      return null;
    }

    return this._authState.getValue().accessToken;
  }

  public async handleAuthentication(): Promise<void> {
    await this.initializeAuth0Client();
    const path = window.location.href.replace(window.location.origin, '');
    if (path !== '/session/callback' && path !== '/callback') {
      this.redirectUrl = path;
    }
    this._auth0Client.parseHash(async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        await this.setSession(authResult);
        logUserLoggedInEvent(true);
        if (this.redirectUrl) {
          await this.router.navigateByUrl(this.redirectUrl);
          this.redirectUrl = null;
        } else {
          this.router.navigate(['/']);
        }
      } else if (err) {
        logUserLoggedInEvent(false);
        this.router.navigate(['/login']);
      }
    });
  }

  public login(target?: string, scopes?: string[]): Promise<any> {
    return this.webLoginFlow(target, scopes);
  }

  public signUp(newPatientData: SignUpRequest): Observable<NewPatientSummary> {
    return this.http
      .post<NewPatientSummary>(`${environment.niceServiceUrl}/v2/users/sign-up`, newPatientData)
      .pipe(catchError(this.handleError));
  }

  private checkAuthIsInitialized() {
    if (!this._auth0Config) {
      throw Error('Auth0 configuration not initialized.');
    }
  }

  private async webLoginFlow(target?: string, additionalScopes?: string[]) {
    await this.initializeAuth0Client();
    const redirectUri = this.getBaseRedirectUri().concat(target ? `/${target}` : '/callback');

    // Doesn't request offline_access scope.
    const baseScopes = 'openid profile';
    const allScopes: string = additionalScopes
      ? getAllScopes(baseScopes).concat(' ').concat(additionalScopes.join(' '))
      : getAllScopes(baseScopes);
    this._auth0Client.authorize({
      ...this._auth0Config,
      redirectUri,
      scope: allScopes,
      prompt: additionalScopes ? 'login' : undefined,
    });
  }

  private async setSession(authResult: auth0.Auth0DecodedHash) {
    // This block preserves the "email" scope from any previous authentication request.  This is to avoid timing issues
    // with various authentication requests overwriting an already approved email update scoped token.
    const currentScopes = this._authState.getValue() ? this._authState.getValue().scope?.split(' ') : [];
    if (currentScopes && currentScopes.includes('email')) {
      return;
    }

    const profile = (await this.getUserInfo(authResult.accessToken)) as auth0.Auth0UserProfile;
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

    // Setup Amplitude with the current logged-in user ID.
    setUserId(profile.sub);

    this._authState.next({
      profile,
      scope: authResult.scope,
      accessToken: authResult.accessToken,
      tokenPayload: authResult.idTokenPayload,
      expiresAt: new Date(parseInt(expiresAt, 10)),
    });

    this.scheduleRenewal();

    return;
  }

  private async getUserInfo(accessToken: string) {
    await this.initializeAuth0Client();
    return new Promise((resolve, reject) => {
      this._auth0Client.client.userInfo(accessToken, (err, profile) => {
        if (err) {
          reject(err);
        }

        resolve(profile);
      });
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // todo: A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // Use the error message from the server.
      if (error.status === 400) {
        return throwError(error?.error?.message);
      }
    }

    // Throw a default error
    return throwError('Hmm, something went wrong. Please try again in a moment...');
  }

  private scheduleRenewal() {
    if (!this.isAuthenticated()) {
      return;
    }
    this.unscheduleRenewal();

    const authState = this._authState.getValue();

    if (authState === null) {
      return null;
    }

    const expiresAt = authState.expiresAt.getTime();

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap((expires) => {
        const now = Date.now();
        const renewTimerBuffer = 60000;
        const timeUntilRenewal = expires - (now + renewTimerBuffer);
        const refreshTimer = timer(Math.max(1, timeUntilRenewal));
        return refreshTimer;
      })
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(() => {
      if (this.idleService.isIdle && !this.idleService.inVideoCall) {
        this.unscheduleRenewal();
        this.logout();
      } else {
        this.idleService.isIdle = true;
        this.renewTokens();
      }
    });
  }

  private unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private async renewTokens(): Promise<void> {
    return this.renewTokenUsingSilentAuthentication();
  }

  private async renewTokenUsingSilentAuthentication() {
    await this.initializeAuth0Client();
    this._auth0Client.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        alert('Your session has expired. Please login again.');
        this.unscheduleRenewal();
        this.logout();
      }
    });
  }

  public async initiatePasswordReset() {
    const { profile } = this.currentAuthState;
    await this.initializeAuth0Client();
    await new Promise((resolve, reject) => {
      this._auth0Client.changePassword(
        {
          connection: environment.newAuth0PatientDatabaseConnection,
          email: profile.email,
        },
        (error, result) => {
          if (error) {
            return reject(error);
          }
          resolve(result);
        }
      );
    });
  }

  public async isSignUpDisabled(): Promise<boolean> {
    await this.initializeAuth0Client();
    return this._auth0Config.isSignUpDisabled;
  }

  public async logout() {
    this.redirectUrl = null;
    this._authState.next(null);
    this.router.navigate(['/login']);

    if (this._auth0Client) {
      this._auth0Client.logout({
        returnTo: environment.returnToUrl,
        clientID: this._auth0Config ? this._auth0Config.clientID : undefined,
      });
    }
  }

  private async initializeAuth0Client(): Promise<auth0.WebAuth> {
    if (!this._auth0Client) {
      this._auth0Config = await this.versionService.getVersionConfig();
      // Special handling of client ID for the native builds.  The Auth0 interface expects "clientId", not "clientID".
      this._auth0Config.clientId = this._auth0Config.clientID;
      this._auth0Client = new auth0.WebAuth({
        ...this._auth0Config,
        scope: this.getDefaultScopes(),
        redirectUri: this.getBaseRedirectUri().concat('/callback'),
      });
    }

    return this._auth0Client;
  }

  private checkForExistingSession() {
    this.checkAuthIsInitialized();
    return new Promise((resolve, reject) => {
      this._auth0Client.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  private getDefaultScopes(additionalScopes?: string[]) {
    const baseScopes = 'openid profile offline_access';
    const allScopes: string = additionalScopes
      ? getAllScopes(baseScopes).concat(' ').concat(additionalScopes.join(' '))
      : getAllScopes(baseScopes);
    return allScopes;
  }

  private getBaseRedirectUri() {
    return `${window.location.protocol}//${window.location.host}`;
  }
}
