import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';

import { TimeSlotWithCalendars } from '@dto';
import { AlertService } from '../../../core/services/alert.service';
import { UtilityService } from '../../../core/services/utility.service';
import moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDateForApi } from '@utility';
import {
  AppointmentService,
  PatientAppointment,
  ProviderCalendarSummary,
} from '../../../core/services/appointment.service';
import { DateTime } from 'luxon';

export interface ChangeAppointmentTypeFormValues {
  appointmentType: keyof typeof PatientBookableAppointmentType;
  appointmentId: string;
  providerId?: number;
  appointmentDate: Date;
  appointmentTimeSlot: TimeSlotWithCalendars;
}

enum PatientBookableAppointmentType {
  video = 'Video',
  chat = 'Chat',
}

@Component({
  selector: 'app-change-appointment-type-form',
  templateUrl: './change-appointment-type-form.component.html',
  styleUrls: ['./change-appointment-type-form.component.scss'],
})
export class ChangeAppointmentTypeFormComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() originalAppointment: PatientAppointment;

  private appointmentId = new ReplaySubject<string>(1);
  private selectedProviderId = new BehaviorSubject<number>(null);
  private selectedDate = new BehaviorSubject<DateTime>(DateTime.now());

  public loadingTimesSlotsForSelection = false;
  public showNoAppointSlotsAvailableMessage = true;
  public minDate: string;
  public maxDate: string;
  public appointmentTypesEnum = PatientBookableAppointmentType;
  public availableTimeSlots: Observable<TimeSlotWithCalendars[]>;
  public availableProviders: Observable<ProviderCalendarSummary[]>;

  // public datePickerConfig = {
  //   inputDate: new Date(),
  //   fromDate: new Date(),
  //   toDate: DateTime.local().plus({ days: 30 }).toJSDate(),
  //   titleLabel: 'Date',
  //   dateFormat: 'YYYY-MM-DD',
  //   weeksList: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  //   mondayFirst: false,
  //   closeOnSelect: true,
  //   setLabel: 'OK',
  //   todayLabel: 'TODAY',
  //   closeLabel: 'CANCEL',
  //   clearButton: false,
  //   btnProperties: {
  //     fill: 'clear',
  //     size: 'small',
  //     color: 'primary',
  //   },
  // };

  constructor(
    private alertService: AlertService,
    private appointmentService: AppointmentService,
    public utilityService: UtilityService
  ) {}

  static formModel(initValues: Partial<ChangeAppointmentTypeFormValues> = {}) {
    const defaultAppointmentType = initValues.appointmentType || null;
    const defaultAppointmentDate = initValues.appointmentDate || new Date();
    return new UntypedFormGroup({
      appointmentType: new UntypedFormControl(defaultAppointmentType, Validators.required),
      appointmentId: new UntypedFormControl(initValues.appointmentId, Validators.required),
      providerId: new UntypedFormControl(initValues.providerId),
      appointmentDate: new UntypedFormControl(defaultAppointmentDate, Validators.required),
      appointmentTimeSlot: new UntypedFormControl(initValues.appointmentTimeSlot, Validators.required),
    });
  }

  ngOnInit() {
    const initialDate = new Date(this.form.controls['appointmentDate'].value);
    const selectedDate = moment(
      formatDateForApi(initialDate.getFullYear(), initialDate.getMonth() + 1, initialDate.getDate())
    );
    this.selectedDate.next(DateTime.fromMillis(selectedDate.valueOf()));
    this.selectedProviderId.next(this.form.controls['providerId'].value);

    this.availableProviders = this.appointmentId.pipe(
      switchMap((appointmentId) => {
        return this.appointmentService.getCalendarsForReschedule(appointmentId);
      }),
      startWith([])
    );
    this.availableTimeSlots = this.createAvailabilityObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { appointmentId } = this.form.value;

    if (appointmentId) {
      this.appointmentId.next(appointmentId);
    }
  }

  onDateChange(change: MatDatepickerInputEvent<Date>) {
    if (change && change.value) {
      const selectedDate = moment(
        formatDateForApi(change.value.getFullYear(), change.value.getMonth() + 1, change.value.getDate())
      );
      this.selectedDate.next(DateTime.fromMillis(selectedDate.valueOf()));
    } else {
      console.error('Error in appointment type selection.');
    }
  }

  onCalenderSelectionChange(change: CustomEvent) {
    const providerId = change.detail.value;
    this.selectedProviderId.next(providerId);
  }

  /**
   * Returns an observable that emits available time slots
   * for the selected appointment type, calendar and date
   */
  private createAvailabilityObservable(): Observable<TimeSlotWithCalendars[]> {
    return combineLatest(this.appointmentId, this.selectedProviderId, this.selectedDate, this.availableProviders).pipe(
      tap(() => {
        this.loadingTimesSlotsForSelection = true;
        this.form.controls.appointmentTimeSlot.setValue(null);
      }),
      switchMap(([appointmentId, providerId, date, providers]) => {
        if (providers.length === 0) {
          return of([]);
        }

        if (providerId) {
          return this.appointmentService.getAvailabilityForReschedule(date, appointmentId, [providerId]);
        }

        // Get availability of all providers.
        return this.appointmentService.getAvailabilityForReschedule(
          date,
          appointmentId,
          providers.map((p) => p.providerId)
        );
      }),
      catchError(() => {
        this.alertService.error('Error loading time slots, please try again.');
        return [];
      }),
      tap((slots) => (this.showNoAppointSlotsAvailableMessage = slots.length === 0)),
      tap(() => (this.loadingTimesSlotsForSelection = false))
    );
  }
}
