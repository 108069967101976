import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import { ChatService } from '../../core/services/chat.service';
import { ChannelType } from '../../../../../core/enums/message';
import { MessageDto } from '../../../../../core/dto/message';
import { Patient } from '../../../../../core/models/patient';
import { PatientService } from '../../core/services/patient.service';
import { forbiddenCharValidator } from '../../shared/forms/validators';
import {
  USER_INPUT_FORBIDDEN_CHARS,
  USER_INPUT_FORBIDDEN_UI_MESSAGE,
} from '../../../../../core/constants/forbidden-characters';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss'],
})
export class ChatPageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('providerContainer', { static: true })
  providerContainer: ElementRef;
  @ViewChild('coordinatorContainer', { static: true })
  coordinatorContainer: ElementRef;

  public forbiddenErrorMessage = USER_INPUT_FORBIDDEN_UI_MESSAGE;
  public providerAutoScroll = true;
  public coordinatorAutoScroll = true;

  public emptyMessage: Observable<boolean>;

  public sending = false;

  public channel = ChannelType.provider;
  public channelType = ChannelType;
  public providerMessages: Observable<MessageDto[]>;
  public coordinatorMessages: Observable<MessageDto[]>;

  private messageCount = 0;

  public input = new UntypedFormControl('', [forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)]);
  public patientIsActive: Observable<boolean>;

  constructor(public chatService: ChatService, private patientService: PatientService, private route: ActivatedRoute) {}

  get showResetScroll() {
    return (
      (this.channel === ChannelType.provider && !this.providerAutoScroll) ||
      (this.channel === ChannelType.coordinator && !this.coordinatorAutoScroll)
    );
  }

  ngOnInit() {
    this.providerMessages = this.chatService.providerMessages;
    this.coordinatorMessages = this.chatService.coordinatorMessages;
    this.patientIsActive = this.patientService.activePatient.pipe(
      map((patient: Patient): boolean => {
        return patient && patient.active;
      })
    );

    this.route.queryParamMap.subscribe((params) => {
      // todo: this should perhaps only do something if the channel queryparam is set,
      // otherwise, maintain previous state?
      const channel = params.get('channel');
      if (channel === 'provider') {
        this.setChannelToProvider();
      } else {
        this.setChannelToCoordinator();
      }
    });

    this.emptyMessage = this.input.valueChanges.pipe(
      map((value: string) => (value ? value.trim().length === 0 : true)),
      startWith(true)
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollToBottom(this.providerContainer);
      this.scrollToBottom(this.coordinatorContainer);
    }, 500);
  }

  ngAfterViewChecked(): void {
    // TODO This should likely be turned into some sort of timer, though it gets tricky to understand when the user doesn't want to scroll.
    //   if (this.providerAutoScroll) {
    //     this.scrollToBottom(this.providerContainer);
    //   }
    //   if (this.coordinatorAutoScroll) {
    //     this.scrollToBottom(this.coordinatorContainer);
    //   }
  }

  // Function used in unit tests
  public setChatValue(value: string | null) {
    this.input.setValue(value);
  }

  public setChannelToProvider() {
    this.chatService.activeChatChannel = 'provider';
    this.channel = this.channelType.provider;
    this.scrollToBottom(this.providerContainer);
  }

  public setChannelToCoordinator() {
    this.chatService.activeChatChannel = 'coordinator';
    this.channel = this.channelType.coordinator;
    this.scrollToBottom(this.coordinatorContainer);
  }

  public sendMessage() {
    if (this.input.valid) {
      this.sending = true;
      const channelString = this.channelType[this.channel];
      this[`${channelString}AutoScroll`] = true;
      this.scrollToBottom(this[`${channelString}Container`]);

      this.chatService.sendMessage(this.input.value, this.channel).then(() => {
        this.input.setValue('');
        this.sending = false;
      });
    }
  }

  public scrollToBottom(element: ElementRef) {
    setTimeout(() => {
      const nativeElement = element?.nativeElement?.parentElement;
      if (
        nativeElement?.scrollHeight &&
        nativeElement.scrollHeight > 0 &&
        nativeElement.scrollTop !== nativeElement.scrollHeight
      ) {
        nativeElement.scrollTop = nativeElement.scrollHeight;
      }
    });
  }

  public disableAutoScroll() {
    this.providerAutoScroll = false;
    this.coordinatorAutoScroll = false;
  }

  public enableAutoScroll() {
    this.providerAutoScroll = true;
    this.coordinatorAutoScroll = true;
  }

  checkIfScrollNeeded(event: any) {
    // TODO This needs to be reviewed and potentially adjusted before turning back on. Current form scrolls more than desired.
    //   visible height + pixel scrolled >= total height
    //   If the user has scrolled at least one screen up, then we disable auto scrolling.
    //   if (event.target.scrollHeight - event.target.scrollTop >= (1.25 * event.target.offsetHeight)) {
    //     this.disableAutoScroll();
    //   } else {
    //     this.enableAutoScroll();
    //   }
  }

  public onIntersection(message: MessageDto, event: { target: Element; visible: boolean }) {
    // Need to handle:
    // -- No Unread Messages
    // -- Unread messages all in viewport
    // -- Unread messages extending past view port

    if (event.visible === true && message.readByPatient === false) {
      this.chatService.markMessageRead(message);
    }
  }
}
