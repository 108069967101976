import { createTheme } from '@mui/material/styles';
import { colorPaletteThemeOptions } from './colors';
import { typographyComponentThemeOptions } from './components';
import { typographyThemeOptions } from './typography';

export const theme = createTheme({
  palette: colorPaletteThemeOptions,
  typography: typographyThemeOptions,
  components: typographyComponentThemeOptions,
});

export default theme;
