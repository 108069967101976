import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { FormNode } from '../../../../../../core/formly-typeform/typeform-parser';

import { DynamicFormSchema } from '../../../core/services/intake-form.service';
import { Platform } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Patient } from '@models';

export interface IntakeFormState {
  isValid: boolean;
  model: Object;
  isComplete: boolean;
}

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.scss'],
})
export class IntakeFormComponent implements OnInit, AfterContentChecked {
  @Input() public model: any = {};
  @Input() public patient: Patient;
  @Input() private formSchema: DynamicFormSchema;

  public step: string;
  public form = new UntypedFormGroup({});
  public fields: FormNode[] = [];
  public options: FormlyFormOptions = {};
  public progress = new BehaviorSubject<FormNode[]>([]);
  private questionNumber = 0;
  // Can we skip the current step? If step has no form controls, this will be null
  public skippable = null;
  public alreadyVisited = false;
  private visitedSteps: string[] = [];

  @Output() formState = new EventEmitter<IntakeFormState>();

  constructor(private platform: Platform, private focusMonitor: FocusMonitor) {}

  get currentStep(): FormNode {
    return this.progress.getValue()[this.progress.getValue().length - 1];
  }

  get previousStep(): FormNode {
    if (this.progress.getValue().length - 2 >= 0) {
      return this.progress.getValue()[this.progress.getValue().length - 2];
    } else {
      return this.progress.getValue()[0];
    }
  }

  get formTree() {
    return this.formSchema.formTree;
  }

  get intakeFormId(): number {
    return this.formSchema.metadata.formDefinitionId;
  }

  ngOnInit() {
    this.progress.next([this.formTree[this.formSchema.entryNodeId]]);
    this.step = this.formSchema.entryNodeId;
    this.progress
      .pipe(
        tap((fields) => {
          this.options = { formState: { currentStepKey: this.step } };
          this.form = new UntypedFormGroup({});
          this.model = cloneDeep(this.model);
          this.fields = cloneDeep(fields);

          const field = this.fields[this.fields.length - 1];
          const requiredValue = field.templateOptions.required;

          this.skippable = requiredValue === undefined ? null : !requiredValue;
        })
      )
      .subscribe((fields) => {
        this.formState.emit({
          isValid: this.form.valid,
          model: this.model,
          isComplete: false,
        });
      });
  }

  ngAfterContentChecked(): void {
    if (this.platform.is('ios')) {
      const radioGroupElements = document.getElementsByClassName('mat-radio-button');
      const checkBoxElements = document.getElementsByClassName('mat-checkbox');
      this.stopMonitoringElementCollection(radioGroupElements);
      this.stopMonitoringElementCollection(checkBoxElements);
    }
  }

  private stopMonitoringElementCollection(collection: HTMLCollectionOf<any>) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection.item(i);
      this.focusMonitor.stopMonitoring(element);
    }
  }

  public next() {
    // Save the place we are leaving in the visited fields list, but only when advancing
    const field = this.fields[this.fields.length - 1];
    if (typeof field.key === 'string') {
      if (!this.visitedSteps.includes(field.key)) {
        this.visitedSteps.push(field.key);
      }
    }

    this.questionNumber++;
    const nextRef = this.currentStep.next(this.model);
    this.step = nextRef;
    if (nextRef === null) {
      return this.submit();
    }
    this.progress.next([...this.progress.getValue(), this.formTree[nextRef]]);
  }

  public back() {
    this.questionNumber--;
    const currentProgress = this.progress.getValue();
    this.step = currentProgress[currentProgress.length - 2].key as string;
    const fields = currentProgress.slice(0, currentProgress.length - 1);
    this.progress.next(fields);
  }

  public submit() {
    this.formState.emit({
      isValid: this.form.valid,
      model: this.model,
      isComplete: true,
    });
  }
}
