<ion-list [formGroup]="form">
  <ion-item>
    <ion-label position="floating" color="tertiary">{{ header }}</ion-label>
    <ion-select
      formControlName="selectedState"
      [placeholder]="placeholder"
      [interfaceOptions]="utilityService.interfaceOptions"
    >
      <ion-select-option *ngFor="let state of states" [value]="state">
        <span class="ph-no-capture">
          {{ state.valueOf() }}
        </span>
      </ion-select-option>
    </ion-select>
    <ion-note *ngIf="hint">{{ hint }}</ion-note>
  </ion-item>
</ion-list>
