export function formatDateForApi(year: number, month: number, day: number): string {
  return `${year}-${padZero(month)}-${padZero(day)}`;
}

export function padZero(numberToPad: number, digitLengthToPadUntil: number = 2): string {
  let paddedValue = `${numberToPad}`;
  while (paddedValue.length < digitLengthToPadUntil) {
    paddedValue = `0${paddedValue}`;
  }
  return paddedValue;
}

export function formatStandardDate(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}
export function formatLocalDateFromDatabase(dateToFormat: string | number | Date): string {
  const date = new Date(dateToFormat);
  if (!isDateValid(date)) {
    return dateToFormat?.toString();
  }
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return formatLocalDate(localDate);
}
export function formatLocalDate(date: Date): string {
  return `${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}/${date.getFullYear()}`;
}

export function parseStandardDate(date: string): Date {
  const dateParts = date?.trim()?.split('-') || [];
  if (dateParts.length === 3) {
    return new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10));
  }

  throw new Error(`Invalid date: ${date}`);
}

export function calculateAge(dateOfBirth: string, targetDateOfCalculation = new Date()): string {
  const birthDate = parseStandardDate(dateOfBirth);
  if (!isDateValid(birthDate)) {
    return 'Age unknown';
  }

  const ageStrings = [];

  const years = calculateCalendarYearsDifference(birthDate, targetDateOfCalculation, true);

  if (years !== 0) {
    ageStrings.push(`${years} Year`);
  }

  if (years < 3) {
    const monthNum = calculateCalendarMonthsDifference(birthDate, targetDateOfCalculation, true);
    ageStrings.push(`${monthNum} Month`);
  }

  ageStrings.push('Old');

  return ageStrings.join(' ');
}

export function isDateValid(date: Date | string | number) {
  return !isNaN(new Date(date).getDate());
}

export interface DateDifference {
  years: number;
  months: number;
  days: number;
}

function validateDateRange(fromDate: Date, toDate: Date) {
  if (fromDate.getTime() > toDate.getTime()) {
    throw Error('From date is ahead of to date.');
  }
}

export function calculateDifference(fromDate: Date, toDate: Date): DateDifference {
  // This currently deals with timezones as given and does not normalize.
  const calculateFromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
  const calculateToDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());

  validateDateRange(calculateFromDate, calculateToDate);

  const yearsDifference = calculateCalendarYearsDifference(calculateFromDate, calculateToDate);
  const monthsDifference = calculateCalendarMonthsDifference(calculateFromDate, calculateToDate);
  const daysDifference = calculateCalendarDaysDifference(calculateFromDate, calculateToDate);

  return {
    years: yearsDifference,
    months: monthsDifference,
    days: daysDifference,
  };
}

export function calculateCalendarYearsDifference(fromDate: Date, toDate: Date, calculateAge: boolean = false) {
  validateDateRange(fromDate, toDate);

  const isOnOrPastTargetDate = onOrPastTargetCalendarDate(fromDate, toDate, calculateAge);

  let yearsDifference = toDate.getFullYear() - fromDate.getFullYear();

  // We only need to subtract a year if we are calculating age, otherwise just use the year number difference.
  yearsDifference = !calculateAge || isOnOrPastTargetDate ? yearsDifference : yearsDifference - 1;

  if (yearsDifference === -1) {
    // If we are less than 12 months apart, set the years as 0 (minimum).
    yearsDifference = 0;
  }

  return yearsDifference;
}

export function calculateCalendarMonthsDifference(fromDate: Date, toDate: Date, calculateAge: boolean = false) {
  validateDateRange(fromDate, toDate);

  const fromDateMonth = calculateAge ? toDate.getMonth() : fromDate.getMonth();
  const toDateMonth = calculateAge ? fromDate.getMonth() : toDate.getMonth();
  const isPastTargetDate = fromDate.getDate() > toDate.getDate();
  let months;

  if (calculateAge) {
    months = toDateMonth >= fromDateMonth ? 12 - (toDateMonth - fromDateMonth) : fromDateMonth - toDateMonth;
  } else {
    months = toDateMonth >= fromDateMonth ? toDateMonth - fromDateMonth : 12 - (fromDateMonth - toDateMonth);
  }

  if (isPastTargetDate) {
    // We must subtract one from the month count if within a month of days.
    months = months - 1;

    // If we are in the same month, we are now 11 months difference.
    if (months === -1) {
      months = 11;
    }
  } else if (months === 12) {
    // A full year if the target date is past means it is 0 months past.  Time will be included in the year value.
    months = 0;
  }

  return months;
}

export function calculateCalendarDaysDifference(fromDate: Date, toDate: Date, calculateAge: boolean = false): number {
  validateDateRange(fromDate, toDate);
  const isPastTargetDate = calculateAge ? toDate.getDate() > fromDate.getDate() : fromDate.getDate() > toDate.getDate();

  return isPastTargetDate
    ? getDaysRemainingInTargetMonth(fromDate) + toDate.getDate()
    : toDate.getDate() - fromDate.getDate();
}

export function daysInTargetMonth(targetDate: Date) {
  return new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0).getDate();
}

function getDaysRemainingInTargetMonth(targetDate: Date) {
  return daysInTargetMonth(targetDate) - targetDate.getDate();
}

/**
 * Determines if a given "from" date is equal to or greater than a target date in months and days, which is used to
 * calculate age.
 *
 * @param dateToCheck the date to compare against the target date.
 * @param targetCalendarDate the target date that we want to find out if our given date is on or after.
 * @param calculateAge true if we are looking for an age calculation where we need to invert the checks for what is
 * being compared, false for the normal .
 *
 * @return true if the given date to check is on or after the target date on the calendar (months and days).
 */
export function onOrPastTargetCalendarDate(
  dateToCheck: Date,
  targetCalendarDate: Date,
  calculateAge: boolean = false,
): boolean {
  if (!calculateAge) {
    validateDateRange(dateToCheck, targetCalendarDate);
  }

  let isOnOrPastTargetDate = false;

  if (calculateAge) {
    if (targetCalendarDate.getMonth() > dateToCheck.getMonth()) {
      isOnOrPastTargetDate = true;
    } else if (
      // Does not currently include hours, minutes, etc. in the calculation.
      targetCalendarDate.getMonth() === dateToCheck.getMonth() &&
      targetCalendarDate.getDate() >= dateToCheck.getDate()
    ) {
      isOnOrPastTargetDate = true;
    }
  } else {
    if (dateToCheck.getMonth() > targetCalendarDate.getMonth()) {
      isOnOrPastTargetDate = true;
    } else if (
      // Does not currently include hours, minutes, etc. in the calculation.
      dateToCheck.getMonth() === targetCalendarDate.getMonth() &&
      dateToCheck.getDate() >= targetCalendarDate.getDate()
    ) {
      isOnOrPastTargetDate = true;
    }
  }

  return isOnOrPastTargetDate;
}
