import React, { PropsWithChildren } from 'react';
import { Router } from '@angular/router';
import NavigationMenuItem from '../NavigationMenuItem/NavigationMenuItem';
import { MenuItem } from '../menuItem';

type SidebarNavigationMenuProps = PropsWithChildren<{
  router: Router;
  menuItems: MenuItem[];
}>;

export function SidebarNavigationMenu({ router, menuItems }: SidebarNavigationMenuProps): React.JSX.Element {
  return (
    <>
      {menuItems.map((menuItem, index) => (
        <NavigationMenuItem
          key={index}
          router={router}
          menuItem={{
            title: menuItem.title,
            internalLink: menuItem.internalLink,
            externalLink: menuItem.externalLink,
            icon: menuItem.icon,
          }}
        />
      ))}
    </>
  );
}

export default SidebarNavigationMenu;
