import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { DateTime, Duration } from 'luxon';

import { VideoService } from '../../../core/services/video.service';
import { AlertService } from '../../../core/services/alert.service';
import { RescheduleAppointmentComponent } from '../reschedule-appointment/reschedule-appointment.component';
import { AppointmentService, PatientAppointment } from '../../../core/services/appointment.service';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointment: PatientAppointment;
  @Input() public upcomingAppointment: boolean;
  public canCancel = true;
  public canReschedule = true;
  public isInRescheduleWindow = true;
  public isInCancelWindow = true;
  private appointmentVideoId;

  constructor(
    private router: Router,
    private videoService: VideoService,
    private appointmentService: AppointmentService,
    private modalController: ModalController,
    private alertController: AlertController,
    private alertService: AlertService
  ) {}

  get isVideoAppointment(): boolean {
    return this.appointment.appointmentType.appointmentTypeFormat === 'VIDEO';
  }

  get isChatAppointment(): boolean {
    return this.appointment.appointmentType.appointmentTypeFormat === 'CHAT';
  }

  get isFutureAppointment(): boolean {
    const appointmentDate = DateTime.fromISO(this.appointment.startDateTime);
    const isFuture = appointmentDate >= DateTime.local();
    const isToday = appointmentDate.toISODate() === DateTime.local().toISODate();
    return isFuture || isToday;
  }

  ngOnInit() {
    this.canReschedule = this.appointment.appointmentType.patientCanReschedule;
    this.canCancel = this.appointment.appointmentType.patientCanCancel;
    this.isInRescheduleWindow = this.checkIfAppointmentIsWithinRescheduleWindow();
    this.isInCancelWindow = this.checkIfAppointmentIsWithinCancellationWindow();

    this.appointmentVideoId = this.appointment.patients[0].id;
  }

  launchVideo() {
    this.videoService.launchVideo(this.appointmentVideoId);
  }

  goToChat() {
    this.router.navigate(['/tabs/chat'], {
      queryParams: {
        channel: 'provider',
      },
    });
  }

  public async rescheduleAppointment() {
    const modal = await this.modalController.create({
      component: RescheduleAppointmentComponent,
      componentProps: { appointment: this.appointment },
      cssClass: 'reschedule-modal',
    });
    return await modal.present();
  }

  public async cancelAppointment(appointmentId: string) {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: 'Are you sure you would like to cancel this appointment?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.alertService.loading();
            await this.appointmentService
              .cancelAppointment(appointmentId)
              .toPromise()
              .then(() => {
                this.alertService.hideLoading();
                this.alertService.success('Successfully cancelled appointment');
              })
              .catch((error) => {
                this.alertService.hideLoading();
                this.alertService.error('An error occurred while canceling the appointment. Please try again.');
                console.error('An error occurred while canceling the appointment. ', error);
              });
          },
        },
      ],
    });

    await alert.present();
  }

  private checkIfAppointmentIsWithinRescheduleWindow() {
    const appointmentDate = DateTime.fromISO(this.appointment.startDateTime);
    return (
      appointmentDate.diffNow() >
      Duration.fromObject({ seconds: this.appointment.appointmentType.patientRescheduleLeadTime.seconds })
    );
  }

  private checkIfAppointmentIsWithinCancellationWindow() {
    const appointmentDate = DateTime.fromISO(this.appointment.startDateTime);
    return (
      appointmentDate.diffNow() >
      Duration.fromObject({ seconds: this.appointment.appointmentType.patientCancelLeadTime.seconds })
    );
  }
}
