import { FormlyFieldConfig } from '@ngx-formly/core';
import { Typeform } from '@typeform/api-client';
import { AbstractControl } from '@angular/forms';

export interface FormTree {
  [ref: string]: FormNode;
}

export interface FormNode extends FormlyFieldConfig {
  next: ((model: any) => string) | null;
  buttonText: string | null;
}

export interface ConditionVar {
  /**
   * Type of value the condition object refers to.
   */
  type?: 'field' | 'hidden' | 'variable' | 'constant' | 'end' | 'choice';
  /**
   * Value to check for in the "type" field to evaluate with the operator.
   */
  value?: any;
}

export class TypeformParser {
  static parseFormTree(
    fields: Typeform.Field[],
    logic: Typeform.Logic[],
    tree: FormTree = {},
    parentNextNodeRef?: string,
  ): FormTree {
    if (fields.length === 0) {
      // no fields left to parse
      return tree;
    }

    // Clone the input fields
    // eslint-disable-next-line no-param-reassign
    fields = [...fields];

    const field = fields.shift();
    let nextNodeRef;

    if (field && field.properties && field.properties.fields && field.type === 'group') {
      nextNodeRef = field.properties.fields[0].ref || (fields[0] && fields[0].ref) || parentNextNodeRef || null;
      // assign the group nodes
      this.parseFormTree(field.properties.fields, logic, tree, (fields[0] && fields[0].ref) || undefined);
    } else {
      nextNodeRef = (fields[0] && fields[0].ref) || parentNextNodeRef || null;
    }

    if (field?.ref) {
      tree[field.ref] = {
        ...this.getFormlyMaterialFieldFromTypeformField(field),
        next: this.getNextFunction(field.ref, nextNodeRef, logic),
        buttonText: field?.properties?.button_text as string,
      };
    }

    return this.parseFormTree(fields, logic, tree, parentNextNodeRef);
  }

  static parseWelcomeScreen(
    welcomeScreen: Typeform.WelcomeScreen,
    logic: Typeform.Logic[],
    defaultFirstNode?: string,
  ): FormNode {
    return {
      ...this.createStatementField(
        welcomeScreen.title as string,
        welcomeScreen.properties?.description as string,
        welcomeScreen.ref as string,
      ),
      buttonText: 'Continue',
      next: this.getNextFunction(
        welcomeScreen.ref as string,
        defaultFirstNode as string,
        this.getLogicForTypeformField(welcomeScreen.ref as string, logic),
      ),
    };
  }

  static createStatementField(statement: string, description: string, ref: string): FormlyFieldConfig {
    let templateString: string;

    if (description) {
      // TODO: Note that the parsing of the link won't work for most changes to the state service are screen.
      //   We can address it if we want to invest more time into redoing Thank You screens in general in this app.
      templateString = `<h3>${statement}</h3>
        <p>${description.replace(
          '[service map](https://www.nice.healthcare/locations)',
          '<a href="https://www.nice.healthcare/locations" target="_blank">service map</a>',
        )}</p>`;
    } else {
      templateString = `<h3>${statement}</h3>`;
    }

    return {
      key: ref,
      template: templateString,
      templateOptions: {
        label: statement,
      },
      hideExpression: (model, state, field) => state.currentStepKey !== field?.key,
    };
  }

  static getNextFunction(
    currentNodeRef: string,
    defaultNodeRef: string,
    logic: Typeform.Logic[],
  ): (model: any) => string {
    const [node] = this.getLogicForTypeformField(currentNodeRef, logic);
    const jumpActions = node && node.actions?.filter((action) => action.action === 'jump');
    if (!jumpActions || jumpActions.length === 0) {
      return () => defaultNodeRef;
    }

    return (model: any): string => {
      if (node) {
        for (const action of node.actions as Typeform.Action[]) {
          if (this.evaluateCondition(model, action.condition as Typeform.Condition)) {
            return this.getActionTo(action) as string;
          }
        }
      }

      return defaultNodeRef;
    };
  }

  static evaluateCondition(model: any, condition: Typeform.Condition | Typeform.AndOrOperator): boolean {
    try {
      if (condition.op === 'always') {
        return true;
      }
      if (condition.op === 'or') {
        return condition.vars.some((nestedCondition) => this.evaluateCondition(model, nestedCondition));
      }
      if (condition.op === 'and') {
        return condition.vars.every((nestedCondition) => this.evaluateCondition(model, nestedCondition));
      }

      /**
       * String Comparisons
       */
      if (condition.op === 'begins_with') {
        const [a, b] = this.getConditionVars(model, condition);
        if (typeof a === 'string') {
          const index = a.indexOf(b);
          return index === 0;
        }
        return false;
      }
      if (condition.op === 'contains') {
        const [a, b] = this.getConditionVars(model, condition);
        if (typeof a === 'string') {
          return a.includes(b);
        }
        return false;
      }
      if (condition.op === 'ends_with') {
        const [a, b] = this.getConditionVars(model, condition);
        if (typeof a === 'string') {
          return a.endsWith(b);
        }
        return false;
      }
      if (condition.op === 'not_contains') {
        const [a, b] = this.getConditionVars(model, condition);
        if (typeof a === 'string') {
          return !a.includes(b);
        }
      }

      /**
       * Date Comparisons
       */
      if (condition.op === 'earlier_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a < b;
      }
      if (condition.op === 'earlier_than_or_on') {
        const [a, b] = this.getConditionVars(model, condition);
        return a <= b;
      }
      if (condition.op === 'later_than_or_on') {
        const [a, b] = this.getConditionVars(model, condition);
        return a >= b;
      }
      if (condition.op === 'later_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a > b;
      }
      if (condition.op === 'on') {
        const [a, b] = this.getConditionVars(model, condition);
        return a === b;
      }
      if (condition.op === 'not_on') {
        const [a, b] = this.getConditionVars(model, condition);
        return a !== b;
      }

      /**
       * Generic equality
       */
      if (condition.op === 'equal' || condition.op === 'is') {
        const [a, b] = this.getConditionVars(model, condition);
        if (a instanceof Array) {
          return a.some((val) => val === b);
        }
        if (a instanceof Object) {
          return !!a[b];
        }
        return a === b;
      }
      if (condition.op === 'is_not' || condition.op === 'not_equal') {
        const [a, b] = this.getConditionVars(model, condition);
        if (a instanceof Array) {
          return a.every((val) => val !== b);
        }
        if (a instanceof Object) {
          return !a[b];
        }
        return a !== b;
      }

      /**
       * Numeric comparison
       */
      if (condition.op === 'greater_equal_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a >= b;
      }
      if (condition.op === 'greater_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a > b;
      }
      if (condition.op === 'lower_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a < b;
      }
      if (condition.op === 'lower_equal_than') {
        const [a, b] = this.getConditionVars(model, condition);
        return a <= b;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static getConditionVars(model: any, condition: Typeform.Condition) {
    return condition.vars ? condition.vars.map((condVar) => this.getConditionVar(model, condVar)) : [];
  }

  static getConditionVar(model: any, conditionVar: ConditionVar) {
    switch (conditionVar.type) {
      case 'field':
      case 'hidden':
        return model[conditionVar.value];
      case 'constant':
      case 'choice':
        return conditionVar.value;
      default:
        console.error('Unsupported condition.type: ', conditionVar.type);
        throw new Error('UNSUPPORTED_CONDITION_VARIABLE_TYPE');
    }
  }

  static getActionTo(action: Typeform.Action) {
    const to = action.details?.to;

    if (to?.type === 'thankyou') {
      return to.value;
    }

    return to?.value;
  }

  static getFormlyMaterialFieldFromTypeformField(field: Typeform.Field): FormlyFieldConfig {
    const newField: Partial<FormlyFieldConfig> = {
      key: field.ref,
      validators: [],
      templateOptions: {
        label: field.title,
        description: (field.properties && field.properties.description) || undefined,
        required: field.validations && field.validations.required,
      },
      hideExpression: (model, state, field) => state.currentStepKey !== field?.key,
    };

    switch (field.type) {
      case 'date':
        newField.type = 'datepicker';
        return newField;
      case 'dropdown':
        newField.type = 'select';
        newField.templateOptions = {
          ...newField.templateOptions,
          multiple: field?.properties?.allow_multiple_selection,
          options: field?.properties?.choices?.map((choice) => ({
            value: choice.ref,
            label: choice.label,
          })),
        };
        return newField;
      case 'multiple_choice':
        if (field?.properties?.allow_multiple_selection) {
          newField.type = 'multicheckbox';
          newField.templateOptions = {
            ...newField.templateOptions,
            options: field?.properties?.choices?.map((choice) => ({
              value: choice.ref,
              label: choice.label,
            })),
          };
        } else {
          newField.type = 'radio';
          newField.templateOptions = {
            ...newField.templateOptions,
            options: field?.properties?.choices?.map((choice) => ({
              value: choice.ref,
              label: choice.label,
            })),
          };
        }

        return newField;
      case 'email':
        newField.type = 'input';
        if (newField.validators) {
          newField.validators['email'] = TypeformParser.isEmail;
        }
        return newField;
      case 'group':
      case 'statement':
        const description = (field.properties && field.properties.description) || '';
        const formattedDescription = description.replace(/\n/g, '<br/>');
        newField.template = `
            <h3>${field.title}</h3>
            <p>${formattedDescription}</p>
          `;
        return newField;
      case 'long_text':
        newField.type = 'textarea';
        newField.templateOptions = {
          ...newField.templateOptions,
          autosize: true,
          autosizeMaxRows: 6,
        };
        return newField;
      case 'number':
        newField.type = 'input';
        if (newField.validators) {
          newField.validators['number'] = TypeformParser.isNumber;
        }
        return newField;
      case 'rating':
      case 'opinion_scale':
        const start = field?.properties?.start_at_one ? 1 : 0;
        newField.type = 'radio';
        const options = [];
        const steps = field?.properties?.steps ? field.properties.steps + start : start;
        for (let i = start; i < steps; i++) {
          options.push({ value: i, label: i });
        }
        const opinionScaleLabels = (field.properties && field.properties.labels) || null;
        const formattedLabels = opinionScaleLabels
          ? `${start} = ${opinionScaleLabels.left} ... ${steps - 1} = ${opinionScaleLabels.right}`
          : '';
        newField.wrappers = ['opinion-wrapper'];
        newField.templateOptions = {
          ...newField.templateOptions,
          description: formattedLabels,
          options,
        };
        return newField;
      case 'short_text':
        newField.type = 'input';
        return newField;
      case 'yes_no':
        newField.type = 'radio';
        newField.templateOptions = {
          ...newField.templateOptions,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
        };
        return newField;
      default:
        throw new Error('UNSUPPORTED_TYPEFORM_FIELD_TYPE_SUPPLIED');
    }
  }

  static getLogicForTypeformField(ref: string, logic: Typeform.Logic[]): Typeform.Logic[] {
    if (logic) {
      return logic.filter((node) => node.ref === ref);
    }

    return [];
  }

  static emailRegEx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

  static isEmail(stringToCheck: string): boolean {
    // Regular expression to check if string is email
    return TypeformParser.emailRegEx.test(stringToCheck);
  }

  // TODO Validation is not currently working in this form - 03/01/2024.
  static isNumber(control: AbstractControl, field: FormlyFieldConfig): boolean {
    return true;
    // return !isNaN(Number(field.model));
  }
}
