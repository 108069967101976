<div class="form-group" [class.has-error]="showError">
  <h5 *ngIf="to.label && to.hideLabel !== true" [attr.for]="id">
    {{ to.label }}
    <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
  </h5>
  <br />
  <div *ngIf="to.description" class="form-text text-muted">{{ to.description }}</div>
  <br />
  <ng-template #fieldComponent></ng-template>
  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
</div>
