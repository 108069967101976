import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { connect, Room } from 'twilio-video';

import { VideoToken } from '../../../../../core/dto/video';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private platform: Platform,
    private router: Router,
  ) {}

  public async launchVideo(videoId): Promise<boolean> {
    return this.router.navigateByUrl(`/video/${videoId}`);
  }

  public getVideoToken(videoId: string): Observable<VideoToken> {
    return this.httpClient.get<VideoToken>(
      `${environment.apiUrl}/video/patient/${videoId}`,
    );
  }

  public async connect(roomId: string): Promise<Room> {
    if (roomId === undefined || roomId === null || roomId === '') {
      throw new Error('Invalid room ID: ' + roomId);
    }

    const response = await this.getVideoToken(roomId).toPromise();

    if (response === undefined || response === null) {
      throw new Error('Invalid response for room ID: ' + roomId);
    } else if (
      response.token === undefined ||
      response.token === null ||
      response.token === ''
    ) {
      throw new Error('Invalid video token for room ID: ' + roomId);
    } else if (
      response.room === undefined ||
      response.room === null ||
      response.room === ''
    ) {
      throw new Error('Invalid room response for room ID: ' + roomId);
    }

    return connect(response.token, {
      audio: true,
      name: response.room,
      video: { width: 1280 },
    });
  }
}
