export const sampleHealthHistoryIncompleteIntakeAnswers = {
  'health-history-form-completed': false,
  'patient-age': 42,
  'sex-assigned-at-birth': 'sex-assigned-at-birth-answer-male',
  'medical-conditions': {
    'medical-conditions-answer-high-blood-pressure': true,
    'medical-conditions-answer-cholesterol': true,
  },
  'medical-conditions-selected-details': 'Conditions...',
  'medications-usage': true,
  'medications-details': 'Medicine...',
  'medication-allergies': true,
  'medication-allergies-details': 'Allergies...',
  surgeries: true,
  'surgeries-details': 'Surgeries...',
  'family-medical-history': 'Family history...',
  'male-colon-cancer-screenings': true,
  'male-colon-cancer-screenings-date': '10/12/2020',
  'adult-tobacco-use': {
    'adult-tobacco-use-answer-vape': true,
  },
  'adult-cigarette-usage-years': '12',
};

export const sampleHealthHistoryChangedIntakeAnswers = {
  'health-history-form-completed': true,
  'patient-age': 42,
  'sex-assigned-at-birth': 'sex-assigned-at-birth-answer-male',
  'health-history-changed': true,
  'health-history-changes': 'Changed health history...',
};

export const sampleHealthHistoryUnchangedIntakeAnswers = {
  'health-history-form-completed': true,
  'patient-age': 42,
  'sex-assigned-at-birth': 'sex-assigned-at-birth-answer-male',
  'health-history-changed': false,
};

export const samplePrimaryCareIntakeAnswers = {
  'medicaid-enrolled': true,
  'patient-age': 33,
  'sex-assigned-at-birth': 'sex-assigned-at-birth-answer-male',
  'health-history-form-completed': true,
  'health-history-changed': false,
  'reason-for-visit-category': 'reason-for-visit-category-answer-chronic',
  'reason-for-visit-chronic': {
    'reason-for-visit-chronic-answer-high-blood-pressure': true,
  },
  'reason-for-visit-chronic-needs': {
    'reason-for-visit-chronic-needs-answer-general-check': true,
    'reason-for-visit-chronic-needs-answer-specific-concerns': true,
  },
  'reason-for-visit-chronic-follow-up': true,
  'reason-for-visit-chronic-detail': 'My reason for visit...',
};

export const samplePhysicalTherapyIntakeAnswers = {
  'patient-age': 33,
  'sex-assigned-at-birth': 'sex-assigned-at-birth-answer-male',
  'lowest-pain-level': 0,
  'maximum-pain-level': 0,
  'anxiety-due-to-pain': 'anxiety-due-to-pain-answer-nearly-every-day',
  'little-interest-due-to-pain': 'little-interest-due-to-pain-answer-nearly-every-day',
  'reason-for-visit-physical-therapy-detail': 'Reason for visit details...\n',
  'reason-for-visit-physical-therapy-symptoms': {
    'reason-for-visit-physical-therapy-symptoms-answer-pain': true,
  },
  'reason-for-visit-physical-therapy-red-flags': {
    'reason-for-visit-physical-therapy-red-flags-answer-pain-from-broken-bone': false,
  },
  'reason-for-visit-physical-therapy-symptoms-effect': 'jio',
  'reason-for-visit-physical-therapy-symptoms-duration':
    'reason-for-visit-physical-therapy-symptoms-duration-answer-one-week',
  'reason-for-visit-physical-therapy-symptoms-location': {
    'reason-for-visit-physical-therapy-symptoms-location-answer-upper-back': true,
  },
  'reason-for-visit-physical-therapy-symptoms-effect-severity': 0,
  'confidence-in-your-ability-to-manage-pain-and-maintain-physical-function': 1,
  'confidence-condition-can-be-managed-without-surgery-medications-or-injections': 1,
  'confidence-that-exercise-and-education-through-virtual-pt-can-address-condition': 1,
};
