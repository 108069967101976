import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { doesZipMatchState, getStateFromZip } from '@utility';
import { State } from '@enums';

import { UtilityService } from '../../../core/services/utility.service';
import { UserService } from '../../../core/services/user.service';
import { PatientService } from '../../../core/services/patient.service';
import { forbiddenCharValidator } from '../validators';
import {
  USER_INPUT_FORBIDDEN_CHARS,
  USER_INPUT_FORBIDDEN_UI_MESSAGE,
} from '../../../../../../core/constants/forbidden-characters';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
  ],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input() public showAddressReset = true;
  @Input() inputColor: 'primary' | 'tertiary' = 'tertiary';
  public states = State;
  public forbiddenErrorMessage = USER_INPUT_FORBIDDEN_UI_MESSAGE;

  public form: UntypedFormGroup;
  private destroyed$ = new Subject();

  constructor(
    public patientService: PatientService,
    public userService: UserService,
    public utilityService: UtilityService
  ) {}

  static validateZipAndState(group: UntypedFormGroup) {
    const zip = group.controls.zip.value;
    const state = group.controls.state.value;
    return doesZipMatchState(zip, state) ? null : { zipCodeMismatch: true };
  }

  ngOnInit(): void {
    this.form = this.initFormGroup();

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  private initFormGroup() {
    const form = new UntypedFormGroup(
      {
        line1: new UntypedFormControl(null, [Validators.required, forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)]),
        line2: new UntypedFormControl(null, forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)),
        city: new UntypedFormControl(null, [Validators.required, forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)]),
        state: new UntypedFormControl(null, Validators.required),
        zip: new UntypedFormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(5),
            Validators.minLength(5),
            Validators.pattern('^[0-9]*$'),
          ])
        ),
      },
      AddressFormComponent.validateZipAndState
    );

    form.controls['zip'].valueChanges
      .pipe(
        filter((zip) => zip && zip.length === 5),
        takeUntil(this.destroyed$)
      )
      .subscribe((zip) => {
        const state = getStateFromZip(zip);
        if (state) {
          form.controls['state'].setValue(state);
        }
      });

    return form;
  }

  public setAddressToAccountDefault(): void {
    this.patientService.primaryPatient
      .pipe(
        switchMap((patient) => {
          if (patient && patient.addresses && patient.addresses.length) {
            return of(patient.addresses[0]);
          } else {
            return this.userService.user.pipe(
              map((user) => (user && user.addresses && user.addresses.length ? user.addresses[0] : null))
            );
          }
        })
      )
      .subscribe((address) => {
        if (address) {
          this.form.setValue({
            line1: address.address1,
            line2: address.address2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { address: { valid: false } };
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
