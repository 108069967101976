<div class="container">
  <button mat-button [disabled]="page === 0" (click)="prevPage()">
    <mat-icon>chevron_left</mat-icon>
    <span *ngIf="showText">Previous</span>
  </button>

  <div>{{ page + 1 }} of {{ pageCount + 1 }}</div>

  <button mat-button [disabled]="page === pageCount" (click)="nextPage()">
    <span *ngIf="showText">Next</span>
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
