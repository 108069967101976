import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertService } from '../../../core/services/alert.service';
import {
  ChangeAppointmentTypeFormComponent,
  ChangeAppointmentTypeFormValues,
} from '../../../shared/forms/change-appointment-type-form/change-appointment-type-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { AppointmentService, PatientAppointment } from '../../../core/services/appointment.service';

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.scss'],
})
export class RescheduleAppointmentComponent implements OnInit {
  @Input() appointment: PatientAppointment;
  public form: UntypedFormGroup;

  constructor(
    public appointmentService: AppointmentService,
    public modalController: ModalController,
    public alertService: AlertService
  ) {}

  get formValue() {
    return this.form.value as ChangeAppointmentTypeFormValues;
  }

  ngOnInit() {
    // Note we are not currently allowing any appointment type changes.
    const appointmentType = this.appointment.appointmentType.name.includes('Chat') ? 'chat' : 'video';
    this.form = ChangeAppointmentTypeFormComponent.formModel({
      appointmentType,
      appointmentDate: new Date(this.appointment.startDateTime),
      appointmentId: this.appointment.id,
      providerId: this.appointment.provider.id,
    });
  }

  async submit() {
    try {
      this.alertService.loading();
      const dateTime: string = this.formValue.appointmentTimeSlot.time;

      const selectedProviderId: number = this.formValue.providerId ? this.formValue.providerId : undefined;

      await this.appointmentService
        .rescheduleAppointment(this.appointment.id, dateTime, selectedProviderId)
        .toPromise();

      this.alertService.success('Successfully rescheduled appointment');
      this.dismiss();
    } catch (error) {
      console.error(error);
      this.alertService.error('That timeslot is not available, please select another.');
    } finally {
      this.alertService.hideLoading();
    }
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
