import { Autolinker, Match } from 'autolinker';
import { isNil } from 'lodash';
import { TextArrayObject, TextObject } from '../models/documents';

type parseLinkOptions<T, L> = {
  textHandler?: (text: string) => T;
  linkHandler?: (text: string) => L;
};

const autolinker = new Autolinker();
const defaultTextHandler = (input: string) => input;
const defaultLinkHandler = (text: string, link: string) => ({
  text,
  link,
});

export type ParsedLinksForPdf = (ReturnType<typeof defaultTextHandler> | ReturnType<typeof defaultLinkHandler>)[];

/**
 * Constructed from a string of text and optional custom handler functions
 * ParsedLinkText can be used to retrieve an array of text fragments and links
 * for the input text string.
 */
export class ParsedLinkText<T = ReturnType<typeof defaultTextHandler>, L = ReturnType<typeof defaultLinkHandler>> {
  private parsedResult: (T | L)[] = [];
  private textHandler: (input: string) => any;
  private linkHandler: (text: string, link: string) => any;
  private currentIndex = 0;
  private text: string;

  constructor(input: string, options?: parseLinkOptions<T, L>) {
    const { textHandler, linkHandler } = options || {};
    this.textHandler = textHandler || defaultTextHandler;
    this.linkHandler = linkHandler || defaultLinkHandler;

    // no further parsing required for null/undefined inputs
    if (isNil(input)) {
      return;
    }

    switch (typeof input) {
      case 'string':
        this.text = input;
        break;
      case 'bigint':
      case 'boolean':
      case 'number':
        this.text = (<bigint | boolean | number>input).toString();
        break;
      case 'object':
      default:
        throw new Error(`input of type ${typeof input} not a valid argument for ParsedLinkText`);
    }

    this.parseLinksInText(this.text);
  }

  public value() {
    return this.parsedResult;
  }

  private parseLinksInText(text: string) {
    if (text.length === 0) {
      return this.parsedResult.push(this.textHandler(text));
    }

    const matches = autolinker.parse(text);
    do {
      const match = matches.shift();
      this.addTextFragment(this.currentIndex, match?.getOffset());
      if (match) {
        this.addLink(match);
      }
    } while (this.currentIndex < this.text.length);
  }

  private addTextFragment(start: number, stop?: number) {
    const textFragment = this.textHandler(this.text.slice(start, stop));
    if (textFragment.length) {
      this.parsedResult.push(textFragment);
    }
    this.currentIndex = stop || start + textFragment.length;
  }

  private addLink(match: Match) {
    const linkText = match.getAnchorText();
    const linkHref = match.getAnchorHref();
    this.parsedResult.push(this.linkHandler(linkText, linkHref));
    this.currentIndex = match.getOffset() + match.getMatchedText().length;
  }
}

/**
 * Utility function to create a new instance of ParsedLinkText and retrieve the value
 * @param text the text to parse
 */
export function parseTextLinks(text: string) {
  return new ParsedLinkText(text).value();
}

/**
 * Utility function to parse links for the Document service
 * @param pdfMakeTextObject text object with styling attributes
 */
export function generateTextObjectWithLinks(pdfMakeTextObject: TextObject): TextArrayObject {
  const parsedText = new ParsedLinkText(pdfMakeTextObject.text).value();
  return {
    ...pdfMakeTextObject,
    text: parsedText,
  };
}
