import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { Patient } from '../../../../../../core/models/patient';

import { PatientService } from '../../../core/services/patient.service';
import { EditPatientComponent } from '../edit-patient/edit-patient.component';

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss'],
})
export class PatientCardComponent implements OnInit {
  @Input() patient: Patient;
  @Input() active: boolean;

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  async presentEditPatientModal() {
    const modal = await this.modalController.create({
      component: EditPatientComponent,
      componentProps: {
        patientId: this.patient.id,
      },
    });
    modal.onDidDismiss().then((res) => {});
    return await modal.present();
  }
}
