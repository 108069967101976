<ion-card color="light" class="card">
  <div class="container">
    <div fxLayout="column">
      <h2>
        {{ document.name | documentName }}
      </h2>
      <div class="doc-info-line">
        <p style="text-transform: uppercase">
          {{ documentType }}
        </p>
        <p>
          {{ document.metadata.timeCreated | date: 'medium' }}
        </p>
      </div>
    </div>
    <button mat-icon-button color="accent" (click)="presentModal()">
      <mat-icon>file_open</mat-icon>
    </button>
  </div>
</ion-card>
