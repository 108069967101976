import {
  StateAbbreviation,
  StateAbbreviations,
} from '../enums/state-abbreviation';

export const stateZipCodeRanges: readonly {
  readonly min: number;
  readonly max: number;
  readonly code: StateAbbreviation;
}[] = [
  { min: 35000, max: 36999, code: StateAbbreviations.AL },
  { min: 99500, max: 99999, code: StateAbbreviations.AK },
  { min: 85000, max: 86999, code: StateAbbreviations.AZ },
  { min: 71600, max: 72999, code: StateAbbreviations.AR },
  { min: 90000, max: 96699, code: StateAbbreviations.CA },
  { min: 80000, max: 81999, code: StateAbbreviations.CO },
  { min: 6000, max: 6999, code: StateAbbreviations.CT },
  { min: 20001, max: 20599, code: StateAbbreviations.DC },
  { min: 19700, max: 19999, code: StateAbbreviations.DE },
  { min: 32000, max: 34999, code: StateAbbreviations.FL },
  { min: 30000, max: 31999, code: StateAbbreviations.GA },
  { min: 96700, max: 96999, code: StateAbbreviations.HI },
  { min: 83200, max: 83999, code: StateAbbreviations.ID },
  { min: 60000, max: 62999, code: StateAbbreviations.IL },
  { min: 46000, max: 47999, code: StateAbbreviations.IN },
  { min: 50000, max: 52999, code: StateAbbreviations.IA },
  { min: 66000, max: 67999, code: StateAbbreviations.KS },
  { min: 40000, max: 42999, code: StateAbbreviations.KY },
  { min: 70000, max: 71599, code: StateAbbreviations.LA },
  { min: 3900, max: 4999, code: StateAbbreviations.ME },
  { min: 20600, max: 21999, code: StateAbbreviations.MD },
  { min: 1000, max: 2799, code: StateAbbreviations.MA },
  { min: 48000, max: 49999, code: StateAbbreviations.MI },
  { min: 55000, max: 56999, code: StateAbbreviations.MN },
  { min: 38600, max: 39999, code: StateAbbreviations.MS },
  { min: 63000, max: 65999, code: StateAbbreviations.MO },
  { min: 59000, max: 59999, code: StateAbbreviations.MT },
  { min: 27000, max: 28999, code: StateAbbreviations.NC },
  { min: 58000, max: 58999, code: StateAbbreviations.ND },
  { min: 68000, max: 69999, code: StateAbbreviations.NE },
  { min: 88900, max: 89999, code: StateAbbreviations.NV },
  { min: 3000, max: 3899, code: StateAbbreviations.NH },
  { min: 7000, max: 8999, code: StateAbbreviations.NJ },
  { min: 87000, max: 88499, code: StateAbbreviations.NM },
  { min: 10000, max: 14999, code: StateAbbreviations.NY },
  { min: 43000, max: 45999, code: StateAbbreviations.OH },
  { min: 73000, max: 74999, code: StateAbbreviations.OK },
  { min: 97000, max: 97999, code: StateAbbreviations.OR },
  { min: 15000, max: 19699, code: StateAbbreviations.PA },
  { min: 2800, max: 2999, code: StateAbbreviations.RI },
  { min: 29000, max: 29999, code: StateAbbreviations.SC },
  { min: 57000, max: 57999, code: StateAbbreviations.SD },
  { min: 37000, max: 38599, code: StateAbbreviations.TN },
  { min: 75000, max: 79999, code: StateAbbreviations.TX },
  { min: 84000, max: 84999, code: StateAbbreviations.UT },
  { min: 5000, max: 5999, code: StateAbbreviations.VT },
  { min: 22000, max: 24699, code: StateAbbreviations.VA },
  { min: 98000, max: 99499, code: StateAbbreviations.WA },
  { min: 24700, max: 26999, code: StateAbbreviations.WV },
  { min: 53000, max: 54999, code: StateAbbreviations.WI },
  { min: 82000, max: 83199, code: StateAbbreviations.WY },
] as const;

export const stateZipCodeRangesByState = Object.freeze(
  stateZipCodeRanges.reduce(
    (map, range) => Object.assign(map, { [range.code]: range }),
    {} as {
      [K in StateAbbreviation]: typeof stateZipCodeRanges[number];
    },
  ),
);
