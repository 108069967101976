export enum Gender {
  Female,
  Male,
  Other,
  Transgender_Woman,
  Transgender_Man,
  Gender_Nonconforming,
  Unspecified,
}

export enum GenderName {
  FEMALE = 'Female',
  MALE = 'Male',
  OTHER = 'Other',
  TRANSGENDER_WOMAN = 'Transgender Woman',
  TRANSGENDER_MAN = 'Transgender Man',
  GENDER_NONCONFORMING = 'Gender Nonconforming',
  UNSPECIFIED = 'Unspecified',
}

export enum Relationship {
  Self,
  Spouse,
  Partner,
  Child,
  Parent,
  Sibling,
  Guardian,
  Other,
}

export enum AddPatientRelationship {
  Child = Relationship.Child,
  Sibling = Relationship.Sibling,
  Other = Relationship.Other,
}

export enum Race {
  'American Indian or Alaskan Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White',
  'Other/Decline',
  'Two or more races',
}

export enum SexAssignedAtBirth {
  FEMALE = 'Female',
  MALE = 'Male',
  UNSPECIFIED = 'Unspecified',
}
