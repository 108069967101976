<form [formGroup]="form">
  <div fxLayout="column">
    <div fxLayout="row" class="form-heading">Service</div>
    <div fxLayout="row" class="form-sub-heading">How can we help?</div>
    <div>
      <mat-radio-group aria-label="Select a service option" formControlName="selectedServiceLine">
        <ng-container *ngFor="let serviceLine of serviceLinesEligibility">
          <mat-radio-button [value]="getServiceLineValue(serviceLine.serviceLine)">
            {{ getServiceLineName(serviceLine.serviceLine) }}
          </mat-radio-button>
          <div class="radio-helper-text">
            {{ getServiceLineDescription(serviceLine.serviceLine) }}
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
</form>
