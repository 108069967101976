import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  AppointmentBookingConfirmationDto,
  AppointmentBookingDto,
  ServiceAreaState,
  ServiceLineEligibility,
} from '@dto';
import { DateTime } from 'luxon';
import { getStateAbbreviationFromState, State } from '@enums';
import {
  CancelReasonContract,
  GenderContract,
  PaginatedResponse,
  VisitReasonCategoryContract,
} from '../types/appointments';

export interface GetServiceLinesEligibilityResponse {
  eligibleServiceLines: ServiceLineEligibility[];
}

export interface GetServiceAreaStatesResponse {
  states: ServiceAreaState[];
}

export interface GetCalendarsRequest {
  appointmentTypeId: number;
  intakeSubmissionId: string; // UUID
  visitState: State;
}

export interface GetCalendarsResponse {
  calendars: ProviderCalendarSummary[];
}

export interface ProviderCalendarSummary {
  providerId: number;
  name: string;
}

export interface StandardAvailabilityRequest {
  type: string;
  date: string;
  timeZone: string;
  visitState: string;
  appointmentTypeId: number;
  intakeSubmissionId: string; // UUID
  providerIds?: number[];
}

export interface RescheduleAvailabilityRequest {
  type: string;
  date: string;
  timeZone: string;
  appointmentId: string;
  providerIds: number[];
}

export interface AvailabilityResponse {
  timeSlots: ProviderAvailabilityForTime[];
  providers: ProviderCalendarSummary;
}

export interface ProviderAvailabilityForTime {
  time: string;
  providerIds: number[];
}

export interface AvailableAppointmentTypesRequest {
  intakeSubmissionId: string; // UUID
}

export interface AvailableAppointmentTypesResponse {
  appointmentTypes: AvailableAppointmentType[];
}

export interface AvailableAppointmentType {
  id: number;
  appointmentDurationInMinutes: number;
  formatType: AppointmentTypeFormatContract;
  serviceLine: AppointmentTypeServiceLineContract;
}

export interface PatientAppointmentType {
  id: number;
  name: string;
  appointmentDurationInMinutes: number;
  appointmentTypeFormat: string;
  serviceLine: string;
  patientCanReschedule: boolean;
  patientCanCancel: boolean;
  patientCancelLeadTime: LeadTime;
  patientRescheduleLeadTime: LeadTime;
}

export interface LeadTime {
  seconds: number;
}

export interface RescheduleAppointmentRequest {
  appointmentId: string;
  dateTime: string;
  timeZone: string;
  providerId?: number;
}

export interface RescheduleAppointmentResponse {
  appointmentId: string;
}

export interface CancelAppointmentRequest {
  reason: string;
}

export interface GetAppointmentsRequest {
  after: string;
  before: string;
  includeCanceled: boolean;
  patientId: number;
}

export interface AppointmentPatient {
  id: number;
  firstName: string;
  lastName: string;
  preferredName?: string;
  dateOfBirth: string;
  gender: GenderContract;
  visitReasonCategory?: VisitReasonCategoryContract;
  visitReasons: string[];
}

export interface AppointmentProvider {
  id: number;
  firstName: string;
  lastName: string;
  licenses: string[];
  credentials: string[];
}

export interface PatientAppointment {
  id: string;
  appointmentType: PatientAppointmentType;
  startDateTime: string;
  visitState: string;
  address?: string;
  provider: AppointmentProvider;
  patients: AppointmentPatient[];
  canceled: boolean;
  cancelReason?: CancelReasonContract;
}

/**
 * Defines the appointment types that are selectable by the patient.  Note that for display, we use the
 * {@link #APPOINTMENT_TYPE_DISPLAY_ORDER} constant.
 */
export enum AppointmentTypeFormatContract {
  CHAT = 'Chat',
  IN_PERSON = 'In Person',
  VIDEO = 'Video',
}

export const APPOINTMENT_TYPE_DISPLAY_ORDER = {
  [AppointmentTypeFormatContract.VIDEO]: 1,
  [AppointmentTypeFormatContract.CHAT]: 2,
  [AppointmentTypeFormatContract.IN_PERSON]: 3,
} as const;

export enum AppointmentTypeServiceLineContract {
  PRIMARY_CARE = 'Primary Care',
  MENTAL_HEALTH = 'Mental Health',
  PHYSICAL_THERAPY = 'Physical Therapy',
}

/**
 * Defines an error state for the appointment scheduling process that allows for testing of various failure scenarios.
 */
export enum SchedulingErrorState {
  APPOINTMENT_TYPES = 'Appointment Types',
  CALENDARS = 'Calendars',
  AVAILABILITY = 'Availability',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  public triggerAppointmentRefresh = new Subject<boolean>();

  constructor(private httpClient: HttpClient) {}

  public getAppointments(
    patientId: number,
    page: number,
    pageSize: number,
    timeframe: 'past' | 'future'
  ): Observable<PaginatedResponse<PatientAppointment>> {
    const url = `${environment.niceServiceUrl}/v2/appointments/search`;

    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    const startOfTime = new Date(0);
    const endOfTime = new Date('8888-12-30');

    return this.httpClient
      .post<PaginatedResponse<PatientAppointment>>(
        url,
        {
          after: timeframe === 'past' ? startOfTime : startOfToday,
          before: timeframe === 'past' ? startOfToday : endOfTime,
          includeCanceled: timeframe === 'past',
          patientId: patientId,
          type: 'FindAppointmentRequest::Patient::V1',
        },
        {
          params: {
            page: page,
            size: pageSize,
            // sortBy: 'scheduledTime',
            order: timeframe === 'past' ? 'DESC' : 'ASC',
          },
        }
      )
      .pipe(
        take(1),
        map((response) => {
          return response;
        })
      );
  }

  public getServiceLineEligibility(): Observable<ServiceLineEligibility[]> {
    const url = `${environment.niceServiceUrl}/v1/service-lines/eligibility`;

    return this.httpClient.get<GetServiceLinesEligibilityResponse>(url).pipe(
      take(1),
      map((response: GetServiceLinesEligibilityResponse) => {
        return response.eligibleServiceLines;
      })
    );
  }

  public getServiceAreaStates(): Observable<ServiceAreaState[]> {
    const url = `${environment.niceServiceUrl}/v1/service-areas/states`;

    return this.httpClient.get<GetServiceAreaStatesResponse>(url).pipe(
      take(1),
      map((response: GetServiceAreaStatesResponse) => {
        return response.states;
      })
    );
  }

  public getAvailableAppointmentTypes(
    availableAppointmentTypesRequest: AvailableAppointmentTypesRequest
  ): Observable<AvailableAppointmentTypesResponse> {
    const url = `${environment.niceServiceUrl}/v1/appointments/types/search`;

    return this.httpClient.post<AvailableAppointmentTypesResponse>(url, availableAppointmentTypesRequest);
  }

  public createBooking(booking: AppointmentBookingDto): Observable<AppointmentBookingConfirmationDto> {
    return this.httpClient
      .post<AppointmentBookingConfirmationDto>(`${environment.niceServiceUrl}/v1/appointments`, booking)
      .pipe(
        take(1),
        tap(() => this.triggerAppointmentRefresh.next(true))
      );
  }

  /**
   * Sorts the given array of {@link AvailableAppointmentType}s for display, first by type and then by length.
   *
   * @param availableAppointmentTypes the array of types from the server to sort.
   *
   * @return a sorted array of {@link AvailableAppointmentType}s for display.
   */
  public orderAvailableAppointmentTypes(
    availableAppointmentTypes: AvailableAppointmentType[]
  ): AvailableAppointmentType[] {
    if (availableAppointmentTypes && availableAppointmentTypes.length > 0) {
      // First sort by appointment format.
      const sortedAppointmentTypes: AvailableAppointmentType[] = availableAppointmentTypes.sort(
        (a, b) => APPOINTMENT_TYPE_DISPLAY_ORDER[a.formatType] - APPOINTMENT_TYPE_DISPLAY_ORDER[b.formatType]
      );
      let sortedAndOrderedAppointmentTypes: AvailableAppointmentType[] = [];

      // Then do a secondary sort by length of the appointment for each format type (don't sort just by time...).
      let currentFormatType: AppointmentTypeFormatContract = null;
      let currentAppointmentTypesSubset: AvailableAppointmentType[] = [];
      for (let i = 0; i < sortedAppointmentTypes.length; i++) {
        // Process the current set if the format changes or if it is the last element.
        if (currentFormatType !== sortedAppointmentTypes[i].formatType) {
          currentFormatType = sortedAppointmentTypes[i].formatType;
          sortedAndOrderedAppointmentTypes = sortedAndOrderedAppointmentTypes.concat(
            currentAppointmentTypesSubset.sort(
              (a, b) => a.appointmentDurationInMinutes - b.appointmentDurationInMinutes
            )
          );
          currentAppointmentTypesSubset = [];
        }

        currentAppointmentTypesSubset.push(sortedAppointmentTypes[i]);

        // Ensure last element is accounted for and mixed in.
        if (i === sortedAppointmentTypes.length - 1) {
          sortedAndOrderedAppointmentTypes = sortedAndOrderedAppointmentTypes.concat(
            currentAppointmentTypesSubset.sort(
              (a, b) => a.appointmentDurationInMinutes - b.appointmentDurationInMinutes
            )
          );
        }
      }

      return sortedAndOrderedAppointmentTypes;
    }

    return availableAppointmentTypes;
  }

  public getCalendars(calendarsRequest: GetCalendarsRequest): Observable<ProviderCalendarSummary[]> {
    const url = `${environment.niceServiceUrl}/v2/calendars`;

    return this.httpClient
      .get<GetCalendarsResponse>(url, {
        params: {
          appointmentTypeId: calendarsRequest.appointmentTypeId,
          visitState: getStateAbbreviationFromState(calendarsRequest.visitState),
        },
      })
      .pipe(
        take(1),
        map((response) => {
          return response.calendars;
        })
      );
  }

  public getCalendarsForReschedule(appointmentId: string): Observable<ProviderCalendarSummary[]> {
    const url: string = `${environment.niceServiceUrl}/v2/calendars`;

    return this.httpClient
      .get<GetCalendarsResponse>(url, {
        params: {
          appointmentId: appointmentId,
        },
      })
      .pipe(
        take(1),
        map((response) => {
          return response.calendars;
        })
      );
  }

  public getAvailability(
    appointmentDate: DateTime,
    appointmentTypeId: number,
    intakeSubmissionId: string, // UUID
    visitState: State,
    providerIds?: number[]
  ): Observable<ProviderAvailabilityForTime[]> {
    const url = `${environment.niceServiceUrl}/v2/availability`;
    const availabilityRequestObject: StandardAvailabilityRequest = {
      type: 'Availability::Patient::V2',
      visitState: getStateAbbreviationFromState(visitState),
      date: appointmentDate.toFormat('yyyy-MM-dd'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      appointmentTypeId,
      intakeSubmissionId,
      providerIds,
    };

    return this.httpClient.post<AvailabilityResponse>(url, availabilityRequestObject).pipe(
      take(1),
      map((response) => {
        // Note this response also contains provider names.  We don't need to pass it back since we are currently
        // using the "all matching this appointment type" call from v1/calendars.
        return response.timeSlots;
      })
    );
  }

  public getAvailabilityForReschedule(
    appointmentDate: DateTime,
    appointmentId: string,
    providerIds: number[]
  ): Observable<ProviderAvailabilityForTime[]> {
    const url = `${environment.niceServiceUrl}/v2/availability`;
    const availabilityRequestObject: RescheduleAvailabilityRequest = {
      type: 'Availability::Reschedule::V1',
      date: appointmentDate.toFormat('yyyy-MM-dd'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      appointmentId,
      providerIds,
    };

    return this.httpClient.post<AvailabilityResponse>(url, availabilityRequestObject).pipe(
      take(1),
      map((response) => {
        // Note this response also contains provider names.  We don't need to pass it back since we are currently
        // using the "all matching this appointment type" call from v1/calendars.
        return response.timeSlots;
      })
    );
  }

  public rescheduleAppointment(appointmentId: string, dateTime: string, providerId?: number): Observable<string> {
    const url = `${environment.niceServiceUrl}/v1/appointments/reschedule`;
    const request: RescheduleAppointmentRequest = {
      appointmentId,
      dateTime,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      providerId,
    };

    return this.httpClient.put<RescheduleAppointmentResponse>(url, request).pipe(
      take(1),
      map((response: RescheduleAppointmentResponse) => {
        this.triggerAppointmentRefresh.next(true);
        return response.appointmentId;
      })
    );
  }

  public cancelAppointment(appointmentId: string): Observable<void> {
    const url = `${environment.niceServiceUrl}/v1/appointments/${appointmentId}/cancel`;
    const request: CancelAppointmentRequest = {
      reason: 'UNSPECIFIED',
    };

    // No response body for success.  Throws an error on failure (non 200).
    return this.httpClient.post(url, request).pipe(
      take(1),
      map(() => {
        this.triggerAppointmentRefresh.next(true);
      })
    );
  }
}
