import { Injectable } from '@angular/core';

import { FormTree, TypeformParser } from '../../../../../core/formly-typeform/typeform-parser';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Typeform } from '@typeform/api-client';
import { IntakeFormFriendlyName } from '@enums';

export interface DynamicFormSchema {
  entryNodeId: string;
  formTree: FormTree;
  metadata: {
    formDefinitionId: number;
    visitReasonFieldId: string[];
  };
}

export interface IntakeFormDefinition {
  id: number;
  formDefinition: Typeform.Form;
  visitReasonFieldId: string; // JSON string
  mapping: any;
}

export interface IntakeFormSubmissionResponse {
  intakeSubmissionId: string;
}

@Injectable({
  providedIn: 'root',
})
export class IntakeFormService {
  private intakeForms: Map<IntakeFormFriendlyName, IntakeFormDefinition> = new Map<
    IntakeFormFriendlyName,
    IntakeFormDefinition
  >();

  constructor(private httpClient: HttpClient) {}

  public async getPrimaryCareForm(): Promise<DynamicFormSchema> {
    return await this.getIntakeForm(3, IntakeFormFriendlyName.PRIMARY_CARE);
  }

  public async getMentalHealthTherapyForm(): Promise<DynamicFormSchema> {
    return await this.getIntakeForm(3, IntakeFormFriendlyName.MENTAL_HEALTH);
  }

  public async getPhysicalTherapyForm(): Promise<DynamicFormSchema> {
    return await this.getIntakeForm(3, IntakeFormFriendlyName.PHYSICAL_THERAPY);
  }

  public async getHealthHistoryForm(): Promise<DynamicFormSchema> {
    return await this.getIntakeForm(3, IntakeFormFriendlyName.HEALTH_HISTORY);
  }

  public async submitIntakeForms(intakeFormId: number, intakeForms: any): Promise<IntakeFormSubmissionResponse> {
    const url = environment.niceServiceUrl.concat('/v1/intake-form/submit');

    // Reminder that intake forms have this shape:
    // {
    //   "patientId1": {
    //     "additionalProp1": {},
    //     "additionalProp2": {},
    //     ...
    //   },
    //   "patientId2": {
    //     "additionalProp1": {},
    //     "additionalProp2": {},
    //     ...
    //   },
    //   ...
    // }
    const submitIntakeFormRequest = {
      intakeFormId: intakeFormId,
      intakeSubmissionData: intakeForms,
    };

    return this.httpClient.post<IntakeFormSubmissionResponse>(url, submitIntakeFormRequest).toPromise();
  }

  private async getIntakeForm(
    compatibilityVersion: number = 3,
    friendlyName: IntakeFormFriendlyName = IntakeFormFriendlyName.PRIMARY_CARE
  ): Promise<DynamicFormSchema> {
    const intakeForm = await this.retrieveIntakeForm(compatibilityVersion, friendlyName);
    this.intakeForms.set(friendlyName, intakeForm);
    const {
      fields,
      logic,
      welcome_screens: welcomeScreens,
      thankyou_screens: thankYouScreens,
    } = intakeForm.formDefinition;
    let firstStepRef = (fields[0] && fields[0].ref) || null;
    const formTree = TypeformParser.parseFormTree(fields, logic);
    const welcomeScreen = welcomeScreens && welcomeScreens[0];

    if (welcomeScreen) {
      const welcomeNode = TypeformParser.parseWelcomeScreen(welcomeScreen, logic, firstStepRef);
      firstStepRef = welcomeScreen.ref;
      formTree[firstStepRef] = welcomeNode;
    }

    if (thankYouScreens) {
      thankYouScreens.forEach((screen) => {
        const thankYouNode = {
          ...TypeformParser.createStatementField(screen.title, (screen.properties as any)?.description, screen.ref),
          buttonText: 'Continue',
          next: () => null,
        };
        formTree[screen.ref] = thankYouNode;
      });
    }

    return {
      entryNodeId: firstStepRef,
      formTree,
      metadata: {
        formDefinitionId: intakeForm.id,
        visitReasonFieldId: this.parseVisitReasonFieldId(intakeForm),
      },
    };
  }

  private parseVisitReasonFieldId(definition: IntakeFormDefinition): string[] {
    // The visitReasonFieldId should be an array of Typeform fields that represent the possible form answers
    // filled out by a patient depending on what path they go through the form.
    let result: string[];
    try {
      const parsedResult = JSON.parse(definition.visitReasonFieldId);
      if (typeof parsedResult === 'string') {
        result = [parsedResult];
      } else {
        result = parsedResult;
      }
    } catch (error: any) {
      result = [definition.visitReasonFieldId];
    }

    return result;
  }

  private async retrieveIntakeForm(
    compatibilityVersion: number = 3,
    friendlyName: IntakeFormFriendlyName = IntakeFormFriendlyName.PRIMARY_CARE
  ): Promise<IntakeFormDefinition> {
    if (this.intakeForms.get(friendlyName)) {
      return this.intakeForms.get(friendlyName);
    }

    const url = environment.niceServiceUrl.concat('/v1/intake-form');

    return this.httpClient
      .get<IntakeFormDefinition>(url, {
        params: {
          compatibilityVersion,
          friendlyName: friendlyName.valueOf(),
        },
      })
      .toPromise();
  }
}
