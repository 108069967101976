import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]',
})
export class LongPressDirective implements OnDestroy {
  private timeoutReference: NodeJS.Timeout;
  private readonly longPressThresholdInMilliseconds = 750;

  @Output() longPressOccurred = new EventEmitter();

  @HostListener('click', ['$event']) onClick(event: any) {
    // Prevent click event from doing something with the target element (similar to mouseup).
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.timeoutReference = setTimeout(() => {
      this.longPressOccurred.emit();
    }, this.longPressThresholdInMilliseconds);
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    clearTimeout(this.timeoutReference);
  }

  ngOnDestroy(): void {
    if (this.timeoutReference) {
      clearTimeout(this.timeoutReference);
    }
  }
}
