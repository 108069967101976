import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { State } from '@enums';

export interface SelectStateFormValues {
  selectedState: State;
}

@Component({
  selector: 'app-invalid-state-form',
  templateUrl: './invalid-state-form.component.html',
  styleUrls: ['./invalid-state-form.component.scss'],
})
export class InvalidStateFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  constructor(public utilityService: UtilityService) {}

  static formModel() {
    return new UntypedFormGroup({});
  }

  ngOnInit() {}
}
