<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ document.name | documentName }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="downloadFile()">
        <ion-icon slot="icon-only" name="share-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <div class="document-container">
    <ion-spinner class="centered" *ngIf="isLoading"></ion-spinner>
    <div *ngIf="isImage && dataUrl" class="document-image">
      <img [src]="dataUrl" [style.transform]="'scale(' + zoom / 100 + ')'" alt="" />
    </div>
    <pdf-viewer
      *ngIf="!isImage && dataUrl"
      [src]="$any(this.dataUrl).changingThisBreaksApplicationSecurity"
      [autoresize]="true"
      [original-size]="false"
      [fit-to-page]="true"
      [render-text]="true"
      [render-text-mode]="2"
      [external-link-target]="'blank'"
      (text-layer-rendered)="openExternalLinksInNewTab($event)"
      [zoom]="zoom / 100"
      style="width: 100vh; height: 100vh"
    ></pdf-viewer>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar color="secondary" class="ion-padding-start ion-padding-end">
    <button mat-button slot="start" (click)="resetZoom()">Zoom: {{ zoom }}%</button>
    <div slot="end">
      <button class="first-button" mat-icon-button [disabled]="zoom === 100" (click)="zoomOut()">
        <mat-icon>remove</mat-icon>
      </button>
      <button mat-icon-button [disabled]="zoom >= zoomMax" (click)="zoomIn()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </ion-toolbar>
</ion-footer>
