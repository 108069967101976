import {
  AlertController,
  ToastController,
  LoadingController,
} from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AlertButton } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /**
   * Holds a reference to the Promise returned by `loadingCtrl.create()`
   * We reference the promise instead of the resolved value so that
   * loadingCtrl.create() isn't called in parallel async functions;
   * this prevents a race condition.
   */
  private loader?: Promise<HTMLIonLoadingElement>;

  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
  ) {}

  public async loading(message: string = null) {
    if (this.loader) {
      // Loader already displayed, proceed without taking action
      return;
    }

    this.loader = this.loadingCtrl.create({
      message: message,
    });
    return (await this.loader).present();
  }

  public async hideLoading() {
    if (this.loader) {
      (await this.loader).dismiss();
    }
    this.loader = undefined;
  }

  public async success(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2500,
      color: 'success',
    });

    toast.present();
  }

  public async error(message: string, duration: number = 6000) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      color: 'warning',
    });

    toast.present();
  }

  public async alert(opts: {
    header: string;
    subheader?: string;
    message: string;
    button?: string;
  }) {
    const alert = await this.alertCtrl.create({
      header: opts.header,
      subHeader: opts.subheader,
      message: opts.message,
      buttons: [opts.button || 'OK'],
    });

    await alert.present();

    return alert;
  }

  public async confirm({
    header = 'Confirm',
    subHeader,
    message,
    confirmText = 'Confirm',
    confirmCssClass = 'primary',
    cancelText = 'Cancel',
    cancelCssClass = 'warning',
    hideCancelButton = false,
  }: {
    header?: string;
    subHeader?: string;
    message: string;
    confirmText?: string;
    confirmCssClass?: string;
    cancelText?: string;
    cancelCssClass?: string;
    hideCancelButton?: boolean;
  }) {
    const buttons = [];

    if (!hideCancelButton) {
      buttons.push({
        text: cancelText,
        role: 'cancel',
        cssClass: cancelCssClass,
      });
    }

    buttons.push({
      text: confirmText,
      role: 'confirm',
      cssClass: confirmCssClass,
    });

    const confirm = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons,
    });

    await confirm.present();

    const result = await confirm.onDidDismiss();

    return result.role !== 'cancel';
  }

  public async banner(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: 'top',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
      color: 'light',
    });

    await toast.present();

    return toast;
  }
}
