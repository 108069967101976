import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicStorageModule } from '@ionic/storage-angular';

import { EnumPipe } from './pipes/enum.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { DocumentNamePipe } from './pipes/document-name.pipe';

@NgModule({
  declarations: [EnumPipe, PhoneNumberPipe, DocumentNamePipe],
  imports: [
    CommonModule,
    IonicStorageModule.forRoot({
      name: '__nicedb',
      driverOrder: ['indexeddb', 'sqlite', 'websql'],
    }),
  ],
  exports: [EnumPipe, PhoneNumberPipe, DocumentNamePipe],
})
export class CoreModule {}
