<ion-card color="secondary" class="card">
  <ion-card-header>
    <ion-card-title
      >{{ appointment.appointmentType.name }}<span *ngIf="appointment.canceled"> - Cancelled</span></ion-card-title
    >
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row class="ion-justify-content-between">
        <ion-col>
          <p>
            {{ appointment.startDateTime | date }} w/ {{ appointment.provider.firstName }}
            {{ appointment.provider.lastName }}
            <span *ngIf="appointment.provider.credentials && appointment.provider.credentials.length > 0"
              >, {{ appointment.provider.credentials.join(', ') }}</span
            >
          </p>
        </ion-col>
        <ion-col class="ion-text-right">
          <p>
            {{ appointment.startDateTime | date : 'shortTime' }}<br />
            {{ appointment.address ? appointment.address : appointment.visitState }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
  <ion-row class="ion-justify-content-center">
    <ion-button
      *ngIf="isChatAppointment && upcomingAppointment && appointment.canceled === false"
      expand="block"
      color="primary"
      vertical="bottom"
      (click)="goToChat()"
    >
      <p>Go To Chat</p>
      <ion-icon slot="end" name="arrow-forward"></ion-icon>
    </ion-button>
    <ion-button
      *ngIf="isVideoAppointment && upcomingAppointment && appointment.canceled === false"
      expand="block"
      color="primary"
      vertical="bottom"
      (click)="launchVideo()"
    >
      <p>JOIN VISIT</p>
      <ion-icon slot="end" name="arrow-forward"></ion-icon>
    </ion-button>
    <ion-button
      id="reschedule"
      *ngIf="isFutureAppointment && appointment.canceled === false && canReschedule"
      [disabled]="!isInRescheduleWindow"
      color="primary"
      vertical="bottom"
      (click)="rescheduleAppointment()"
    >
      <p>Reschedule</p>
    </ion-button>
    <ion-button
      id="cancel"
      *ngIf="isFutureAppointment && appointment.canceled === false && (isChatAppointment || isVideoAppointment)"
      [disabled]="!isInCancelWindow"
      color="primary"
      vertical="bottom"
      (click)="cancelAppointment(appointment.id)"
    >
      <p>Cancel</p>
    </ion-button>
  </ion-row>
  <span *ngIf="isFutureAppointment && appointment.canceled === false && canReschedule && !isInRescheduleWindow">
    This appointment is outside of the reschedule window
  </span>
</ion-card>
