export enum TobaccoProducts {
  cigarettes = 'Cigarettes',
  pipe = 'Pipe',
  smokeless = 'Smokeless',
}

export enum SmokingStatus {
  current = 'Current Smoker',
  former = 'Former Smoker',
  never = 'Never',
}

export enum MaritalStatus {
  single = 'Single',
  married = 'Married',
  widowed = 'Widowed',
  divorced = 'Divorced',
  separated = 'Separated',
}

export enum AllergyType {
  specificDrugAllergy = 'Specific Drug Allergy',
  drugClassAllergy = 'Drug-Class Allergy',
  nonDrugAllergy = 'Non-Drug Allergy',
  fdbATDrugName = 'fdbATDrugName',
  fdbATAllergenGroup = 'fdbATAllergenGroup',
}

export enum AllergySeverity {
  SEVERE = 'Severe',
  MODERATE = 'Moderate',
  MILD = 'Mild',
}

export enum AllergyReaction {
  acuteKidneyFailure = 'Acute kidney failure',
  anaphylaxis = 'Anaphylaxis',
  arthralgia = 'Arthralgia',
  chills = 'Chills',
  cough = 'Cough',
  fever = 'Fever',
  headache = 'Headache',
  hives = 'Hives',
  fatigue = 'Malaise/fatigue',
  myalgia = 'Myalgia',
  congestion = 'Nasal congestion',
  nausea = 'Nausea',
  soreness = 'Pain/soreness at injection site',
  rash = 'Rash',
  respiratoryDistress = 'Respiratory distress',
  rhinorrhea = 'Rhinorrhea',
  shortnessOfBreath = 'Shortness of breath / difficulty breathing',
  soreThroat = 'Sore throat',
  swelling = 'Swelling',
}
