import React, { PropsWithChildren, useEffect, useRef } from 'react';

/**
 * Hook that identifies clicks outside of the target component wrapper.ø
 */
function useHandleClickOutside(
  componentReference: React.RefObject<HTMLDivElement>,
  callback?: (event: MouseEvent) => Promise<void> | void
): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent): void => {
      if (componentReference.current && !componentReference.current.contains(event.target as Node)) {
        callback?.(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind on cleanup.
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, componentReference]);
}

type ClickOutsideAwareComponentProps = PropsWithChildren<{
  callback?: (event: MouseEvent) => Promise<void> | void;
}>;

/**
 * Component that allows for a callback if a click occurs outside of its boundaries.
 */
export function ClickOutsideAwareComponent({ callback, children }: ClickOutsideAwareComponentProps): React.JSX.Element {
  const componentWrapperReference: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  useHandleClickOutside(componentWrapperReference, callback);

  return <div ref={componentWrapperReference}>{children}</div>;
}

export default ClickOutsideAwareComponent;
