import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let phone = value.replace(/\D/g, '');
      const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${
          match[3] ? '-' : ''
        }${match[3]}`;
      }
      return phone;
    }
  }
}
