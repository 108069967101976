import {
  cond,
  flattenDeep,
  flow,
  identity,
  isArray,
  isPlainObject,
  map,
  partialRight,
  stubTrue,
} from 'lodash';

/**
 * Deeply flattens an object returning an array of
 * its primitive leaf nodes.
 */
export function deepFlattenCollection(object: object): unknown[] {
  // based on SO answer here: https://stackoverflow.com/a/39134832/7926620
  return flow<any, any, any, any>(
    identity,
    partialRight(
      map,
      cond([
        [isArray, deepFlattenCollection],
        [isPlainObject, deepFlattenCollection],
        [stubTrue, identity],
      ]),
    ),
    flattenDeep,
  )(object);
}
