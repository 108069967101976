import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { Color } from '../../../react/theme/colors';
import { Patient } from '@models';
import { Box, IconButton, Typography } from '@mui/material';
import NavigationMenuItem from '../NavigationMenuItem/NavigationMenuItem';
import { LogoutOutlined, SettingsOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { Router } from '@angular/router';
import { typographyThemeOptions } from '../../../react/theme/typography';

function stringAvatar(name: string) {
  return {
    sx: {
      height: '36px',
      width: '36px',
      bgcolor: Color.Lilac500,
      color: Color.Lilac100,
      ...typographyThemeOptions.subtitle2,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export type AccountMenuProps = {
  router: Router;
  accountHolder: Patient;
};

export function AccountMenu({ router, accountHolder }: AccountMenuProps) {
  const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorElement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorElement(null);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{
          padding: '3px',
          '&:hover': {
            backgroundColor: Color.Lilac400,
            padding: '3px',
          },
        }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar {...stringAvatar(accountHolder.name)} />
      </IconButton>
      <Menu
        anchorEl={menuAnchorElement}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              marginTop: '5px',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ padding: '8px 20px 8px 20px' }}>
          <Typography noWrap variant='subtitle1' title={accountHolder.name} sx={{ lineHeight: '22px' }}>
            {accountHolder.name}
          </Typography>
          <Typography
            sx={{ marginTop: '3px', color: Color.Slate200 }}
            noWrap
            variant='body2'
            title={accountHolder.email}
          >
            {accountHolder.email}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: '13px', marginBottom: '13px' }} />
        <NavigationMenuItem
          router={router}
          menuItem={{ title: 'Login & Account', internalLink: '/user', icon: <SettingsOutlined /> }}
        />
        <NavigationMenuItem
          router={router}
          menuItem={{
            title: 'Privacy Policy',
            externalLink: 'https://www.nice.healthcare/privacy-policy',
            icon: <VerifiedUserOutlined />,
          }}
        />
        <Divider sx={{ marginTop: '13px', marginBottom: '13px' }} />
        <NavigationMenuItem
          router={router}
          menuItem={{ title: 'Sign Out', internalLink: '/logout', icon: <LogoutOutlined /> }}
        />
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;
