import { PatientDto } from '../dto/patient';
import { Gender, Race, Relationship, SexAssignedAtBirth } from '../enums/patient';
import { MembershipDto } from '../dto/membership';
import { PatientAddressDto } from '../dto/patient-address';
import { DateTime } from 'luxon';

export class Patient {
  public readonly addresses!: PatientAddressDto[];
  public readonly addressLatLng!: { lat: number; lng: number };
  public readonly id!: number;
  public readonly firstName!: string;
  public readonly lastName!: string;
  public readonly preferredName!: string;
  public readonly race!: Race;
  public readonly sexAssignedAtBirth!: SexAssignedAtBirth;
  public readonly gender!: Gender;
  public readonly relationToAccountHolder!: Relationship;
  public readonly dateOfBirth!: string;
  public readonly membership!: MembershipDto;
  public readonly email!: string;
  public readonly phone!: string;
  public readonly unreadCount!: number;
  public readonly patientNote!: string;
  public readonly chronoPatientId!: string; // Only available for the provider app
  public readonly chronoSyncError!: boolean; // Only available for the provider app
  public readonly chronoChartId!: string; // Only available for the provider app
  public readonly deactivated!: boolean;
  public readonly active!: boolean;
  public readonly deleted!: boolean;
  public readonly verified!: boolean;
  public readonly allow65Plus!: boolean;
  public readonly changeHealthcarePatientId!: string;
  public readonly inHomeVisitServiceRange?: boolean;
  constructor(data: PatientDto) {
    Object.assign(this, data);
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get ageInYears() {
    const years = DateTime.fromISO(this.dateOfBirth).diffNow('years').negate().toFormat('y', { floor: true });
    const age = Number(years);

    if (isNaN(age)) {
      return 0;
    }

    return age;
  }

  get is65Plus() {
    return this.ageInYears >= 65;
  }

  get companyName() {
    if (!this.membership || !this.membership.signUpDetails || !this.membership.signUpDetails.companyName) {
      return null;
    } else {
      return this.membership.signUpDetails.companyName;
    }
  }

  get fullAddress() {
    if (!this.addresses || !this.addresses.length) {
      return null;
    }

    // Only support a single address for time being
    const address = this.addresses[0];
    return `${address.address1}${address.address2 ? ` ${address.address2}` : ''}, ${address.city}, ${address.state}, ${
      address.zip
    }`;
  }

  get address() {
    if (!this.addresses || !this.addresses.length) {
      return null;
    }

    const address = this.addresses[0];
    return address;
  }

  get membershipTypeName() {
    if (!this.membership || !this.membership.membershipType) {
      return null;
    }

    return this.membership.membershipType.membershipName;
  }
}
