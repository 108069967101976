import { formatDate } from '@angular/common';
import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'long', year: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
// Adding some custom logic so that calendar modal displays Month Year as the calendar
// header and formats the date selected/inputted in MMM d, yyyy format to ensure parity
// with how the legacy date picker logic worked
@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    date.setUTCHours(12, 0, 0, 0);
    if (displayFormat === 'input') {
      // format date to MMM d, y
      return formatDate(date, 'MMM d, y', this.locale);
    } else {
      // format the calendar header as Month Year
      return this.formatToDateString(date);
    }
  }

  private formatToDateString(date: Date): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month}  ${year}`;
  }
}
