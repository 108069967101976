import { Component } from '@angular/core';
import { AlertService } from '../../../core/services/alert.service';
import { Router } from '@angular/router';
import { PatientService } from '../../../core/services/patient.service';
import { ModalController } from '@ionic/angular';
import { UntypedFormControl } from '@angular/forms';
import { NewPatientRequest } from '@dto';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss'],
})
export class AddPatientComponent {
  public form = new UntypedFormControl();

  constructor(
    private alertService: AlertService,
    private modalController: ModalController,
    public router: Router,
    public patientService: PatientService
  ) {}

  public async submit() {
    try {
      this.alertService.loading();

      const patientData: NewPatientRequest = {
        address: this.form.value.address,
        dateOfBirth: this.form.value.dateOfBirth,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        sexAssignedAtBirth: this.form.value.sexAssignedAtBirth,
        gender: this.form.value.gender,
        race: this.form.value.race,
        relationToAccountHolder: this.form.value.relationToAccountHolder,
        phone: this.form.value.phone?.replace(/[^A-Za-z0-9]+/gi, ''),
        email: this.form.value.email,
      };

      await this.patientService.addPatientToAccount(patientData).toPromise();
      this.alertService.success(`${patientData.firstName} ${patientData.lastName} added!`);
      this.modalController.dismiss({ refresh: true });
    } catch (error) {
      console.error(error);
      if (error.status === 409) {
        this.alertService.error(error.error);
        return;
      }
      this.alertService.error('Error adding patient. Please try again.');
    } finally {
      this.alertService.hideLoading();
    }
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
