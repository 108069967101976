import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { alpha, Button, Menu, MenuProps, styled } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ClickOutsideAwareComponent from './ClickOutsideAwareComponent';
import { Color } from '../theme/colors';
import { typographyThemeOptions } from '../theme/typography';
import { PatientService } from '../../core/services/patient.service';

export type SelectOption<TValue = string> = {
  name: string;
  value: TValue;
};

export type SelectDropDownProps<TValue = string> = {
  options: SelectOption<TValue>[];
  initialSelectedOption?: SelectOption<TValue>;
  onSelectionChanged?: OnSelectChangeHandler<TValue>;
  patientService: PatientService;
  id?: string;
  placeholderLabel?: string;
  color?: 'primary' | 'neutral';
  variant?: 'normal' | 'outlined';
};

const StyledButton = styled(Button)(({ theme, ...props }) => ({
  ...typographyThemeOptions.subtitle2,
  '& .MuiButton-endIcon': {
    borderLeft: '1px solid ' + Color.Slate400,
    marginLeft: '10px',
    paddingLeft: '8px',
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

type OnClickHandler = (event: React.MouseEvent<HTMLElement>) => Promise<void> | void;

type OnClickHandlerWithParameters = (
  ...parameters: any[]
) => (event: React.MouseEvent<HTMLElement>) => Promise<void> | void;

/**
 * This type is the defined signature for any onChange Select component callback.  It is required that they type allow
 * for values that are defined for the handler plus the underlying DOM element value type. For example, when the
 * browser auto-fills the Select you'll receive a <code>React.ChangeEvent</code> tied to an
 * <code>HTMLInputElement</code>. The value type is {@link string}.  This signature can be used for async calls in
 * addition to normal function callbacks since the events are filtered through the React {@link SyntheticEvent}
 * framework.  Any return value will be ignored regardless. This is often specified by wrapping with a
 * {@link React.useCallback} method call.
 */
// export type OnSelectChangeHandler<Value = string> = (event: SelectChangeEvent<Value>, child?: React.ReactNode) => Promise<void> | void;
export type OnSelectChangeHandler<Value = string> = (
  patientService: PatientService,
  selectedValue: Value
) => Promise<void> | void;

export function SelectDropDown<TValue = string>({
  id,
  placeholderLabel,
  options,
  initialSelectedOption,
  onSelectionChanged,
  patientService,
}: SelectDropDownProps<TValue>): React.JSX.Element {
  const [selectedOption, setSelectedOption] = React.useState<SelectOption<TValue> | undefined>(undefined);

  const displayLabel = useMemo((): string | undefined => {
    return selectedOption ? selectedOption.name : placeholderLabel;
  }, [placeholderLabel, selectedOption]);

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const isSelectedNavigationMenuItem = useCallback(
    (optionToCheck: SelectOption<TValue>): boolean => {
      return selectedOption?.value === optionToCheck.value;
    },
    [selectedOption]
  );

  const closeDropDown = useCallback((): void => {
    setAnchorElement(null);
  }, []);

  const handleDropDownOpen = useCallback((event: React.MouseEvent<HTMLElement>): Promise<void> | void => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleOptionSelected: OnClickHandlerWithParameters = useCallback(
    (dropDownSelection?: SelectOption<TValue>) =>
      (event: React.MouseEvent<HTMLElement>): void => {
        setSelectedOption(dropDownSelection);
        onSelectionChanged?.(patientService, dropDownSelection.value);
        closeDropDown();
      },
    [closeDropDown, onSelectionChanged, patientService]
  );

  const handleClickOutsideOfSelect = useCallback(
    (event: MouseEvent): void => {
      closeDropDown();
    },
    [closeDropDown]
  );

  useEffect(() => {
    if (initialSelectedOption && initialSelectedOption !== selectedOption) {
      setSelectedOption(initialSelectedOption);
    }
  }, [initialSelectedOption, selectedOption]);

  return (
    <ClickOutsideAwareComponent callback={handleClickOutsideOfSelect}>
      <StyledButton
        id={id ? id : 'select-drop-down-button'}
        aria-controls={open ? 'select-drop-down-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        color='neutral'
        disableElevation
        onClick={handleDropDownOpen}
        endIcon={<KeyboardArrowDown />}
      >
        {displayLabel}
      </StyledButton>
      <StyledMenu
        id='select-drop-down-menu'
        MenuListProps={{
          'aria-labelledby': id ? id : 'select-drop-down-button',
        }}
        anchorEl={anchorElement}
        open={open}
        onClose={closeDropDown}
      >
        {options.map((selectOption, index) => (
          <MenuItem
            key={index}
            onClick={handleOptionSelected(selectOption)}
            disableRipple
            sx={{
              color: isSelectedNavigationMenuItem(selectOption) ? Color.Lilac100 : 'inherit',
              background: isSelectedNavigationMenuItem(selectOption) ? Color.Lilac600 : 'inherit',
            }}
          >
            {selectOption.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </ClickOutsideAwareComponent>
  );
}

export default SelectDropDown;
