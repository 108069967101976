<div>
  <div class="patient-app-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div>
      <app-title-bar></app-title-bar>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="patient-app-content-header">
        <ion-toolbar>
          <ion-segment [value]="appointmentView">
            <ion-segment-button value="future" (click)="setView('future')"> Upcoming </ion-segment-button>
            <ion-segment-button value="past" (click)="setView('past')"> Past </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </div>
      <div>
        <div class="ion-text-center patient-app-content-ion-row-wrapper" *ngIf="!bookingOnly">
          <app-appointment-list-paginated
            [appointments]="appointmentView === 'future' ? futureAppointments : pastAppointments"
            [patients]="patients | async"
            [activePatient]="activePatient | async"
            [pageSize]="pageSize"
            [view]="appointmentView"
            (presentMenu)="presentMenu()"
            (updatePage)="updatePage($event)"
          >
          </app-appointment-list-paginated>
        </div>
        <div class="patient-app-content-footer" *ngIf="!bookingOnly">
          <ion-fab vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button
              color="accent"
              [disabled]="loadingIntakeForm || (activePatient | async)?.active === false"
              (click)="startAppointmentBookingFlow()"
            >
              <ion-icon [name]="loadingIntakeForm ? 'hourglass' : 'add'"></ion-icon>
            </ion-fab-button>
            <ion-badge color="accent">Book Appointment</ion-badge>
          </ion-fab>
        </div>
      </div>
    </div>
  </div>
</div>
