import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Document } from '@models';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private httpClient: HttpClient) {}

  /**
   * API call to get a signed URL so the user can upload a document
   * securely to google cloud storage.
   */
  public getSignedUrl(contentType: string, fileName: string, patientId: string, fileSizeBytes: number) {
    return this.httpClient.get<{ signedUrl: string; fileName: string }>(
      `${environment.apiUrl}/document/me/${patientId}/uploadUrlWithFilename`,
      {
        params: {
          contentType,
          fileName,
          fileSizeBytes: fileSizeBytes.toString(),
          restricted: 'false',
        },
      }
    );
  }

  public getReadUrl(type: any, file: string, patientId: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/document/me/${patientId}/readUrl/${type.content}/${type.type}/${file}`)
      .pipe(map((url) => url[0]));
  }

  public async getDocuments(patientId: number): Promise<Document[]> {
    const documentObject: any = await this.httpClient
      .get(`${environment.apiUrl}/documents/me/${patientId}`)
      .toPromise();
    return documentObject;
  }

  /**
   * Using the fetch api for this because it doesn't send the JWT, and has .blob() as a
   * part of the API.  If this can be done better using the HTTP client, then that works as well - KD
   */
  public async uploadFile(file: File, url: string): Promise<Response> {
    const filename = file.name;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'x-goog-content-length-range': `${file.size},${file.size}`,
        'x-goog-meta-restricted': 'false',
      },
      body: file,
    };
    return fetch(url, options);
  }

  /**
   * Using the fetch api for this because it doesn't send the JWT, and has .blob() as a
   * part of the API.  If this can be done better using the HTTP client, then that works as well - KD
   */
  public async viewFile(url, meta) {
    try {
      const response = await fetch(url, {
        headers: { 'Content-Type': meta.contentType },
      });
      const body = await response.blob();
      return URL.createObjectURL(body);
    } catch (error) {
      console.log('error in viewFile', error);
    }
  }

  // Returns true if a document with the given name was uploaded today for the given patientId
  public documentSuccessfullyUploaded(filename: string, patientId: number): Observable<boolean> {
    const url = `${environment.apiV2Url}/document/me/upload-success/${patientId}`;
    return this.httpClient.get<boolean>(url, {
      params: {
        filename,
      },
    });
  }
}
