import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';

import { DocumentsPageComponent } from './documents-page.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { DisplayComponent } from './display/display.component';

import { PatientService } from '../../core/services/patient.service';
import { Patient } from '../../../../../core/models/patient';
import { Observable, combineLatest } from 'rxjs';
import { OnInit } from '@angular/core';
import { map, delay } from 'rxjs/operators';

const routes: Routes = [
  {
    path: '',
    component: DocumentsPageComponent,
  },
];

@NgModule({
  imports: [
    PdfViewerModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
  ],
  declarations: [DocumentsPageComponent, DocumentCardComponent, DisplayComponent],
})
export class DocumentsPageModule implements OnInit {
  public patients: Observable<Patient[]>;
  public activePatient: Observable<Patient>;

  constructor(public patientService: PatientService) {}

  ngOnInit() {
    this.patients = this.patientService.patients;
    this.activePatient = combineLatest([this.patients, this.patientService.activePatient]).pipe(
      // Hack to prevent value of select being set before options are popualated
      delay(1),
      map<[Patient[], Patient], Patient>(([patients, activePatient]) => activePatient)
    );
  }
}
