import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Versions } from '../../../../../core/enums/versions';
import {
  ApplicationType,
  ClientConfiguration,
  version,
} from '../../../../../core/version';
import { environment } from '../../../environments/environment';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  public incompatibleVersion = false;
  // We have a separate client here because we can't use the same interceptors without a cyclical dependency
  // with AuthService.
  private httpClient: HttpClient;

  constructor(
    private alertService: AlertService,
    private httpBackend: HttpBackend,
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async checkVersion(): Promise<boolean> {
    const response = await this.httpClient
      .get<{ versionStatus: Versions }>(
        `${environment.apiUrl}/version/${version.current}`,
      )
      .toPromise();
    if (response.versionStatus === Versions.SuggestUpdate) {
      // This alert is not needed since we can push an updated version via Ionic AppFlow
      // this.alertService.alert({
      //   header: `Update Recommended `,
      //   message: `Your version of the application is out of date. Update to the latest version for access to the latest features.`
      // });
    } else if (response.versionStatus === Versions.Incompatable) {
      this.incompatibleVersion = true;
    }

    return !this.incompatibleVersion;
  }

  public async getVersionConfig(): Promise<ClientConfiguration> {
    try {
      return await this.httpClient
        .get<ClientConfiguration>(
          `${environment.apiUrl}/versionConfig/${ApplicationType.PATIENT}`,
        )
        .toPromise();
    } catch (error) {
      console.error('Error retrieving Version Config: ', error);
      throw error;
    }
  }
}
