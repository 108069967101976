import React from 'react';
import TitleBar, { TitleBarProps } from './TitleBar';
import ThemedComponent from '../../../react/theme/ThemedComponent';

export function TitleBarWrapper({
  router,
  patientService,
  accountPatients,
  hidePatientSelector,
  activePatient,
  onSelectionChanged,
}: TitleBarProps): React.JSX.Element {
  return (
    <ThemedComponent>
      <TitleBar
        router={router}
        patientService={patientService}
        accountPatients={accountPatients}
        hidePatientSelector={hidePatientSelector}
        activePatient={activePatient}
        onSelectionChanged={onSelectionChanged}
      />
    </ThemedComponent>
  );
}

export default TitleBarWrapper;
