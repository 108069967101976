import { Component, OnInit } from '@angular/core';
import { ChatService } from '../core/services/chat.service';
import { PatientService } from '../core/services/patient.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs-page.component.html',
  styleUrls: ['tabs-page.component.scss'],
})
export class TabsPageComponent implements OnInit {
  public isActivePatient: Observable<boolean>;

  constructor(public chatService: ChatService, public patientService: PatientService) {}

  ngOnInit() {
    this.isActivePatient = this.patientService.activePatient.pipe(map((patient) => !!patient));
  }
}
