import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { version } from '../../../../../core/version';

import { AuthService } from '../../core/services/auth.service';
import { PatientService } from '../../core/services/patient.service';
import { ChatService } from '../../core/services/chat.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Patient } from '@models';
import TitleBarWrapper from '../navigation/TitleBar/TitleBarWrapper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit, OnDestroy {
  @Input() pageName: string;
  @Input() back;
  @Input() loggedIn = true;
  @Input() hidePatientSelector = false;

  public patientsObservable: Observable<Patient[]>;
  public activePatientObservable: Observable<Patient>;
  public accountHolder: Patient;
  public activePatient: Patient;
  public patients: Patient[];
  public displayUnreadIndicator: Observable<boolean>;

  public version = version.current;

  protected readonly TitleBarWrapper = TitleBarWrapper;

  private destroyed = new Subject<boolean>();

  constructor(
    public patientService: PatientService,
    public router: Router,
    private authService: AuthService,
    private chatService: ChatService,
    private location: Location
  ) {}

  ngOnInit() {
    this.patientsObservable = this.patientService.patients;
    this.patientsObservable.pipe(takeUntil(this.destroyed)).subscribe((patients: Patient[]) => {
      if (patients && patients.length > 0) {
        this.accountHolder = patients[0];
        this.patients = patients;
      }
    });
    this.activePatientObservable = this.patientService.activePatient;
    this.activePatientObservable.pipe(takeUntil(this.destroyed)).subscribe((activePatient: Patient) => {
      if (activePatient) {
        this.activePatient = activePatient;
      }
    });

    this.displayUnreadIndicator = this.chatService.totalPatientUnreadCount.pipe(map((count) => count > 0));
  }

  onSelectionChanged(patientService: PatientService, patientId: number) {
    if (patientService) {
      const patient = patientService.getPatient(patientId);
      patientService.setActivePatient(patient);
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}
