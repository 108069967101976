import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function forbiddenCharValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenCharacters: { value: control.value } } : null;
  };
}

export function forbiddenDomainValidator(
  forbiddenDomains: string[],
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let forbiddenDomainError = null;
    forbiddenDomains.map((domain) => {
      if (control.value.includes(domain)) {
        // Validation is fine if the email is an alias.
        if (!isAliasEmail(control.value)) {
          forbiddenDomainError = { forbiddenDomain: true };
        }
      }
    });
    return forbiddenDomainError;
  };

  function isAliasEmail(email: any): boolean {
    if (email && typeof email === 'string') {
      // Validate that the '+' is used for alias and this character comes before the '@';
      return email.includes('+') && email.indexOf('+') < email.indexOf('@');
    }
  }
}
