import { StateAbbreviation } from '../enums/state-abbreviation';
import {
  stateZipCodeRanges,
  stateZipCodeRangesByState,
} from '../constants/state-zip-code-ranges';

/**
 * Returns the state abbreviation for a given zip code, otherwise returns null.
 * Zip code data is stable enough that this can be determined by
 * associating a given range of zip codes with a particular state.
 * This is adapted from the code in this SO answer:
 * https://stackoverflow.com/questions/28821804/how-can-i-quickly-determine-the-state-for-a-given-zipcode
 * @param zipCode
 */
export function getStateFromZip(zipCode: string): StateAbbreviation | null {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zipCode !== 'string') {
    throw new Error('Must pass zip code as a string');
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipCodeInteger = parseInt(zipCode, 10);

  for (const range of stateZipCodeRanges) {
    if (zipCodeInRange(zipCodeInteger, range)) {
      return range.code;
    }
  }

  return null;
}

export function doesZipMatchState(zipCode: string, state: StateAbbreviation) {
  const stateRange = stateZipCodeRangesByState[state];
  if (!zipCode || !stateRange) {
    return false;
  }
  return zipCodeInRange(parseInt(zipCode, 10), stateRange);
}

/**
 * Returns true if the supplied zipCode falls in the supplied range (inclusive)
 * @param zipCode
 * @param range
 */
export function zipCodeInRange(
  zipCode: number,
  range: { min: number; max: number },
) {
  return zipCode >= range.min && zipCode <= range.max;
}
