import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddPatientRelationship, Race, Relationship } from '@enums';
import { UtilityService } from '../../../core/services/utility.service';
import { PhoneNumberPipe } from '../../../core/pipes/phone-number.pipe';
import { forbiddenCharValidator, forbiddenDomainValidator } from '../validators';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PICK_FORMATS, PickDateAdapter } from '../../../date-picker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  USER_INPUT_FORBIDDEN_CHARS,
  USER_INPUT_FORBIDDEN_UI_MESSAGE,
} from '../../../../../../core/constants/forbidden-characters';

const phoneNumberTransformer = new PhoneNumberPipe();

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PatientFormComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
  ],
})
export class PatientFormComponent implements OnInit, OnDestroy {
  public static validateEmailsMatch: ValidatorFn = function (group: UntypedFormGroup) {
    const email = group.controls.email.value;
    const confirm = group.controls.confirmEmail.value;
    const emailMismatchError = email === confirm ? null : { emailMismatch: true };
    return emailMismatchError;
  };

  @Input() public editMode = false;

  public forbiddenErrorMessage = USER_INPUT_FORBIDDEN_UI_MESSAGE;
  public maxDateOfBirth: string;

  public form = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl(null, [
        Validators.required,
        forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS),
      ]),
      lastName: new UntypedFormControl(null, [Validators.required, forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)]),
      preferredName: new UntypedFormControl(null, [forbiddenCharValidator(USER_INPUT_FORBIDDEN_CHARS)]),
      sexAssignedAtBirth: new UntypedFormControl(null, Validators.required),
      gender: new UntypedFormControl(null, Validators.required),
      race: new UntypedFormControl(null, Validators.required),
      dateOfBirth: new UntypedFormControl(null, [
        Validators.required,
        (c: UntypedFormControl) => {
          const patientAge = this.calculateAgeOfPatient(c.value);
          if (patientAge && !this.editingAdultPatient) {
            return { tooOld: true };
          }
          return null;
        },
      ]),
      relationToAccountHolder: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.email, Validators.required, forbiddenDomainValidator(['@nice.healthcare'])])
      ),
      confirmEmail: new UntypedFormControl('', Validators.compose([Validators.email])),
      phone: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl(null),
    },
    {
      validators: [PatientFormComponent.validateEmailsMatch],
    }
  );

  public editingAdultPatient: boolean = null;
  // enums
  public relationship = Relationship;
  public addPatientRelationship = AddPatientRelationship;
  public race = Race;

  private destroyed = new Subject();

  constructor(public utilityService: UtilityService) {}

  ngOnInit() {
    const futureDate = new Date().setFullYear(new Date().getFullYear() + 100);
    this.maxDateOfBirth = new Date(futureDate).toISOString().split('T')[0];
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((v) => {
      if (this.editMode && this.editingAdultPatient === null) {
        const patientAge = new Date(v.dateOfBirth).getFullYear() - new Date().getFullYear();
        this.editingAdultPatient = patientAge <= -18;
      }
      this.onChange(v);
      this.onTouch();
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public handlePhoneNumberChange(value: any) {
    this.form.controls['phone'].setValue(phoneNumberTransformer.transform(value.detail.value));
  }

  public setEmail(value: any) {
    this.form.controls['email'].setValue(value.detail.value);
  }

  public setConfirmEmail(value: any) {
    this.form.controls['confirmEmail'].setValue(value.detail.value);
  }

  public writeValue(value: any): void {
    if (value) {
      return value && this.form.patchValue(value);
    }
  }

  public calculateAgeOfPatient(birthday: Date): boolean {
    const currentDate = new Date();
    const birthDate = new Date(birthday);
    const ageDifferenceInYears = currentDate.getFullYear() - birthDate.getFullYear();
    const ageDifferenceInMonths = currentDate.getMonth() - birthDate.getMonth();
    const ageDifferenceInDays = currentDate.getDate() - birthDate.getDate();
    return ageDifferenceInYears >= 18 && ageDifferenceInMonths >= 0 && ageDifferenceInDays >= 0;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { patientFormValid: false };
  }

  handleDateOfBirthChanges(event: MatDatepickerInputEvent<Date>) {
    const eventDate = new Date(event.target.value);
    eventDate.setUTCHours(12, 0, 0, 0);
    this.form.controls['dateOfBirth'].setValue(eventDate);
  }

  protected readonly Number = Number;
}
