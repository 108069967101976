import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ModalController } from '@ionic/angular';
import { find } from 'lodash';
import { Subject } from 'rxjs';

import { Patient } from '@models';
import { AlertService } from '../../../core/services/alert.service';
import { DynamicFormSchema, IntakeFormService } from '../../../core/services/intake-form.service';
import { PatientService } from '../../../core/services/patient.service';
import {
  SelectPatientFormComponent,
  SelectPatientFormValues,
} from '../../../shared/forms/select-patient-form/select-patient-form.component';
import { IntakeFormComponent, IntakeFormState } from '../../../shared/forms/intake-form/intake-form.component';
import {
  AppointmentSchedulingFormComponent,
  AppointmentSchedulingFormValues,
} from '../../../shared/forms/appointment-scheduling-form/appointment-scheduling-form.component';
import {
  AppointmentConfirmationFormComponent,
  AppointmentConfirmationFormValues,
} from '../../../shared/forms/appointment-confirmation-form/appointment-confirmation-form.component';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { AppointmentService, SchedulingErrorState } from '../../../core/services/appointment.service';
import { AppointmentBookingDto, ServiceAreaState, ServiceLineEligibility } from '@dto';
import { HttpErrorResponse } from '@angular/common/http';
import { getStateAbbreviationFromState, ServiceLine, State } from '@enums';
import {
  SelectStateFormComponent,
  SelectStateFormValues,
} from '../../../shared/forms/select-state-form/select-state-form.component';
import {
  SelectServiceLineFormComponent,
  SelectServiceLineFormValues,
} from '../../../shared/forms/select-service-line-form/select-service-line-form.component';
import { calculateCalendarYearsDifference, parseStandardDate } from '@utility';
import {
  samplePhysicalTherapyIntakeAnswers,
  samplePrimaryCareIntakeAnswers,
} from '../../../core/utilities/intakeHelper';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-book-appointment-dialog',
  templateUrl: './book-appointment-dialog.component.html',
  styleUrls: ['./book-appointment-dialog.component.scss'],
})
export class BookAppointmentDialogComponent implements OnInit, OnDestroy {
  private bookingOnly = false;

  constructor(
    private alertService: AlertService,
    private appointmentService: AppointmentService,
    private intakeFormService: IntakeFormService,
    private modalController: ModalController,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  get readyToSubmit() {
    return (
      this.selectPatientFormGroup.valid &&
      this.patientHealthHistoryIntakeFormGroup.valid &&
      this.patientServiceLineIntakeFormGroup.valid &&
      this.scheduleAppointmentFormGroup.valid &&
      this.confirmAppointmentFormGroup.valid
    );
  }

  get selectedPatients(): Patient[] {
    const patientIds = this.getSelectedPatientIds();

    return patientIds
      .map((id) => this.patientService.getPatient(id))
      .filter((patient: Patient) => {
        // Note the patients list in the service call could be empty in certain lifecycle conditions.  If that happens,
        // each request for a match would return `undefined`.  This will filter those out, so we don't mistakenly try to
        // use `undefined` as a patient object.
        return patient !== undefined && patient !== null;
      });
  }

  public healthHistoryIntakeFormSchema: DynamicFormSchema;
  public serviceLineIntakeFormSchema: DynamicFormSchema;
  public displayStates: State[];
  public serviceLinesEligibility: ServiceLineEligibility[] = null;
  public serviceAreaStates: ServiceAreaState[] = null;
  public patients: Patient[];
  public currentButtonText = 'Next';
  public isInitialized = false;
  public prepopulated = false;
  public expandedIntakeFormIndex = -1;
  public serviceLineIntakeSubmissionId: string;
  public schedulingErrorState: SchedulingErrorState = null;

  public selectStateFormGroup = new UntypedFormGroup({});
  public invalidStateFormGroup = new UntypedFormGroup({});
  public selectServiceLineFormGroup = new UntypedFormGroup({});
  public selectPatientFormGroup = new UntypedFormGroup({});
  public patientHealthHistoryIntakeFormGroup = new UntypedFormGroup({});
  public patientServiceLineIntakeFormGroup = new UntypedFormGroup({});
  public scheduleAppointmentFormGroup = new UntypedFormGroup({});
  public confirmAppointmentFormGroup = new UntypedFormGroup({});

  public isVisitInfoComplete = false;
  public showStateSelect = true;
  public showInvalidStateSelected = false;
  public showServiceLineSelect = false;
  public showPatientSelect = false;
  public showInvalidMentalHealthSelected = false;

  @ViewChildren('healthHistoryIntakeForm') healthHistoryIntakeForms: QueryList<IntakeFormComponent>;
  @ViewChildren('serviceLineIntakeForm') serviceLineIntakeForms: QueryList<IntakeFormComponent>;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('stepper') formStepper: MatStepper;
  @ViewChild('content') modalContent: ElementRef;

  public panelActive = false;
  public activeHealthHistoryIntakeForm: IntakeFormComponent = null;
  public activeServiceLineIntakeForm: IntakeFormComponent = null;
  public intakeActive = false;
  public serviceLinePrepopulatedAnswers: any = {};
  public healthHistoryPrepopulatedAnswers: any = {};

  private handleKeyUp = this.onKeyDown.bind(this);

  private primaryCareIntakeFormSchema: DynamicFormSchema;
  private mentalHealthTherapyIntakeFormSchema: DynamicFormSchema;
  private physicalTherapyIntakeFormSchema: DynamicFormSchema;
  private isDestroyed = new Subject();

  ngOnInit() {
    this.displayStates = Object.keys(State).map((stateKey: string) => State[stateKey]);
    this.patients = this.patientService.patientsSnapshot.filter((patient) => patient.active);
    this.initialize().then(() => {
      this.isInitialized = true;
    });
    document.addEventListener('keyup', this.handleKeyUp);

    this.bookingOnly = this.route.snapshot.queryParamMap.get('booking') === 'true';
  }

  async initialize() {
    this.serviceLinesEligibility = await this.appointmentService.getServiceLineEligibility().toPromise();

    const allStates = await this.appointmentService.getServiceAreaStates().toPromise();
    this.serviceAreaStates = allStates.filter((state: ServiceAreaState) => {
      // Filter out any states not in the normal 51.
      return State[state.abbreviation] !== null && State[state.abbreviation] !== undefined;
    });

    const defaultPatient = this.patientService.currentActivePatient;

    const defaultSelectedState = defaultPatient?.addresses[0]?.state ? State[defaultPatient.addresses[0].state] : null;

    this.selectStateFormGroup = SelectStateFormComponent.formModel({
      selectedState: defaultSelectedState,
    });

    this.selectServiceLineFormGroup = SelectServiceLineFormComponent.formModel({
      selectedServiceLine: null,
    });

    this.selectPatientFormGroup = SelectPatientFormComponent.formModel({
      patientIds: [],
    });

    this.confirmAppointmentFormGroup = AppointmentConfirmationFormComponent.formModel(defaultPatient);

    this.scheduleAppointmentFormGroup = AppointmentSchedulingFormComponent.formModel();

    this.selectPatientFormGroup.valueChanges.subscribe(() => this.handlePatientSelectionChange());

    await this.handlePatientSelectionChange();

    this.primaryCareIntakeFormSchema = await this.intakeFormService.getPrimaryCareForm();
    this.mentalHealthTherapyIntakeFormSchema = await this.intakeFormService.getMentalHealthTherapyForm();
    this.physicalTherapyIntakeFormSchema = await this.intakeFormService.getPhysicalTherapyForm();
    this.healthHistoryIntakeFormSchema = await this.intakeFormService.getHealthHistoryForm();
  }

  public openPanel(index: number): void {
    this.intakeActive = this.formStepper.selectedIndex === 1;
    this.panelActive = true;

    this.activeHealthHistoryIntakeForm = this.healthHistoryIntakeForms.toArray()[index];
    this.activeServiceLineIntakeForm = this.serviceLineIntakeForms.toArray()[index];
    this.expandedIntakeFormIndex = index;

    setTimeout(() => {
      if (index !== 0) {
        this.scrollToBottom();
      }

      this.formStepper.next();
      this.skipSteps();
    });
  }

  public closeDialog() {
    this.modalController.dismiss();
    if (this.bookingOnly) {
      window.open(environment.newWebUrl + '/appointments', '_self');
    }
  }

  public closePanel(): void {
    this.panelActive = this.panels.toArray().some((panel) => panel.expanded);
    if (!this.panelActive) {
      this.activeHealthHistoryIntakeForm = null;
      this.activeServiceLineIntakeForm = null;
    }
  }

  public back(): void {
    if (
      this.formStepper &&
      this.formStepper.selectedIndex === 1 &&
      this.activeHealthHistoryIntakeForm !== null &&
      (this.activeHealthHistoryIntakeForm.currentStep.key === 'medical-conditions' ||
        this.activeHealthHistoryIntakeForm.currentStep.key === 'health-history-changed')
    ) {
      // Skip back to custom forms.
      this.showStateSelect = false;
      this.showServiceLineSelect = false;
      this.showPatientSelect = true;
      this.formStepper.previous();
    } else if (
      this.formStepper &&
      this.formStepper.selectedIndex === 1 &&
      this.activeHealthHistoryIntakeForm === null &&
      this.activeServiceLineIntakeForm !== null &&
      (this.activeServiceLineIntakeForm.currentStep.key === 'medical-emergency-confirmation' ||
        this.activeServiceLineIntakeForm.currentStep.key === 'mental-health-emergency')
    ) {
      // Back to health history.
      this.activeHealthHistoryIntakeForm =
        this.healthHistoryIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
    } else if (this.activeHealthHistoryIntakeForm !== null && this.isSkippableStep()) {
      // Skip these pages for health history.
      this.activeHealthHistoryIntakeForm.back();
      setTimeout(() => {
        this.back();
      }, 10);
    } else if (this.activeHealthHistoryIntakeForm !== null) {
      this.activeHealthHistoryIntakeForm.back();
      this.currentButtonText = this.activeHealthHistoryIntakeForm.currentStep.buttonText
        ? this.activeHealthHistoryIntakeForm.currentStep.buttonText
        : 'Next';
    } else if (this.activeServiceLineIntakeForm !== null && this.isSkippableStep()) {
      // Skip these pages for service line intake.
      this.activeServiceLineIntakeForm.back();
      setTimeout(() => {
        this.back();
      }, 10);
    } else if (this.activeServiceLineIntakeForm !== null) {
      this.activeServiceLineIntakeForm.back();
      this.currentButtonText = this.activeServiceLineIntakeForm.currentStep.buttonText
        ? this.activeServiceLineIntakeForm.currentStep.buttonText
        : 'Next';
    }
  }

  public handleCustomFormBack(): void {
    if (this.formStepper && this.formStepper.selectedIndex === 0) {
      if (this.showPatientSelect) {
        this.showStateSelect = false;
        this.showServiceLineSelect = true;
        this.showPatientSelect = false;
      } else if (this.showServiceLineSelect) {
        this.showStateSelect = true;
        this.showServiceLineSelect = false;
        this.showPatientSelect = false;
      }
    }
  }

  public onKeyDown($event: KeyboardEvent): void {
    if (
      $event.key === 'Enter' &&
      this.activeHealthHistoryIntakeForm !== null &&
      this.activeHealthHistoryIntakeForm.form &&
      !this.activeHealthHistoryIntakeForm.form.invalid
    ) {
      this.next();
    } else if (
      $event.key === 'Enter' &&
      this.activeServiceLineIntakeForm !== null &&
      this.activeServiceLineIntakeForm.form &&
      !this.activeServiceLineIntakeForm.form.invalid
    ) {
      this.next();
    }
  }

  public next(): void {
    if (this.activeHealthHistoryIntakeForm !== null) {
      this.activeHealthHistoryIntakeForm.next();
    } else {
      this.activeServiceLineIntakeForm.next();
    }

    if (this.activeHealthHistoryIntakeForm !== null) {
      this.skipSteps();
      this.currentButtonText = this.activeHealthHistoryIntakeForm.currentStep.buttonText
        ? this.activeHealthHistoryIntakeForm.currentStep.buttonText
        : 'Next';
    } else if (this.activeServiceLineIntakeForm !== null) {
      this.skipSteps();
      this.currentButtonText = this.activeServiceLineIntakeForm.currentStep.buttonText
        ? this.activeServiceLineIntakeForm.currentStep.buttonText
        : 'Next';

      if (
        this.activeServiceLineIntakeForm.currentStep.key === 'ending-cannot-be-scheduled-for-someone-else' ||
        this.activeServiceLineIntakeForm.currentStep.key === 'ending-needs-specialized-care-services' ||
        this.activeServiceLineIntakeForm.currentStep.key === 'ending-needs-specialized-care-challenges'
      ) {
        this.showInvalidMentalHealthSelected = true;
      }
    }
  }

  public handleCustomFormNext() {
    if (this.formStepper && this.formStepper.selectedIndex === 0) {
      if (this.showPatientSelect) {
        this.showStateSelect = false;
        this.showServiceLineSelect = false;
        this.showPatientSelect = false;
        this.isVisitInfoComplete = true;

        this.populateIntakesForPatients();
        this.prepopulated = true;
        this.expandedIntakeFormIndex = 0;
        this.activeHealthHistoryIntakeForm =
          this.healthHistoryIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
        this.activeServiceLineIntakeForm = this.serviceLineIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
      } else if (this.showServiceLineSelect) {
        this.showStateSelect = false;
        this.showServiceLineSelect = false;
        this.showPatientSelect = true;

        switch (this.getSelectedServiceLine()) {
          case ServiceLine.PRIMARY_CARE:
            if (
              this.serviceLineIntakeFormSchema &&
              this.serviceLineIntakeFormSchema !== this.primaryCareIntakeFormSchema
            ) {
              this.alertService.error('Please restart your appointment request to change services.');
              this.closeDialog();
            } else {
              this.serviceLineIntakeFormSchema = this.primaryCareIntakeFormSchema;
            }
            break;
          case ServiceLine.PHYSICAL_THERAPY:
            if (
              this.serviceLineIntakeFormSchema &&
              this.serviceLineIntakeFormSchema !== this.physicalTherapyIntakeFormSchema
            ) {
              this.alertService.error('Please restart your appointment request to change services.');
              this.closeDialog();
            } else {
              this.serviceLineIntakeFormSchema = this.physicalTherapyIntakeFormSchema;
            }
            break;
          case ServiceLine.MENTAL_HEALTH:
            if (
              this.serviceLineIntakeFormSchema &&
              this.serviceLineIntakeFormSchema !== this.mentalHealthTherapyIntakeFormSchema
            ) {
              this.alertService.error('Please restart your appointment request to change services.');
              this.closeDialog();
            } else {
              this.serviceLineIntakeFormSchema = this.mentalHealthTherapyIntakeFormSchema;
            }
            break;
        }
      } else if (this.showStateSelect) {
        const selectedStateAbbreviation = this.getStateAbbreviation(this.selectStateFormGroup.value.selectedState);
        const selectedState = this.serviceAreaStates.find((state: ServiceAreaState) => {
          return selectedStateAbbreviation === state.abbreviation;
        });
        if (selectedState.inService) {
          this.showInvalidStateSelected = false;
          this.showStateSelect = false;
          this.showServiceLineSelect = true;
          this.showPatientSelect = false;
        } else {
          this.showInvalidStateSelected = true;
          this.showStateSelect = false;
          this.showServiceLineSelect = false;
          this.showPatientSelect = false;
        }
      } else {
        // Start over.
        console.error('Invalid selection.  Restarting workflow.');
        this.showStateSelect = true;
        this.showServiceLineSelect = false;
        this.showPatientSelect = false;
      }
    }
  }
  public stepChanged() {
    this.scrollToTop();

    setTimeout(async () => {
      // Persist the intake form if it needs to be saved (moving to next step).
      if (this.intakeActive && this.formStepper.selectedIndex === 2) {
        // Submit Service Line intakes
        try {
          const intakeForms = this.getServiceLineIntakeForms();
          this.serviceLineIntakeSubmissionId = (
            await this.intakeFormService.submitIntakeForms(this.activeServiceLineIntakeForm.intakeFormId, intakeForms)
          ).intakeSubmissionId;
        } catch (error) {
          console.error('Failed to save the intake form.', error);
          this.alertService.error(
            'Failed to save the intake form.  Please retry or contact support if this issue persists.'
          );

          // If an error occurs, this will push the user back into the intake step.  Maybe they can try again,
          // maybe not.  Either way, they can't move forward.
          this.formStepper.previous();
        }
      }

      this.intakeActive = this.formStepper.selectedIndex === 1;
    }, 0);
  }

  // Triggered on every step change to reset content position
  scrollToTop() {
    if (this.modalContent?.nativeElement) {
      this.modalContent.nativeElement.scrollTop = 0;
    }
  }

  scrollToBottom() {
    if (this.modalContent?.nativeElement) {
      this.modalContent.nativeElement.scrollTop = 1000;
    }
  }

  async handleHealthHistoryIntakeFormChange(patientId: number, update: IntakeFormState) {
    this.patientHealthHistoryIntakeFormGroup.controls[patientId].markAsTouched();
    if (update.isValid && update.isComplete) {
      this.patientHealthHistoryIntakeFormGroup.controls[patientId].setValue(update.model);

      // Submit Health History intakes
      try {
        const healthHistoryForms = this.getHealthHistoryIntakeForms();
        await this.intakeFormService.submitIntakeForms(this.activeHealthHistoryIntakeForm.intakeFormId, {
          [patientId]: healthHistoryForms[patientId],
        });
      } catch (error) {
        console.error('Failed to save the health history form.', error);
        this.alertService.error(
          'Failed to save the health history form.  Please retry or contact support if this issue persists.'
        );

        // If an error occurs, this will push the user back into the custom form step.  Maybe they can try again,
        // maybe not.  Either way, they can't move forward.
        this.formStepper.previous();
      }

      this.activeHealthHistoryIntakeForm = null;
    } else {
      this.patientHealthHistoryIntakeFormGroup.controls[patientId].setValue(null);
    }
  }

  handleServiceLineIntakeFormChange(patientId: number, update: IntakeFormState) {
    this.patientServiceLineIntakeFormGroup.controls[patientId].markAsTouched();
    if (update.isValid && update.isComplete) {
      this.patientServiceLineIntakeFormGroup.controls[patientId].setValue(update.model);

      // Note the selected patients list could be empty in certain lifecycle conditions.  Recognizing that scenario
      // will prevent the user from moving to the next step if no patients have been selected.
      const isLastPatient =
        this.selectedPatients.length > 0 && patientId === this.selectedPatients[this.selectedPatients.length - 1].id;

      if (isLastPatient) {
        // Move to next step.
        const currentFormStep = this.formStepper.selectedIndex;
        this.formStepper.next();
        const updatedFormStep = this.formStepper.selectedIndex;

        if (currentFormStep === 1 && updatedFormStep === 1) {
          // Form error.  Something else needs to be filled out.  Reset to first form.
          this.expandedIntakeFormIndex = 0;
          this.activeHealthHistoryIntakeForm =
            this.healthHistoryIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
          this.activeServiceLineIntakeForm =
            this.serviceLineIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
        }
      } else {
        // Set to view forms for the next patient.
        this.expandedIntakeFormIndex++;
        this.activeHealthHistoryIntakeForm =
          this.healthHistoryIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
        this.activeServiceLineIntakeForm = this.serviceLineIntakeForms.toArray()[this.expandedIntakeFormIndex] || null;
      }
    } else {
      this.patientServiceLineIntakeFormGroup.controls[patientId].setValue(null);
    }
  }

  async handlePatientSelectionChange() {
    const patientIds = this.getSelectedPatientIds();

    // Clear any previous validators and update validity for each control
    Object.values(this.patientHealthHistoryIntakeFormGroup.controls).forEach((control) => {
      control.setValidators([]);
      control.updateValueAndValidity();
    });

    Object.values(this.patientServiceLineIntakeFormGroup.controls).forEach((control) => {
      control.setValidators([]);
      control.updateValueAndValidity();
    });

    // For each selected patient create or update the related formControl for health history.
    const healthHistoryIntakeFormValidators = [Validators.required, this.validatePatientHealthHistoryFormCompleted];
    patientIds.forEach((patientId) => {
      if (patientId) {
        const controlExists = this.patientHealthHistoryIntakeFormGroup.contains(patientId.toString());

        if (controlExists) {
          this.patientHealthHistoryIntakeFormGroup.controls[patientId].setValidators(healthHistoryIntakeFormValidators);
          this.patientHealthHistoryIntakeFormGroup.controls[patientId].updateValueAndValidity();
        } else {
          this.patientHealthHistoryIntakeFormGroup.addControl(
            patientId.toString(),
            new UntypedFormControl(null, healthHistoryIntakeFormValidators)
          );
        }
      }
    });

    // For each selected patient create or update the related formControl
    const serviceLineIntakeFormValidators = [Validators.required, this.validatePatientIntakeFormCompleted];
    patientIds.forEach((patientId) => {
      if (patientId) {
        const controlExists = this.patientServiceLineIntakeFormGroup.contains(patientId.toString());

        if (controlExists) {
          this.patientServiceLineIntakeFormGroup.controls[patientId].setValidators(serviceLineIntakeFormValidators);
          this.patientServiceLineIntakeFormGroup.controls[patientId].updateValueAndValidity();
        } else {
          this.patientServiceLineIntakeFormGroup.addControl(
            patientId.toString(),
            new UntypedFormControl(null, serviceLineIntakeFormValidators)
          );
        }
      }

      if (this.confirmAppointmentFormGroup.touched === false && patientIds.length) {
        const selectedPatient = this.patientService.getPatient(patientIds[0]);
        this.confirmAppointmentFormGroup.patchValue(selectedPatient);
      }
    });
  }

  dismiss() {
    if (confirm('Are you sure you want to cancel the appointment booking process? Your progress will be lost.')) {
      this.modalController.dismiss();
      if (this.bookingOnly) {
        window.open(environment.newWebUrl + '/appointments', '_self');
      }
    }
  }

  async submit() {
    try {
      await this.alertService.loading();
      const appointmentSchedulingFormValue: AppointmentSchedulingFormValues = this.scheduleAppointmentFormGroup.value;
      const { selectedState } = this.selectStateFormGroup.value as SelectStateFormValues;
      const patientIds = this.getSelectedPatientIds();
      const confirmationFormValue = this.confirmAppointmentFormGroup.value as AppointmentConfirmationFormValues;
      const contactPhone = confirmationFormValue.phone.replace(/\D+/g, ''); // Remove non-numeric characters from phone number

      const selectedProviderId = appointmentSchedulingFormValue.providerId
        ? appointmentSchedulingFormValue.providerId
        : undefined;

      const selectedVisitState = getStateAbbreviationFromState(selectedState);

      const request: AppointmentBookingDto = {
        type: 'Virtual:SelfSchedule:V3',
        providerId: selectedProviderId, // If not populated, the API will select the best option.
        patientIds: patientIds,
        dateTime: appointmentSchedulingFormValue.appointmentTimeSlot.time,
        notes: appointmentSchedulingFormValue.note,
        appointmentTypeId: appointmentSchedulingFormValue.appointmentTypeId,
        phone: contactPhone,
        email: confirmationFormValue.email,
        contactFirstName: confirmationFormValue.firstName,
        contactLastName: confirmationFormValue.lastName,
        visitState: selectedVisitState,
        intakeSubmissionId: this.serviceLineIntakeSubmissionId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      await this.appointmentService.createBooking(request).toPromise();

      const patients = this.patientService.patientsSnapshot;
      const patientNames = patientIds
        .map((id) => find(patients, { id }) as Patient)
        .map((patient) => patient.name)
        .join(', ');
      this.alertService.success(`Successfully booked an appointment for ${patientNames}`);
      this.modalController.dismiss(true);
      if (this.bookingOnly) {
        window.open(
          `${environment.newWebUrl}/appointments?source=legacy-booking&success=true&patientId=${patientIds[0]}`,
          '_self'
        );
      }
    } catch (error) {
      console.error(error);
      if (
        error instanceof HttpErrorResponse &&
        ['NO_PROVIDER_AVAILABLE', 'TIMESLOT_NOT_AVAILABLE'].some((code) => error.error.reasonCode.includes(code))
      ) {
        this.alertService.error(
          'The selected time slot is no longer available. Try a different time, or message Patient Support if you continue having issues.'
        );
      } else {
        this.alertService.error('Error booking appointment. Please try again.');
      }
    } finally {
      this.alertService.hideLoading();
    }
  }

  public handleLongPressToSkipIntake() {
    const patientIds = this.getSelectedPatientIds();

    // If we are on a lower environment and have selected at least one patient, we can skip intake.
    // It only moves through intake if on the intake screen.
    if (this.isLongPressValid(patientIds) && this.formStepper.selectedIndex === 1) {
      setTimeout(() => {
        let defaultServiceLineIntakeAnswers = {};

        switch (this.getSelectedServiceLine()) {
          case ServiceLine.PRIMARY_CARE:
            defaultServiceLineIntakeAnswers = samplePrimaryCareIntakeAnswers;
            break;
          case ServiceLine.PHYSICAL_THERAPY:
            defaultServiceLineIntakeAnswers = samplePhysicalTherapyIntakeAnswers;
            break;
        }

        this.handleServiceLineIntakeFormChange(patientIds[this.expandedIntakeFormIndex], {
          isValid: true,
          model: defaultServiceLineIntakeAnswers,
          isComplete: true,
        });
      }, 100);
    }
  }

  public handleLongPressToSimulateError() {
    const patientIds = this.getSelectedPatientIds();

    // If we are on a lower environment and are on the scheduling screen, we can force an error to occur in one of
    // the three API calls being made (rerun the call if needed).
    if (this.isLongPressValid(patientIds) && this.formStepper.selectedIndex === 3) {
      // Generate a random error.
      const index = Math.floor(Math.random() * Object.keys(SchedulingErrorState).length);
      this.schedulingErrorState = Object.values(SchedulingErrorState)[index];
    }
  }

  public handleErrorStateReset() {
    this.schedulingErrorState = null;
  }

  public getIntakeFormIconColor(patientId: number) {
    const healthHistoryIntakeFormControl = this.patientHealthHistoryIntakeFormGroup.controls[patientId];
    const healthHistoryIntakeFormCompleteAndValid = healthHistoryIntakeFormControl.valid;
    const serviceLineIntakeFormControl = this.patientServiceLineIntakeFormGroup.controls[patientId];
    const serviceLineIntakeFormCompleteAndValid = serviceLineIntakeFormControl.valid;
    if (serviceLineIntakeFormCompleteAndValid && healthHistoryIntakeFormCompleteAndValid) {
      return 'accent';
    } else if (
      serviceLineIntakeFormControl.hasError('eligiblePatient') ||
      healthHistoryIntakeFormControl.hasError('eligiblePatient')
    ) {
      return 'warn';
    } else {
      return 'gray';
    }
  }
  public getIntakeFormIcon(patientId: number) {
    const healthHistoryIntakeFormControl = this.patientHealthHistoryIntakeFormGroup.controls[patientId];
    const serviceLineIntakeFormControl = this.patientServiceLineIntakeFormGroup.controls[patientId];
    if (
      serviceLineIntakeFormControl.hasError('eligiblePatient') ||
      healthHistoryIntakeFormControl.hasError('eligiblePatient')
    ) {
      return 'error';
    } else {
      return 'check_circle';
    }
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  private validatePatientHealthHistoryFormCompleted = (c: UntypedFormControl) => {
    // Form Incomplete, can't evaluate eligibility so no error
    if (c.value === null) {
      return null;
    }

    // This is a proxy for actual validation.  We check that certain fields are filled, and if so, it passes.
    // First scenario is that the history form was filled out in full.
    if (
      c.value['health-history-form-completed'] === false &&
      c.value['health-history-form-completed'] !== undefined &&
      c.value['health-history-form-completed'] !== null &&
      c.value['health-history-form-completed'] !== ''
    ) {
      return null;
    }

    // Second scenario is the user had a previous health history with no updates.
    if (
      c.value['health-history-form-completed'] === true &&
      c.value['health-history-changed'] === true &&
      c.value['health-history-changes'] !== undefined &&
      c.value['health-history-changes'] !== null &&
      c.value['health-history-changes'] !== ''
    ) {
      return null;
    }

    // Third scenario the user had a health history in the past and had no updates.
    if (c.value['health-history-form-completed'] === true && c.value['health-history-changed'] === false) {
      return null;
    }

    // Validation is not OK.  No visit reason was found to be populated.
    return { eligiblePatient: { valid: false } };
  };

  private validatePatientIntakeFormCompleted = (c: UntypedFormControl) => {
    // Form Incomplete, can't evaluate eligibility so no error
    if (c.value === null) {
      return null;
    }

    // This is a proxy for actual validation.  We check the fields marked in the visitReasonFieldId database entry
    // that identify the visit reasons.  Since we don't have an actual ending question/answer that everyone must answer,
    // if the patient fills out one of these visit reasons, we can assume the form is completed.
    // Note the concatenation protects against anything weird coming back in the response for visitReasonFieldId.
    let visitReasonsFieldIds: string[] = [];

    switch (this.getSelectedServiceLine()) {
      case ServiceLine.PRIMARY_CARE:
        visitReasonsFieldIds = visitReasonsFieldIds.concat(
          this.primaryCareIntakeFormSchema.metadata.visitReasonFieldId
        );
        break;
      case ServiceLine.PHYSICAL_THERAPY:
        visitReasonsFieldIds = visitReasonsFieldIds.concat(
          this.physicalTherapyIntakeFormSchema.metadata.visitReasonFieldId
        );
        break;
      case ServiceLine.MENTAL_HEALTH:
        visitReasonsFieldIds = visitReasonsFieldIds.concat(
          this.mentalHealthTherapyIntakeFormSchema.metadata.visitReasonFieldId
        );
        break;
    }

    for (let i = 0; i < visitReasonsFieldIds.length; i++) {
      const fieldId = visitReasonsFieldIds[i];
      if (c.value[fieldId] !== undefined && c.value[fieldId] !== null && c.value[fieldId] !== '') {
        // Value found.  Form is OK.
        return null;
      }
    }

    // Validation is not OK.  No visit reason was found to be populated.
    return { eligiblePatient: { valid: false } };
  };

  private getHealthHistoryIntakeForms(): any {
    const patientIds = this.getSelectedPatientIds();
    return patientIds.reduce((formData, patientId) => {
      formData[patientId] = this.patientHealthHistoryIntakeFormGroup.value[patientId];
      return formData;
    }, {});
  }

  private getServiceLineIntakeForms(): any {
    const patientIds = this.getSelectedPatientIds();
    return patientIds.reduce((formData, patientId) => {
      formData[patientId] = this.patientServiceLineIntakeFormGroup.value[patientId];
      return formData;
    }, {});
  }

  private populateIntakesForPatients(): void {
    const patientIds = this.getSelectedPatientIds();

    // If we are on a lower environment and have selected at least one patient, we can skip intake.
    // It only moves through intake if on the intake screen.
    patientIds.forEach((patientId) => {
      const patient = this.patientService.patientsSnapshot.find((patientObject) => {
        return patientObject.id === patientId;
      });

      if (!patient) {
        throw Error('Invalid patient ' + patient.id);
      }

      this.healthHistoryPrepopulatedAnswers[patientId] = {};
      this.healthHistoryPrepopulatedAnswers[patientId]['health-history-form-completed'] =
        this.hasHealthHistory(patient);
      this.healthHistoryPrepopulatedAnswers[patientId]['patient-age'] = this.getPatientAge(patient);
      this.healthHistoryPrepopulatedAnswers[patientId]['sex-assigned-at-birth'] =
        this.getSexAtBirthTypeformChoice(patient);

      this.serviceLinePrepopulatedAnswers[patientId] = {};
      this.serviceLinePrepopulatedAnswers[patientId]['patient-age'] = this.getPatientAge(patient);
      this.serviceLinePrepopulatedAnswers[patientId]['sex-assigned-at-birth'] =
        this.getSexAtBirthTypeformChoice(patient);
    });
  }

  private skipSteps() {
    while (this.isSkippableStep()) {
      this.next();
    }
  }

  private isSkippableStep() {
    return (
      this.checkStep('health-history-form-completed') ||
      this.checkStep('patient-age') ||
      this.checkStep('sex-assigned-at-birth')
    );
  }

  private checkStep(stepKey: string) {
    if (this.activeHealthHistoryIntakeForm !== null) {
      return (
        this.activeHealthHistoryIntakeForm.currentStep &&
        this.activeHealthHistoryIntakeForm.currentStep.key === stepKey &&
        this.activeHealthHistoryIntakeForm.model[stepKey] !== null &&
        this.activeHealthHistoryIntakeForm.model[stepKey] !== undefined
      );
    } else {
      return (
        this.activeServiceLineIntakeForm &&
        this.activeServiceLineIntakeForm.currentStep &&
        this.activeServiceLineIntakeForm.currentStep.key === stepKey &&
        this.activeServiceLineIntakeForm.model[stepKey] !== null &&
        this.activeServiceLineIntakeForm.model[stepKey] !== undefined
      );
    }
  }

  private isLongPressValid(patientIds: number[]) {
    return (
      (window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'patient.web.staging.noice.healthcare') &&
      patientIds !== undefined &&
      patientIds !== null &&
      patientIds.length > 0
    );
  }

  /**
   * Reverse mapping function extracting state abbreviation as a string from the {@link State} enum.
   *
   * @param state the enum to get the abbreviation for.
   *
   * @return the abbreviation for the given {@link State} or empty string if not found.
   */
  private getStateAbbreviation(state: State): string {
    let abbreviation: string = '';
    const enumKeys: string[] = Object.keys(State);

    for (let j = 0; j < enumKeys.length; j++) {
      if (State[enumKeys[j]] === state) {
        abbreviation = enumKeys[j];
        break;
      }
    }

    return abbreviation;
  }

  private hasHealthHistory(patient: Patient): boolean {
    let hasHealthHistory = false;

    if (this.serviceLinesEligibility) {
      // Note that history flag is the same regardless of the service line, so we only need to look at the first one.
      for (let i = 0; i < this.serviceLinesEligibility[0].patientEligibility.length; i++) {
        if (this.serviceLinesEligibility[0].patientEligibility[i].patientId === patient.id) {
          hasHealthHistory = this.serviceLinesEligibility[0].patientEligibility[i].hasHealthHistory;
          break;
        }
      }
    } else if (!this.serviceLinesEligibility) {
      console.error('Error retrieving eligibility.');
    }

    return hasHealthHistory;
  }

  private getPatientAge(patient: Patient): number {
    const birthDate = parseStandardDate(patient.dateOfBirth);
    return calculateCalendarYearsDifference(birthDate, new Date(), true);
  }

  private getSexAtBirthTypeformChoice(patient: Patient): string | undefined {
    switch (patient.sexAssignedAtBirth.valueOf()) {
      case 'MALE':
        return 'sex-assigned-at-birth-answer-male';
      case 'FEMALE':
        return 'sex-assigned-at-birth-answer-female';
      default:
        return undefined;
    }
  }

  private getSelectedPatientIds(): number[] {
    const patientIds = (this.selectPatientFormGroup.value as SelectPatientFormValues).patientIds;

    if (!Array.isArray(patientIds)) {
      return [patientIds];
    } else {
      return patientIds;
    }
  }

  private getSelectedServiceLine(): ServiceLine {
    return (this.selectServiceLineFormGroup.value as SelectServiceLineFormValues).selectedServiceLine;
  }
}
