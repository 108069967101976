import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Patient } from '@models';
import { Color } from '../../../react/theme/colors';
import AccountMenu from '../AccountMenu/AccountMenu';
import { Router } from '@angular/router';
import SelectDropDown, { OnSelectChangeHandler, SelectOption } from '../../../react/SelectDropDown/SelectDropDown';
import { PatientService } from '../../../core/services/patient.service';

export type TitleBarProps = {
  router: Router;
  patientService: PatientService;
  hidePatientSelector: boolean;
  accountPatients: Patient[];
  activePatient: Patient;
  onSelectionChanged: OnSelectChangeHandler<number>;
};

export function TitleBar({
  router,
  patientService,
  hidePatientSelector,
  accountPatients,
  activePatient,
  onSelectionChanged,
}: TitleBarProps): React.JSX.Element {
  const selectOptions: SelectOption<number>[] = useMemo(() => {
    return accountPatients
      ? accountPatients.map((patient: Patient): SelectOption<number> => {
          return { name: patient.firstName + ' ' + patient.lastName, value: patient.id };
        })
      : [];
  }, [accountPatients]);

  const selectedOption: SelectOption<number> = useMemo(() => {
    const activePatientOption = selectOptions.find((option: SelectOption<number>): boolean => {
      return option.value === activePatient.id;
    });

    return activePatientOption ? activePatientOption : selectOptions[0];
  }, [activePatient, selectOptions]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '64px',
        backgroundColor: Color.BaseWhite,
        borderBottom: '1px solid ' + Color.Slate500,
      }}
    >
      {!hidePatientSelector && (
        <Box sx={{ float: 'left', marginTop: '12px', marginLeft: '256px' }}>
          <Box sx={{ float: 'left', marginTop: '5px', marginRight: '15px' }}>Selected Patient</Box>
          <Box sx={{ float: 'left', marginRight: '15px' }}>
            <SelectDropDown<number>
              placeholderLabel={'Select Patient'}
              options={selectOptions}
              initialSelectedOption={selectedOption}
              onSelectionChanged={onSelectionChanged}
              patientService={patientService}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ float: 'right', marginTop: '6px', marginRight: '24px' }}>
        {accountPatients && accountPatients.length > 0 && (
          <AccountMenu router={router} accountHolder={accountPatients[0]} />
        )}
      </Box>
    </Box>
  );
}

export default TitleBar;
