export enum ConstitutionalGeneralReview {
  weakness = 'Weakness',
  fever = 'Fever',
  chills = 'Chills',
  fatigue = 'Fatigue',
  significantWeightChange = 'Significant Weight Change',
}

export enum HeadReview {
  hairLoss = 'Hair Loss',
  scalpTenderness = 'Scalp Tenderness',
  headache = 'Headache',
  jawPainWithChewing = 'Jaw Pain w/ Chewing',
}

export enum EyesReview {
  itching = 'Itching',
  redness = 'Redness',
  thinWateryDrainage = 'Thin Watery Drainage',
  thickPurulentDrainage = 'Thick Purulent Drainage',
  visionChanges = 'Vision Changes',
  pain = 'Pain',
}

export enum EarsNoseMouthThroatReview {
  hearingProblem = 'Hearing Problem',
  tinnitus = 'Tinnitus',
  vertigo = 'Vertigo',
  earPainRight = 'Ear Pain - Right',
  earPainLeft = 'Ear Pain - Left',
  earPainBilateral = 'Ear Pain - Bilateral',
  earDrainage = 'Ear Drainage',
  nasalCongestion = 'Nasal Congestion',
  rhinorrhea = 'Rhinorrhea',
  nosebleeds = 'Nosebleeds',
  sinusPain = 'Sinus Pain',
  nasalBlockage = 'Nasal Blockage',
  purulentNasalDrainage = 'Purulent Nasal Drainage',
  soreThroat = 'Sore Throat',
  pharyngealErythema = 'Pharyngeal Erythema',
  enlargedTonsils = 'Enlarged Tonsils',
  tonsilarExudate = 'Tonsilar Exudate',
  painfulSwallowing = 'Painful Swallowing',
  difficultySwallowing = 'Difficulty Swallowing',
  muffledVoice = 'Muffled Voice',
  dryMouth = 'Dry Mouth',
  hoarseness = 'Hoarseness',
  frequentSneezing = 'Frequent Sneezing',
  seasonalAllergies = 'Seasonal Allergies',
}

export enum NeckReview {
  neckStiffness = 'Neck Stiffness',
  lymphadenopathy = 'Lymphadenopathy',
  lymphTenderness = 'Lymph Tenderness',
}

export enum GenitourinaryFemaleReview {
  painWithUrination = 'Pain with urination',
  urinaryFrequency = 'Urinary frequency',
  urinaryUrgency = 'Urinary urgency',
  bloodInUrine = 'Blood in urine',
  vaginalDischarge = 'Vaginal discharge',
  vaginalOdor = 'Vaginal odor',
  vaginalItching = 'Vaginal itching',
  vaginalPain = 'Vaginal pain',
}

export enum GenitourinaryMaleReview {
  painWithUrination = 'Pain with urination',
  urinaryFrequency = 'Urinary frequency',
  urinaryUrgency = 'Urinary urgency',
  bloodInUrine = 'Blood in urine',
  penileDischarge = 'Penile discharge',
  penilePain = 'Penile pain',
  erectileDysfunction = 'Erectile dysfunction',
  abnormalEjaculation = 'Abnormal ejaculation',
  changeInUrineStream = 'Change in urine stream',
}

export enum CardiovascularReview {
  chestPain = 'Chest Pain',
  palpitations = 'Palpitations',
  irregularHeartbeat = 'Irregular Heartbeat',
}

export enum RespiratoryReview {
  shortnessOfBreath = 'SOB',
  wheezing = 'Wheezing',
  sputumProduction = 'Sputum Productions',
  postussiveEmesis = 'Postussive Emesis',
  couch = 'Cough',
}

export enum GastrointestinalReview {
  pain = 'Pain',
  nausea = 'Nausea',
  diarrhea = 'Diarrhea',
  vomiting = 'Vomiting',
  lossOfAppetite = 'Loss of Appetite',
  bloating = 'Bloating',
  constipation = 'Constipation',
}

export enum MusculoskeletalReview {
  musclePain = 'Muscle Pain',
  jointPain = 'Joint Pain',
  stiffness = 'Stiffness',
  backache = 'Backache',
  swelling = 'Swelling',
  redness = 'Redness',
  tenderness = 'Tenderness',
  limitationOfMotion = 'Limitation of Motion',
}

export enum BreastReview {
  lumps = 'Lumps',
  painDiscomfort = 'Pain / Discomfort',
  nippleDischarge = 'Nipple Discharge',
}

export enum IntegumentaryReview {
  rashes = 'Rashes',
  lesions = 'Lesions',
  itching = 'Itching',
  redness = 'Redness',
  dryness = 'Dryness',
  pruritis = 'Pruritis',
}

export enum NeurologicalReview {
  fainting = 'Fainting',
  blackouts = 'Blackouts',
  seizures = 'Seizures',
  weakness = 'Weakness',
  paralysis = 'Paralysis',
  numbnessOrLossOfSensation = 'Numbness or Loss of Sensation',
  tingling = 'Tingling',
  tremorsOrOtherInvoluntaryMovements = 'Tremors or Other Involuntary Movements',
  unexplainedHeadaches = 'Unexplained Headaches',
}

export enum PsychiatricReview {
  nervousness = 'Nervousness',
  depressions = 'Depression',
  memoryChange = 'Memory Change',
  anxiety = 'Anxiety',
  suicidalOrHomicidalIdeation = 'Suicidal or Homicidal Ideation',
}

export enum EndocrineReview {
  heatIntolerance = 'Heat Intolerance',
  coldIntolerance = 'Cold Intolerance',
  excessiveSweating = 'Excessive Sweating',
  excessiveThirst = 'Excessive Thirst',
  excessiveHunger = 'Excessive Hunger',
}

export enum HematologicLymphaticReview {
  swollenLymphNodes = 'Swollen Lymph Nodes',
  historyOfBloodClots = 'History of Blood Clots',
  bleedingTendency = 'Bleeding Tendency',
  easyBruising = 'Easy Bruising',
  historyOfTransfusions = 'History of Transfusions',
}
