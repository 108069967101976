export enum UrinalysisResults {
  positiveLikelyInfectionNoCulture = 'Positive, likely infection, no culture',
  positiveLikelyInfectionCulturePending = 'Positive, likely infection, culture pending',
  negativeDipLikelyNoInfectionNoCulture = 'Negative dip, likely no infection, no culture',
  negativeDipCulturePending = 'Negative dip, culture pending',
}

export enum Glucose {
  negative = 'Negative',
  oneHundred = '100',
  twoHundredFifty = '250',
  fiveHundred = '500',
  oneThousand = '1000',
  twoThousandPlus = '2000+',
}

export enum Bilirubin {
  negative = 'Negative',
  small = 'Small +',
  medium = 'Medium ++',
  large = 'Large +++',
}

export enum Ketones {
  negative = 'Negative',
  trace = 'Trace',
  small = 'Small',
  moderate = 'Moderate',
  large = 'Large',
}

export enum SpecificGravity {
  zero = '1.000',
  five = '1.005',
  ten = '1.010',
  fifteen = '1.015',
  twenty = '1.020',
  twentyFive = '1.025',
  thirty = '1.030',
}

export enum Blood {
  negative = 'Negative',
  traceNonHemolyzed = 'Trace (Non-Hemolyzed)',
  moderateNonHemolyzed = 'Moderate (Non-Hemolyzed)',
  traceHemolyzed = 'Trace (Hemolyzed)',
  smallHemolyzed = 'Small (Hemolyzed)',
  moderateHemolyzed = 'Moderate (Hemolyzed)',
  largeHemolyzed = 'Large (Hemolyzed)',
}

export enum pH {
  five = '5.0',
  six = '6.0',
  sixHalf = '6.5',
  seven = '7.0',
  sevenHalf = '7.5',
  eight = '8.0',
  eightHalf = '8.5',
}

export enum Protein {
  negative = 'Negative',
  trace = 'Trace',
  thirty = '30',
  oneHundred = '100',
  threeHundred = '300',
  twoThousandPlus = '2000+',
}

export enum Urobilinogen {
  pointTwo = '0.2',
  one = '1',
  two = '2',
  four = '4',
  eight = '8',
}

export enum Leukocytes {
  negative = 'Negative',
  trace = 'Trace',
  small = 'Small +',
  medium = 'Medium ++',
  large = 'Large +++',
}

export enum RapidInfluenzaAAndB {
  negativeForInfluenzaAAndB = 'Negative for Influenza A & B',
  positiveForInfluenzaA = 'Positive for Influenza A',
  positiveForInfluenzaB = 'Positive for Influenza B',
  positiveForInfluenzaAAndB = 'Positive for Influenza A & B',
}

export enum Microalbumin {
  normal = 'Normal (Under 20mg/L)',
  microalbuminuria = 'Microalbuminuria (20mg/L - 200mg/L)',
  clinicalAlbuminuria = 'Clinical Albuminuria (Greater Than 200mg/L)',
}

export enum Creatinine {
  lessThanOneHundred = 'Less Than 100mg/L',
  oneHundredToFiveHundred = '100mg/L - 500mg/L',
  fiveHundredToOneThousand = '500mg/L - 1000mg/L',
  oneThousandToTwoThousand = '1000mg/L - 2000mg/L',
  twoThousandToThreeThousand = '2000mg/L - 3000mg/L+++',
}

export enum AlbuminCreatinineRation {
  normal = 'Normal (<30mg/g)',
  abnormal = 'Abnormal (30-300mg/g)',
  highAbnormal = 'High Abnormal (>300mg/g)',
}
