import React, { PropsWithChildren, useCallback } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Router } from '@angular/router';
import { MenuItem as MenuItemDetails } from '../menuItem';
import { Color } from '../../../react/theme/colors';
import MenuItem from '@mui/material/MenuItem';

export type NavigationMenuItemProps = PropsWithChildren<{
  router: Router;
  menuItem: MenuItemDetails;
}>;

export function NavigationMenuItem({ router, menuItem }: NavigationMenuItemProps): React.JSX.Element {
  const currentUrl = router.url;

  const isSelectedNavigationMenuItem = useCallback(
    (routeTarget: string): boolean => {
      return routeTarget === currentUrl;
    },
    [currentUrl]
  );

  return (
    <MenuItem
      key={menuItem.title}
      sx={{
        paddingLeft: 0,
        background: isSelectedNavigationMenuItem(menuItem.internalLink) ? Color.Lilac600 : 'inherit',
      }}
    >
      <ListItemButton
        disableRipple={true}
        onClick={(): void => {
          if (menuItem.externalLink) {
            window.open(menuItem.externalLink, '_self');
          } else if (menuItem.internalLink) {
            router.navigate([menuItem.internalLink]);
          } else {
            console.error('No target has been set for this navigation menu item.');
          }
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ListItemIcon sx={{ color: isSelectedNavigationMenuItem(menuItem.internalLink) ? Color.Lilac100 : 'inherit' }}>
          {menuItem.icon}
        </ListItemIcon>
        <ListItemText
          primary={menuItem.title}
          sx={{ color: isSelectedNavigationMenuItem(menuItem.internalLink) ? Color.Lilac100 : 'inherit' }}
        />
      </ListItemButton>
    </MenuItem>
  );
}

export default NavigationMenuItem;
