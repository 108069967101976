export enum SquareCardBrands {
  'OTHER_BRAND',
  'VISA',
  'MASTERCARD',
  'AMERICAN_EXPRESS',
  'DISCOVER',
  'DISCOVER_DINERS',
  'JCB',
  'CHINA_UNIONPAY',
  'SQUARE_GIFT_CARD',
}

export enum InvoiceStatus {
  COMPLETED = 'PAID',
  OPEN = 'PAYMENT DUE',
  CANCELED = 'CANCELED',
}
