import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentName',
})
export class DocumentNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    // Split off the prefix
    const split = value.split(/\/(?=[^\/]+$)/);
    const [prefix, name] = split;
    // Return the name (stripped of timestamp)
    return name.substr(0, name.lastIndexOf('_')) || name;
  }
}
