<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Edit Patient</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" color="light">
  <app-patient-form [formControl]="form" [editMode]="true"></app-patient-form>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button [disabled]="!form.valid" (click)="submit()" color="accent">
      <ion-icon name="checkmark"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
