import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuController, Platform } from '@ionic/angular';

import { AuthService } from './core/services/auth.service';
import { PatientService } from './core/services/patient.service';
import { Patient } from '@models';
import { UtilityService } from './core/services/utility.service';
import { AlertService } from './core/services/alert.service';
import { ChatService, CountSet } from './core/services/chat.service';
import { VersionService } from './core/services/version.service';
import { get } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public patients: Observable<Patient[]>;
  public activePatient: Observable<Patient>; // same as above
  public unreadObject: Observable<CountSet>;
  public showPharmacyCard: Observable<boolean>;

  private notificationsSubscription: Subscription;

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    public chatService: ChatService,
    private menu: MenuController,
    private patientService: PatientService,
    private platform: Platform,
    private utility: UtilityService,
    private versionService: VersionService
  ) {}

  ngOnInit() {
    this.unreadObject = this.chatService.unreadObject;
    this.patients = this.patientService.patients;
    this.activePatient = this.patientService.activePatient;
    this.setupShowPharmacyCardObservable();

    this.versionService
      .checkVersion()
      .then(async (isValidVersion: boolean) => {
        if (isValidVersion) {
          this.initializeApp();
        } else {
          const alert = await this.alertService.alert({
            header: 'Update Required',
            message: `Your version of the application is out of date
          and needs to be updated before proceeding`,
          });
          await alert.onDidDismiss();
          if (this.authService.isAuthenticated()) {
            await this.authService.logout();
          }
        }
      })
      .catch(async (error: any) => {
        console.error('An error occurred when checking for the version. ', error);
        await this.authService.logout();
      });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (!this.utility.checkIfPlatformSupported().isSupported) {
        const alert = await this.alertService.alert({
          header: 'Browser not supported',
          message: this.utility.checkIfPlatformSupported().message,
        });
        await alert.onDidDismiss();
        if (this.authService.isAuthenticated()) {
          await this.authService.logout();
        }
      } else {
        await this.authenticate();
      }
    });
  }

  goToPrivacyPolicy() {
    window.open('https://www.nice.healthcare/privacy-policy', '_blank');
  }

  setActivePatient(patient: Patient) {
    this.patientService.setActivePatient(patient);
  }

  closeMenu() {
    this.menu.close();
  }

  ngOnDestroy() {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }

  private async authenticate() {
    await this.authService.handleAuthentication();
  }

  private setupShowPharmacyCardObservable() {
    this.showPharmacyCard = this.activePatient.pipe(
      map((patient: Patient) => {
        if (patient && patient.membership && patient.membership.company) {
          const cardEligible = get(patient, 'membership.membershipType.rxCardEligible');
          const terminationDate = new Date(Date.parse(patient.membership.company.terminationDate));
          const today = new Date();
          const notTerminated = isNaN(terminationDate.valueOf()) || terminationDate > today;
          const hasGroupId = get(patient, 'membership.company.prescriptionCardGroupId');

          return (
            cardEligible !== null &&
            cardEligible !== undefined &&
            cardEligible === true &&
            notTerminated &&
            hasGroupId !== null &&
            hasGroupId !== undefined &&
            hasGroupId.length > 0
          );
        }
        return false;
      })
    );
  }
}
