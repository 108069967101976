<ion-grid [formGroup]="form">
  <ion-label>
    <h3>Contact Information</h3>
  </ion-label>
  <ion-item>
    <ion-label
      color="{{ form.controls.firstName.invalid && form.controls.firstName.touched ? 'danger' : 'tertiary' }}"
      position="floating"
      >Contact First Name (REQUIRED)</ion-label
    >
    <ion-input
      color="{{ form.controls.firstName.invalid && form.controls.firstName.touched ? 'danger' : inputColor }}"
      formControlName="firstName"
      maxlength="42"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label
      color="{{ form.controls.lastName.invalid && form.controls.lastName.touched ? 'danger' : 'tertiary' }}"
      position="floating"
      >Contact Last Name (REQUIRED)</ion-label
    >
    <ion-input
      color="{{ form.controls.lastName.invalid && form.controls.lastName.touched ? 'danger' : inputColor }}"
      formControlName="lastName"
      maxlength="42"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label
      color="{{ form.controls.phone.invalid && form.controls.phone.touched ? 'danger' : 'tertiary' }}"
      position="floating"
      >Phone Number (REQUIRED)</ion-label
    >
    <ion-input
      color="{{ form.controls.phone.invalid && form.controls.phone.touched ? 'danger' : inputColor }}"
      formControlName="phone"
      [value]="form.get('phone').value | phoneNumber"
      maxlength="14"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label
      color="{{ form.controls.email.invalid && form.controls.email.touched ? 'danger' : 'tertiary' }}"
      position="floating"
      >E-mail (REQUIRED)</ion-label
    >
    <ion-input
      color="{{ form.controls.email.invalid && form.controls.email.touched ? 'danger' : inputColor }}"
      formControlName="email"
    ></ion-input>
  </ion-item>
</ion-grid>
