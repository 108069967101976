import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, timer, of, interval, combineLatest, Subject } from 'rxjs';
import {
  concat,
  switchMap,
  startWith,
  delay,
  tap,
  skip,
  map,
  catchError,
  take,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';

import { MessageDto } from '../../../../../core/dto/message';
import { environment } from '../../../environments/environment';
import { ChannelType } from '../../../../../core/enums/message';
import { AuthService } from './auth.service';
import { PatientService } from './patient.service';
import { Patient } from '../../../../../core/models/patient';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export interface CountSet {
  [patientId: number]: number;
}
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _messages = new BehaviorSubject<{ [messageId: number]: MessageDto }>({});

  public providerMessages = this._messages.asObservable().pipe(
    map((messageStore) => {
      return Object.values(messageStore)
        .filter((message) => message.channel === ChannelType.provider)
        .sort((msgA, msgB) => new Date(msgA.createdOn).valueOf() - new Date(msgB.createdOn).valueOf());
    })
  );

  public coordinatorMessages = this._messages.asObservable().pipe(
    map((messageStore) => {
      return Object.values(messageStore)
        .filter((message) => message.channel === ChannelType.coordinator)
        .sort((msgA, msgB) => new Date(msgA.createdOn).valueOf() - new Date(msgB.createdOn).valueOf());
    })
  );

  public providerUnreadCount = this.providerMessages.pipe(
    map((messages) => messages.filter((message) => message.readByPatient === false).length)
  );

  public coordinatorUnreadCount = this.coordinatorMessages.pipe(
    map((messages) => messages.filter((message) => message.readByPatient === false).length)
  );

  public unreadCount = combineLatest(this.providerUnreadCount, this.coordinatorUnreadCount).pipe(
    map(([count1, count2]) => count1 + count2)
  );

  private lastMessageReceivedAt;

  private _totalPatientUnreadCount = new BehaviorSubject<number>(0);
  public totalPatientUnreadCount = this._totalPatientUnreadCount.asObservable();

  private _unreadObject = new BehaviorSubject<CountSet>(null);
  public unreadObject = this._unreadObject.asObservable();

  private messagesRead: string[] = [];
  public activeChatChannel: 'provider' | 'coordinator' = 'provider';

  private pollingRate: number;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private patientService: PatientService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.patientService.activePatient.subscribe(() => {
      this._messages.next({});
      this.lastMessageReceivedAt = undefined;
    });

    const load$ = new BehaviorSubject([]);
    this.patientService.patients.subscribe((patients) => {
      if (patients && patients.length > 0) {
        this.getUnreadMessagesForPatients(patients);
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((activatedRoute: ActivatedRoute) => activatedRoute.outlet === 'primary'),
        mergeMap((activatedRoute: ActivatedRoute) => activatedRoute.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.pollingRate = event.shortenChatPollingRate
          ? environment.chatPollingRate
          : environment.chatPollingRateBackground;
      });

    this.subscribeToNewMessages(load$);
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private subscribeToNewMessages(trigger: BehaviorSubject<any>) {
    console.log('Creating a new message subscription');

    // only refresh messages after last poll has completed
    // https://nehalist.io/polling-in-angular/
    // https://blog.strongbrew.io/rxjs-polling/
    const whenToRefresh$ = of([]).pipe(
      switchMap(() => {
        return of([]).pipe(delay(this.pollingRate));
      }),
      tap((_) => trigger.next([])),
      skip(1)
    );

    trigger
      .pipe(
        switchMap(() => this.patientService.activePatient),
        switchMap((patient) => this.fetchMessages(patient).pipe(concat(whenToRefresh$)))
      )
      .subscribe(
        (messages) => this.handleNewMessages(messages),
        (error) => {
          console.error(error);
          setTimeout(() => this.subscribeToNewMessages(trigger), 2000);
        }
      );
  }

  private fetchMessages(patient: Patient) {
    const patientId = patient && patient.id ? patient.id : null;

    if (patientId === null || patientId === undefined) {
      return of([]);
    }

    const url = environment.apiUrl.concat(`/messages/me/${patientId}`);

    const params: { since?: string; markRead: string[] } = {
      markRead: this.messagesRead,
    };

    this.messagesRead = [];

    if (this.lastMessageReceivedAt) {
      params.since = this.lastMessageReceivedAt.toString();
    }

    return this.http.get<MessageDto[]>(url, { params });
  }

  private handleNewMessages(messages: MessageDto[], skipMostRecent = false) {
    const messageStore = this._messages.getValue();

    messages.forEach((message) => {
      if (messageStore[message.id] === undefined || messageStore[message.id].updatedOn < message.updatedOn) {
        messageStore[message.id] = message;
      }
    });

    this._messages.next(messageStore);

    const mostRecentMessage = messages[messages.length - 1];

    if (mostRecentMessage && !skipMostRecent) {
      this.lastMessageReceivedAt = mostRecentMessage.updatedOn;
    }
  }

  public async sendMessage(text: string, channel: ChannelType) {
    const url = environment.apiUrl.concat('/messages/me');
    const userId = this.authService.currentAuthState.profile.sub;
    const patientId = this.patientService.currentActivePatient.id;
    const senderName = this.patientService.currentActivePatient.name;

    const data: MessageDto = {
      id: null,
      createdOn: null,
      updatedOn: null,
      deleted: false,
      text,
      channel,
      userId,
      patientId,
      senderName,
      fromNiceStaff: false,
      claimedByNiceStaff: false,
      readByPatient: true,
    };

    const result = await this.http
      .post(url, data)
      .toPromise()
      .then((response: MessageDto) => {
        this.handleNewMessages([response], true);
      });

    return result;
  }

  public markMessageRead(message: MessageDto) {
    this.messagesRead.push(message.id.toString());
    this.updateReadMessageCount();
  }

  /**
   * gets the message counts for all patients associated with a user
   * @param patients
   */
  public async getUnreadMessagesForPatients(patients: Patient[]) {
    const url = environment.apiUrl.concat('/messages/me/unread');
    const unread = (await this.http.get(url).toPromise()) as CountSet;
    /**
     * if the patient is part of the patients array, add the count.
     * needs this check because the object may have ids that are not included
     * in the array of patients, so the count can be off.
     * @return the total count
     */
    const count = Object.entries(unread).reduce((acc, el) => {
      if (patients.find((p) => p.id.toString() === el[0])) {
        acc = acc + el[1];
      }
      return acc;
    }, 0);
    this._unreadObject.next(unread);
    this._totalPatientUnreadCount.next(count);
  }

  /**
   * needed to give the appearance of having a message "read" based on
   * how MarkMessageRead also gives that appearance.  There isn't a BE PUT request
   * that can be tapped into, where we can re-GET the unread messages, just the
   * params for the GET messages request, which seems clunky and not as immediate.
   */
  public updateReadMessageCount() {
    combineLatest([this.unreadObject, this.totalPatientUnreadCount])
      .pipe(take(1))
      .subscribe(([object, count]) => {
        const patient = this.patientService.currentActivePatient;
        if (object && patient) {
          // Reduce total patient count by amount that the patient had
          const patientCount = count - object[patient.id];
          // change count for current patient to zero
          this._unreadObject.next({ ...object, [patient.id]: 0 });
          this._totalPatientUnreadCount.next(patientCount);
        }
      });
  }
}
