import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { ServiceLineEligibility } from '@dto';
import { ServiceLine } from '@enums';

export interface SelectServiceLineFormValues {
  selectedServiceLine: ServiceLine;
}

@Component({
  selector: 'app-select-service-line-form',
  templateUrl: './select-service-line-form.component.html',
  styleUrls: ['./select-service-line-form.component.scss'],
})
export class SelectServiceLineFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() serviceLinesEligibility: ServiceLineEligibility[];

  constructor(public utilityService: UtilityService) {}

  static formModel(initValues?: SelectServiceLineFormValues) {
    return new UntypedFormGroup({
      selectedServiceLine: new UntypedFormControl(
        initValues ? initValues.selectedServiceLine : null,
        Validators.required
      ),
    });
  }

  ngOnInit() {}

  getServiceLineName(serviceLine: ServiceLine) {
    switch (serviceLine) {
      case 'PRIMARY_CARE' as ServiceLine:
        return 'Primary Care';
      case 'PHYSICAL_THERAPY' as ServiceLine:
        return 'Physical Therapy';
      case 'MENTAL_HEALTH' as ServiceLine:
        return 'Mental Health Therapy';
      default:
        console.error('Invalid service line: ' + serviceLine);
    }
  }

  getServiceLineValue(serviceLine: ServiceLine) {
    switch (serviceLine) {
      case 'PRIMARY_CARE' as ServiceLine:
        return ServiceLine.PRIMARY_CARE;
      case 'PHYSICAL_THERAPY' as ServiceLine:
        return ServiceLine.PHYSICAL_THERAPY;
      case 'MENTAL_HEALTH' as ServiceLine:
        return ServiceLine.MENTAL_HEALTH;
      default:
        console.error('Invalid service line: ' + serviceLine);
    }
  }

  getServiceLineDescription(serviceLine: ServiceLine) {
    switch (serviceLine) {
      case 'PRIMARY_CARE' as ServiceLine:
        return 'In-home and virtual care for everyday health needs, plus prescriptions, labs, and x-rays.';
      case 'PHYSICAL_THERAPY' as ServiceLine:
        return 'Care for muscle and joint pain, pelvic health, and more from a licensed physical therapist.';
      case 'MENTAL_HEALTH' as ServiceLine:
        return 'Support for common mental health concerns from a licensed therapist.';
      default:
        console.error('Invalid service line for description: ' + serviceLine);
    }
  }
}
