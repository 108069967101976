export enum General {
  disoriented = 'Disoriented',
  alteredLevelOfConsciousness = 'Altered Level of Consciousness',
  mildDistress = 'Mild Distress',
  moderateDistress = 'Moderate Distress',
  severeDistress = 'Severe Distress',
  thin = 'Thin',
  obese = 'Obese',
  morbidlyObese = 'Morbidly Obese',
  appearsOlderThanStatedAge = 'Appears Older Than Stated Age',
  appearsUncomfortable = 'Appears Uncomfortable',
  disheveled = 'Disheveled',
  appearsMildlyIll = 'Appears Mildly Ill',
  appearsModeratelyIll = 'Appears Moderately Ill',
  appearsAcutelyIll = 'Appears Acutely Ill',
  cachecticAppearance = 'Cachectic Appearance',
  alteredMentalStatus = 'Altered Mental Status',
  patientTearful = 'Patient Tearful',
}

export enum Head {
  illShaped = 'Ill-Shaped',
  woundPresent = 'Wound Present',
  contusionToForehead = 'Contusion to Forehead',
  ecchymosisToCheek = 'Ecchymosis to Cheek',
  ecchymosisToForehead = 'Ecchymosis to Forehead',
  scarNotedToFace = 'Scar Noted to Face',
}

export enum Eyes {
  extraocularMovementsNotIntact = 'Extraocular Movements Not Intact',
  ictericSclerae = 'Icteric Sclerae',
  verticalOrRotaryNystagmus = 'Vertical or Rotary Nystagmus',
  horizontalNystagmus = 'Horizontal Nystagmus',
  purulentDrainagePresent = 'Purulent Drainage Present',
  injectedConjunctiva = 'Injected Conjunctiva',
  hordeolumPresent = 'Hordeolum Present',
  pupilsDilated = 'Pupils Dilated',
  pupilsConstricted = 'Pupils Constricted',
  fixedPupil = 'Fixed Pupil',
  anisocoria = 'Anisocoria',
  subconjunctivalHemorrhagePresent = 'Subconjunctival Hemorrhage Present',
  allergicShinersPresent = 'Allergic Shiners Present',
  thinWateryDrainagePresent = 'Thin, Watery Drainage Present',
}

export enum Ears {
  eacBilateralAbnormal = 'EAC Bilateral Abnormal',
  bilateralTMsWithoutLightReflexNoted = 'Bilateral TMs w/o Light Reflex',
  leftTMWithSerousEffusionNoted = 'Left TM w/ Serous Effusion Noted',
  rightTMWithSerousEffusionNoted = 'Right TM w/ Serous Effusion Noted',
  bilateralEacWithImpactedCereumen = 'Bilateral EAC with Impacted Cerumen',
  unableToFullyVisualizeTMsDueToImpactedCerumen = "Unable to Fully Visualize TM's Due to Impacted Cerumen",
  leftEacErythematousAndDrainingPurulentMaterial = 'Left EAC Erythematous & Draining Purulent Material',
  leftEacErythematous = 'Left EAC Erythematous',
  leftEacWithImpactedCerumen = 'Left EAC w/ Impacted Cerumen',
  leftEacWithDryAppearance = 'Left EAC w/ Dry Appearance',
  leftTMDull = 'Left TM Dull',
  rightTMDull = 'Right TM Dull',
  bilateralTMsDull = "Bilateral TM's Dull",
  bilateralEffusions = 'Bilateral Effusions',
  rightEacErythematousAndDrainingPurulentMaterial = 'Right EAC Erythematous & Draining Purulent Material',
  rightEacErythematous = 'Right EAC Erythematous',
  rightEachWithImpactedCerumen = 'Righ EAC w/ Impacted Cerumen',
  rightEacWithDryAppearance = 'Right EAC w/ Dry Appearance',
  leftTMDullAndErythematous = 'Left TM Dull & Erythematous',
  rightTMDullAndErythematous = 'Right TM Dull & Erythematous',
  lossOfLandMarksBilaterally = 'Loss of Landmarks Bilaterally',
  decreasedHearing = 'Decreased Hearing',
  lossOfHearing = 'Loss of Hearing',
  foreignBodyToRightEar = 'Foreign Body to Right Ear',
  foreignBodyToLeftEar = 'Foreign Body to Left Ear',
  foreignBodyToBothEars = 'Foreign Body to Both Ears',
  hardOfHearing = 'Hard of Hearing',
}

export enum Nose {
  naresObstructed = 'Nares Obstructed', // naresPatent is the correct value. NaresPresent ref. the db
  mildNasalCongestion = 'Mild Nasal Congestion',
  moderateNasalCongestion = 'Moderate Nasal Congestion',
  markedNasalCongestion = 'Marked Nasal Congestion',
  boggyTurbinates = 'Boggy Turbinates',
  rhinorrhea = 'Rhinorrhea',
  greenNasalDischarge = 'Green Nasal Discharge',
  yellowNasalDischarge = 'Yellow Nasal Discharge',
  clearRhinorrhea = 'Clear Rhinorrhea',
}

export enum MouthAndThroat {
  posteriorPharyngealDrainage = 'Posterior Pharyngeal Drainage',
  cobblestoningToPosteriorPharynx = 'Cobblestoning to Posterior Pharynx',
  pharyngealErythema = 'Pharyngeal Erythema',
  pharyngealExudate = 'Pharyngeal Exudate',
  pharyngealLesion = 'Pharyngeal Lesion',
  tonsillarErythema = 'Tonsillar Erythema',
  tonsillarExudate = 'Tonsillar Exudate',
  tonsillarEnlargement = 'Tonsillar Enlargement',
  phlebolithsNotedToTonsils = 'Phleboliths Noted to Tonsils',
  bifurcatedUvula = 'Bifurcated Uvula',
}

export enum Neck {
  rigid = 'Rigid',
  abnormalInspection = 'Abnormal Inspection',
  lymphadenopathy = 'Lymphadenopathy',
  tenderness = 'Tenderness',
  thyromegaly = 'Thyromegaly',
  thyroidNodulePalpatedToLeft = 'Thyroid Nodule Palpated to Left',
  thyroidNodulePalpatedToRight = 'Thyroid Nodule Palpated to Right',
  decreasedRangeOfMotion = 'Decreased Range of Motion',
  tendernessWithPalpationOfRightTrapeziusMuscle = 'Tenderness w/ Palpaton of Right Trapezius Muscle',
  tendernessWithPalpationOfLeftTrapeziusMuscle = 'Tenderness w/ Palpaton of Left Trapezius Muscle',
  lymphadenopathyNotedToAnteriorCervicalChain = 'Lymphadenopathy Noted to Anterior Cervical Chain',
  shottyLymphadenopathy = 'Shotty Lymphadenopathy',
  solitaryLymphNodePresent = 'Solitary Lymph Node Present',
  goiterPresent = 'Goiter Present',
  massToNeckPalpated = 'Mass to Neck Palpated',
  carotidBruitNotedToTheRight = 'Carotid Bruit Noted to the Right',
  carotidBruitNotedToTheLeft = 'Carotid Bruit Noted to the Left',
  tracheaDeviationPresent = 'Trachea Deviation Present',
  jvdPresentBilaterally = 'JVD Present Bilaterally',
  meningismus = 'Meningismus',
}

export enum Cardiovascular {
  rrr = 'Irregular Rate & Rhythm',
  jvd = 'JVD',
  carotidBruits = 'Carotid Bruits',
  murmurs = 'Murmurs',
  rubsOrGallop = 'Rubs or Gallop',
  S1S2NotPresent = 'S1, S2 Not Present',
  S3NoS4 = 'S3, no S4',
  tachycardic = 'Tachycardic',
  bradycardic = 'Bradycardic',
  frequentExtrasystoles = 'Frequent Extrasystoles',
  irregularlyIrregularRhythm = 'Irregularly Irregular Rhythm',
  rapidVentricularRateNoted = 'Rapid Ventricular Rate Noted',
  carotidBruitPresentRight = 'Carotid Bruit Present Right',
  carotidBruitPresentLeft = 'Carotid Bruit Present Left',
  systolicEjectionMurmurPresentIIVI = 'Systolic Ejection Murmur Present II/VI',
  systolicEjectionMurmurPresentIIIVI = 'Systolic Ejection Murmur Present III/VI',
  rubPresent = 'Rub Present',
  gallop = 'Gallop',
  S3Present = 'S3 Present',
  S4Present = 'S4 Present',
  openingSnap = 'Opening Snap',
  splitS2Noted = 'Split S2 Noted',
}

export enum Respiratory {
  abnormalBreathSounds = 'Abnormal Breath Sounds',
  wheezesRalesRhonchi = 'Wheezes/Rales/Rhonchi',
  lungExcursionsAbnormal = 'Lung Excursions Abnormal',
  paroxysmalCough = 'Paroxysmal Cough',
  coarseCough = 'Coarse Cough',
  endExpiratoryWHeezing = 'End Expiratory Wheezing',
  inspiratoryStridorPresent = 'Inspiratory Stridor Present',
  respiratoryDistress = 'Respiratory Distress',
  increasedWorkOfBreathing = 'Increased Work of Breathing',
  accessoryMuscleUse = 'Accessory Muscle Use',
  audibleWheezing = 'Audible Wheezing',
  wheezingInAllLungFieldsOnAuscultation = 'Wheezing in All Lung Fields on Auscultation',
  rhonchiInAllLungFieldsOnAuscultation = 'Wheezing in All Lung Fields on Auscultation',
  ralesInAllLungFieldsOnAuscultation = 'Rales in All Lung Fields on Auscultation',
  decreasedBreathSounds = 'Decreased Breath Sounds',
  absentBreathSounds = 'Absent Breath Sounds',
}

export enum Gastrointestinal {
  firm = 'Firm',
  tender = 'Tender',
  distended = 'Distended',
  surgicalScars = 'Surgical Scars',
  traumaOnInspection = 'Trauma on Inspection',
  abnormalBowelSounds = 'Abnormal Bowel Sounds',
  massesNotedOnLightOrDeepPalpitation = 'Masses Noted on Light or Deep Palpitation',
  cvaTenderness = 'CVA Tenderness',
  organomegalyNotedOnPalpation = 'Organomegaly Noted on Palpation',
  bowelSoundsHypoactive = 'Bowel Sounds Hypoactive',
  bowelSoundsHyperActive = 'Bowel Sounds Hyperactive',
  mildEpigastricTendernessOnPalpation = 'Mild Epigastric Tenderness on Palpation',
  severeEpigastricTendernessOnPalpation = 'Severe Epigastric Tenderness on Palpation',
  surgicalScarPresent = 'Surgical Scar Present',
  massPalpatedRuq = 'Mass Palpated RUQ',
  massPalpatedRlq = 'Mass Palpated RLQ',
  massPalpatedLuq = 'Mass Palpated LUQ',
  massPalpatedLlq = 'Mass Palpated LLQ',
  tendernessRuq = 'Tenderness RUQ',
  tendernessRlq = 'Tenderness RLQ',
  tendernessLuq = 'Tenderness LUQ',
  tendernessLlq = 'Tenderness LLQ',
  splenomegaly = 'Splenomegaly',
  hepatomegaly = 'Hepatomegaly',
  abdominalRigidity = 'Abdominal Rigidity',
  reboundTenderness = 'Rebound Tenderness',
  voluntaryGuarding = 'Voluntary Guarding',
  involuntaryGuarding = 'Involuntary Guarding',
}

export enum GenitourinaryFemale {
  abnormalExternalGenitalia = 'Abnormal External Genitalia',
  abnormalUrethralMeatus = 'Abnormal Urethral Meatus',
  abnormalUterusSizeAndPosition = 'Abnormal Uterus Size and Position',
  adnexaeTenderWithMasses = 'Adnexae Tender with Masses',
  abnormalVaginalDischarge = 'Abnormal Vaginal Discharge',
  lesions = 'Lesions',
  abnormalCervicalOs = 'Abnormal Cervical Os',
}

export enum GenitourinaryMale {
  abnormalExternalGenitalia = 'Abnormal External Genitalia',
  abnormalUrethralMeatus = 'Abnormal Urethral Meatus',
  discharge = 'Discharge',
  redness = 'Redness',
  testiclesNonTender = 'Testicles Non-Tender',
  testiclesAbnormalWithMasses = 'Testicles Abnormal w/Masses',
}

export enum Musculoskeletal {
  deformitiesNoted = 'Deformities Noted',
  abnormalSpineAlignment = 'Abnormal Spine Alignment',
  abnormalBulkTone = 'Abnormal Bulk/Tone',
  abnormalRangeOfMotion = 'Abnormal Range of Motion',
  limitedRom = 'Limited ROM',
  atrophy = 'Atrophy',
  scoliosis = 'Scoliosis',
  jointDeformities = 'Joint Deformities',
  jointTenderness = 'Joint Tenderness',
  bonyDeformities = 'Bony Deformities',
  paraspinalTenderness = 'Paraspinal Tenderness',
  chestWallIrregularity = 'Chest Wall Irregularity',
  tendernessToPalpationOfChestWall = 'Tenderness to Palpation of Chest Wall',
}

export enum Extremities {
  varicoseVeinsPresent = 'Varicose Veins Present',
  abnormalMovements = 'Abnormal Movements',
  tremor = 'Tremor',
  rigidity = 'Rigidity',
  abnormalAlignment = 'Abnormal Alignment',
  abnormalGait = 'Abnormal Gait',
  abnormalRom = 'Abnormal ROM',
  abnormalPedalPulsesBilaterally = 'Abnormal Pedal Pulses Bilaterally',
  onePedalPulsesBilaterally = '1+ Pedal Pulses Bilaterally',
  ecchymosisNotedToLateralAnkle = 'Ecchymosis Noted to Lateral Ankle',
  ecchymosisNotedToMedialAnkle = 'Ecchymosis Noted to Medial Ankle',
  rightCalfTenderness = 'Right Calf Tenderness',
  leftCalfTenderness = 'Left Calf Tenderness',
  abnormalDistalTibiaPulses = 'Abnormal Distal Tibia Pulses',
  abnormalDorsalisPedisPulses = 'Abnormal Dorsalis Pedis Pulses',
  abnormalPosteriorPopliteal = 'Abnormal Posterior Popliteal',
  decreasedPeripheralPulsesToTheRight = 'Decreased Peripheral Pulses to the Right',
  decreasedPeripheralPulsesToTheLeft = 'Decreased Peripheral Pulses to the Left',
  pedalEdemaPresent = 'Pedal Edema Present',
  edemaToRightHand = 'Edema to Right Hand',
  edemaToLeftHand = 'Edema to Left Hand',
  edemaToRightLeg = 'Edema to Right Leg',
  edemaToLeftLeg = 'Edema to Left Leg',
  edemaToRightAnkle = 'Edema to Right Ankle',
  edemaToLeftAnkle = 'Edema to Left Ankle',
  edemaToBothAnkles = 'Edema to Both Ankles',
  edemaToBothLegs = 'Edema to Both Legs',
  edemaToBothHands = 'Edema to Both Hands',
}

export enum Integumentary {
  cool = 'Cool',
  diaphoretic = 'Diaphoretic',
  suspiciousLesions = 'Suspicious Lesions',
  hives = 'Hives',
  skinLesionPresent = 'Skin Lesion Present',
  skinRashPresent = 'Skin Rash Present',
  scarNotedRightLeg = 'Scar Noted to Right Leg',
  scarNotedLeftLeg = 'Scar Noted to Left Leg',
  scarNotedToRightKnee = 'Scar Noted to Right Knee',
  scarNotedToLeftKnee = 'Scar Noted to Left Knee',
  scarNotedToRightAnkle = 'Scar Noted to Right Ankle',
  scarNotedToLeftAnkle = 'Scar Noted to Left Ankle',
  scarNotedToRightHip = 'Scar Noted to Right Hip',
  scarNotedToLeftHip = 'Scar Noted to Left Hip',
}

export enum Breast {
  masses = 'Masses',
  lymphadenopathy = 'Lymphadenopathy',
  nippleDischarge = 'Nipple Discharge',
  tenderness = 'Tenderness',
}

export enum Neurological {
  abnormalMuscleStrength = 'Abnormal Muscle Strength',
  cranialNervesNotIntact = 'Cranial Nerves Not Intact',
  rapidAlternatingMovementsNotIntact = 'Rapid Alternating Movements Not Intact',
  pointToPointMovementsNotIntact = 'Point-to-Point Movements Not Intact',
  painSensationNotIntact = 'Pain Sensation Not Intact',
  temperatureSensationNotIntact = 'Temperature Sensation Not Intact',
  lightTouchNotIntact = 'Light Touch Not Intact',
  vibrationSensationNotIntact = 'Vibration Sensation Not Intact',
  discriminationNotIntact = 'Discrimination Not Intact',
  dtrsAbsentRt = 'DTR’s Absent Rt',
  dtrsAbsentLt = "DTR's Absent Lt",
  dtrs1Rt = "DTR's 1+ Rt",
  dtrs1Lt = "DTR's 1+ Lt",
  abnormalPatellar = 'Abnormal Patellar',
  abnormalAchilles = 'Abnormal Achilles',
  abnormalBrachial = 'Abnormal Brachial',
  abnormalRadial = 'Abnormal Radial',
  confused = 'Confused',
  eomPalsy = 'EOM Palsy',
  facialAsymmetry = 'Facial Asymmetry',
  sensoryDeficit = 'Sensory Deficit',
  motorDeficit = 'Motor Deficit',
  unsteadyGait = 'Unsteady Gait',
  ataxicGait = 'Ataxic Gait',
  abnormalGripStrength = 'Abnormal Grip Strength',
}

export enum Psychiatric {
  uncooperative = 'Uncooperative',
  abnormalMood = 'Abnormal Mood',
  affectInappropriateToMood = 'Affect Inappropriate to Mood',
  abnormalCognition = 'Abnormal Cognition',
  abnormalThoughtPattern = 'Abnormal Thought Pattern',
  abnormalInsightAndJudgement = 'Abnormal Insight and Judgement',
  hallucinations = 'Hallucinations',
  suicidalIdeation = 'Suicidal Ideation',
  homicidalIdeation = 'Homicidal Ideation',
}
