import React, { PropsWithChildren } from 'react';
import { Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { createRoot, Root } from 'react-dom/client';

@Directive({
  selector: '[reactComponent]',
})
export class ReactComponentDirective<Props extends PropsWithChildren, Comp extends (props: Props) => React.JSX.Element>
  implements OnChanges, OnDestroy
{
  @Input() reactComponent: Comp;
  @Input() props: Props;

  private readonly root: Root;

  constructor(private host: ElementRef) {
    if (!this.root) {
      this.root = createRoot(this.host.nativeElement);
    }
  }

  ngOnChanges() {
    this.root.render(this.reactComponent(this.props));
  }

  ngOnDestroy() {
    this.root.unmount();
  }
}
