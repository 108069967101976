import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { retryBackoff } from 'backoff-rxjs';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method === 'GET') {
      return next.handle(req).pipe(
        retryBackoff({
          initialInterval: 1000,
          maxRetries: 4,
          shouldRetry: (error) => {
            // Do not retry certain events like video errors or polling requests.
            if (this.isVideoError(error) || this.isPollingRequest(req)) {
              return false;
            }

            return error.status >= 500 || error.status === 429;
          },
        }),
      );
    }
    if (req.method === 'POST' || req.method === 'PUT') {
      return next.handle(req).pipe(
        retryBackoff({
          initialInterval: 500,
          maxRetries: 8,
          shouldRetry: (error) => {
            return error.status === 429;
          },
        }),
      );
    }

    return next.handle(req);
  }

  private isVideoError(error: any): boolean {
    return (
      error &&
      error.error &&
      error.error.message &&
      (error.error.message as string).startsWith('Video Error:')
    );
  }

  private isPollingRequest(req: HttpRequest<any>): boolean {
    return req && req.url && req.url.includes('/v1/messages/me');
  }
}
