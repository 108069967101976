import React from 'react';
import { Card, CardActionArea, CardMedia } from '@mui/material';

type ImageViewProps = {
  imageTarget: string;
  maxHeight?: string;
  maxWidth?: string;
  centered?: boolean;
  href?: string;
  onClickHandler?: () => void;
  altText?: string;
};

export function ImageView({
  imageTarget,
  maxHeight = '',
  maxWidth = '',
  centered = false,
  href = '',
  onClickHandler = undefined,
  altText = '',
}: ImageViewProps): React.JSX.Element {
  const cardMediaProps: any = {};

  if (maxHeight) {
    cardMediaProps.maxHeight = maxHeight;
  }

  if (maxWidth) {
    cardMediaProps.maxWidth = maxWidth;
  }

  if (centered) {
    cardMediaProps.margin = 'auto';
  } else {
    cardMediaProps.margin = 'inherit';
  }

  return (
    <Card sx={{ border: 'none', borderRadius: '0', boxShadow: 'none' }}>
      <CardActionArea
        href={href}
        onClick={onClickHandler}
        sx={{ cursor: href || onClickHandler ? 'pointer' : 'default' }}
      >
        <CardMedia sx={cardMediaProps} component='img' alt={altText} image={imageTarget} onClick={onClickHandler} />
      </CardActionArea>
    </Card>
  );
}

export default ImageView;
