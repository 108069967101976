import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { concatMap, filter, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Document, Patient } from '@models';
import { DocumentService } from '../../core/services/document.service';
import { PatientService } from '../../core/services/patient.service';
import { AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss'],
})
export class DocumentsPageComponent implements OnInit {
  @ViewChild('file', { static: true }) file;
  public documents: Document[];
  public patients: Observable<Patient[]>;
  public activePatient: Observable<Patient>;
  public refreshTrigger = new BehaviorSubject(null);
  public refreshing = false;
  private destroyed = new Subject();
  private patientId: number;

  public page = 0;
  public pageSize = 5;
  public pageCount = 0;

  constructor(
    public documentService: DocumentService,
    private menu: MenuController,
    public alertService: AlertService,
    public router: Router,
    public patientService: PatientService
  ) {}

  ngOnInit() {
    this.patients = this.patientService.patients;
    this.activePatient = this.patientService.activePatient;

    const doc = combineLatest([this.refreshTrigger, this.patientService.activePatient.pipe(filter((v) => !!v))])
      .pipe(
        map(([_, activePatient]) => activePatient),
        tap(() => (this.refreshing = true)),
        switchMap((activePatient) => {
          if (!this.activePatient) {
            return of([]);
          }
          this.patientId = activePatient.id;
          return this.documentService.getDocuments(this.patientId);
        }),
        tap(() => (this.refreshing = false)),
        takeUntil(this.destroyed)
      )
      .subscribe((documents) => {
        this.documents = documents;
        this.pageCount = Math.floor(documents.length / this.pageSize);
      });
  }

  async onFileUploadComplete() {
    this.alertService.hideLoading();
    this.alertService.success('Successfully saved your document');
    this.refreshTrigger.next(null);
  }

  async onFilesAdded() {
    try {
      const files = this.file.nativeElement.files;
      if (files.length === 0) {
        return;
      }

      this.alertService.loading();
      const fileToUpload = files[0];

      if (fileToUpload.size > 20 * 1024 * 1024) {
        this.file.nativeElement.value = '';
        this.alertService.error(`${fileToUpload.name} is too large to be uploaded. The maximum file size is 20mb.`);
        this.alertService.hideLoading();
        return;
      }
      const urlResponse = await this.documentService
        .getSignedUrl(
          fileToUpload.type,
          fileToUpload.name,
          this.patientService.currentActivePatient.id.toString(),
          fileToUpload.size
        )
        .toPromise();

      await this.documentService.uploadFile(fileToUpload, urlResponse.signedUrl);

      this.confirmFileUploaded(urlResponse.fileName, this.patientId);
    } catch (error) {
      this.alertService.error('File upload failed');
      this.alertService.hideLoading();
    }
  }

  public pageChange(page: number) {
    this.page = page;
  }

  addFiles() {
    this.file.nativeElement.value = '';
    this.file.nativeElement.click();
  }

  getFileType(fileType) {
    const split = fileType.split(/\/(?=[^\/]+$)/);
    const [content, type] = split;
    return { content, type };
  }

  addDocument() {
    this.router.navigate(['add-document']);
  }

  presentMenu() {
    this.menu.open();
  }

  refresh() {
    this.refreshTrigger.next(null);
  }

  // Checks up to 50 times to see if a file with the given name has
  // been successfully scanned and uploaded today for this patient user.
  // Stops when receives confirmation or max number of tries.
  // Provides feedback to user on failure, or calls success function.
  public confirmFileUploaded(filename: string, patientId: number) {
    let attemptCount = 0;
    const timerRequest = timer(500, 500)
      .pipe(
        concatMap(() => this.documentService.documentSuccessfullyUploaded(filename, patientId)),
        retry(),
        takeUntil(this.destroyed)
      )
      .subscribe((result) => {
        attemptCount++;

        if (result === true) {
          this.onFileUploadComplete();
          this.alertService.hideLoading();
          timerRequest.unsubscribe();
        }

        if (attemptCount === 50) {
          this.alertService.error('There was a problem saving your document.');
          this.alertService.hideLoading();
          timerRequest.unsubscribe();
        }
      });
  }
}
