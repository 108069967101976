<mat-card appearance="outlined">
  <mat-card-header>
    <h3 class="ph-no-capture">{{ patient.firstName }} {{ patient.lastName }}</h3>
  </mat-card-header>
  <mat-card-subtitle>
    <p class="ph-no-capture">DOB: {{ patient.dateOfBirth | date : 'longDate' }}</p>
  </mat-card-subtitle>
  <mat-card-actions>
    <button mat-raised-button (click)="presentEditPatientModal()" color="accent">
      EDIT
      <span class="material-icons">arrow_forward</span>
    </button>
  </mat-card-actions>
</mat-card>
