<ion-list [formGroup]="form">
  <ion-item>
    <ion-label position="floating" color="tertiary">Patient(s)</ion-label>
    <ion-select
      *ngIf="isMultiPatientCapable"
      multiple
      formControlName="patientIds"
      [placeholder]="placeholder"
      [interfaceOptions]="utilityService.interfaceOptions"
    >
      <ion-select-option
        *ngFor="let patient of patients"
        [value]="patient.id"
        [disabled]="!this.eligibilityMap[patient.id]"
      >
        <span class="ph-no-capture">
          {{ patient.name }}
        </span>
      </ion-select-option>
    </ion-select>
    <ion-select
      *ngIf="!isMultiPatientCapable"
      formControlName="patientIds"
      [placeholder]="placeholder"
      [interfaceOptions]="utilityService.interfaceOptions"
    >
      <ion-select-option
        *ngFor="let patient of patients"
        [value]="patient.id"
        [disabled]="!this.eligibilityMap[patient.id].eligible"
      >
        <div style="display: flex; flex-direction: column">
          <div class="ph-no-capture">{{ patient.name }}{{ !this.eligibilityMap[patient.id].eligible ? ':' : '' }}</div>
          <div *ngIf="!this.eligibilityMap[patient.id].eligible" class="ph-no-capture">
            {{ this.eligibilityMap[patient.id].reason }}
          </div>
        </div>
      </ion-select-option>
    </ion-select>
    <ion-note *ngIf="hint">{{ hint }}</ion-note>
  </ion-item>
</ion-list>
