<div fxLayout="column" fxLayoutGap="24px" [formGroup]="form">
  <ion-label color="tertiary">
    Choose an appointment type and date, then select a time for your appointment.
  </ion-label>

  <ion-list>
    <ion-item [disabled]="true">
      <ion-label position="floating" color="tertiary">Appointment Type</ion-label>
      <ion-select
        formControlName="appointmentType"
        [interfaceOptions]="utilityService.interfaceOptions"
        [placeholder]="'Select an appointment type'"
        required
        [disabled]="true"
      >
        <ion-select-option *ngFor="let type of appointmentTypesEnum | enum" [value]="type.key">
          {{ type.value }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="floating" color="tertiary">Provider</ion-label>
      <ion-select
        formControlName="providerId"
        [interfaceOptions]="utilityService.interfaceOptions"
        [placeholder]="'Select a specific provider (optional)'"
        [disabled]="(availableProviders | async).length === 0"
        (ionChange)="onCalenderSelectionChange($event)"
      >
        <ion-select-option [value]="null">Any available provider</ion-select-option>
        <ion-select-option *ngFor="let provider of availableProviders | async" [value]="provider.providerId">
          {{ provider.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <div class="datepicker">
      <mat-form-field>
        <mat-label> Date </mat-label>
        <input
          (dateChange)="onDateChange($event)"
          [matDatepicker]="datePicker"
          formControlName="appointmentDate"
          matInput
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker aria-label="Appointment Date"></mat-datepicker>
      </mat-form-field>
    </div>
  </ion-list>

  <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="flex-start center">
    <div fxFill>
      <ion-label color="tertiary">Time Slot</ion-label>
    </div>

    <ion-spinner *ngIf="loadingTimesSlotsForSelection" color="primary"></ion-spinner>

    <div class="slot-container" [hidden]="loadingTimesSlotsForSelection">
      <ion-text *ngIf="showNoAppointSlotsAvailableMessage" color="medium">
        No Time Slots Available For Your Selection
      </ion-text>
      <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <ion-chip
          class="time-chip"
          [color]="
            form.controls['appointmentTimeSlot'].value && slot.time === form.controls['appointmentTimeSlot'].value.time
              ? 'primary'
              : 'secondary'
          "
          *ngFor="let slot of availableTimeSlots | async"
          (click)="form.controls['appointmentTimeSlot'].setValue(slot)"
        >
          {{ slot.time | date : 'shortTime' }}
        </ion-chip>
      </div>
    </div>
  </div>
</div>
