<!--<div *ngIf="!(isActivePatient | async)" class="loading-container">-->
<!--  <ion-spinner></ion-spinner>-->
<!--</div>-->

<!-- It is unclear why this element is needed for ionic to display anything, but it can't be removed without other work. -->
<ion-tabs [hidden]="true">
  <!--  <ion-tab-bar class="tab-bar" color="primary" slot="bottom">-->
  <!--    <ion-tab-button tab="appointments">-->
  <!--      <ion-icon name="calendar"></ion-icon>-->
  <!--      <ion-label>Appointments</ion-label>-->
  <!--    </ion-tab-button>-->

  <!--    <ion-tab-button tab="chat">-->
  <!--      <ion-icon name="chatbox"></ion-icon>-->
  <!--      <ion-badge *ngIf="chatService.unreadCount | async" color="danger">{{-->
  <!--        chatService.unreadCount | async-->
  <!--      }}</ion-badge>-->
  <!--      <ion-label>Chat</ion-label>-->
  <!--    </ion-tab-button>-->

  <!--    <ion-tab-button tab="patients">-->
  <!--      <ion-icon name="people"></ion-icon>-->
  <!--      <ion-label>Patients</ion-label>-->
  <!--    </ion-tab-button>-->

  <!--    &lt;!&ndash; <ion-tab-button tab="video">-->
  <!--      <ion-icon name="videocam"></ion-icon>-->
  <!--      <ion-label>Video</ion-label>-->
  <!--    </ion-tab-button> &ndash;&gt;-->

  <!--    <ion-tab-button tab="documents">-->
  <!--      <ion-icon name="document"></ion-icon>-->
  <!--      <ion-label>Documents</ion-label>-->
  <!--    </ion-tab-button>-->
  <!--  </ion-tab-bar>-->
</ion-tabs>
