<ion-button
  [shape]="shape"
  [color]="color"
  [disabled]="disabled || submitting"
  (click)="onSubmit()"
  fxLayoutAlign="center center"
>
  <span *ngIf="!submitting">{{ text }}</span>
  <ion-spinner *ngIf="submitting"></ion-spinner>
</ion-button>
