export const standardOptions = {
  yesNoAnswer: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  yesNoNaAnswer: [
    {
      label: 'Yes',
      value: 'yes',
    },
    {
      label: 'No',
      value: 'no',
    },
    {
      label: 'N/A',
      value: 'na',
    },
  ],
  positiveNegativeAnswer: [
    {
      label: 'Positive',
      value: true,
    },
    {
      label: 'Negative',
      value: false,
    },
  ],
};
