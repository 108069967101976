export enum AppointmentReasonType {
  acute = 'Acute',
  chronic = 'Chronic',
}

export enum HomeVisitRequestStatus {
  open,
  filled,
  rejected,
}

export const appointmentFormats = ['chat', 'video', 'inPerson'] as const;

export type AppointmentFormatType = (typeof appointmentFormats)[number];

export enum AppointmentCategory {
  home = 'Home',
  virtual = 'Virtual',
  mctHome = 'MctHome',
}

export enum ServiceLine {
  PHYSICAL_THERAPY = 'Physical Therapy',
  PRIMARY_CARE = 'Primary Care',
  MENTAL_HEALTH = 'Mental Health Therapy',
}

export enum IntakeFormFriendlyName {
  PRIMARY_CARE = 'PRIMARY_CARE',
  PHYSICAL_THERAPY = 'PHYSICAL_THERAPY',
  HEALTH_HISTORY = 'HEALTH_HISTORY',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
}
