import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Color } from './colors';

export const enum FontFamily {
  MessinaSans = 'MessinaSans',
  Steradian = 'Steradian',
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bold: React.CSSProperties;
    title: React.CSSProperties;
    subtitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold?: React.CSSProperties;
    title?: React.CSSProperties;
    subtitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    subtitle: true;
    bold: true;
  }
}

export const typographyThemeOptions: TypographyOptions = {
  // App default is MessinaSans font, with sans-serif as fallback.
  fontFamily: `${FontFamily.MessinaSans},sans-serif`,
  fontWeightRegular: 500,
  h1: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 400,
    fontSize: 38,
    lineHeight: '116%',
  },
  h2: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 400,
    fontSize: 32,
    lineHeight: '116%',
  },
  h3: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '116%',
  },
  h4: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '120%',
  },
  h5: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '120%',
  },
  h6: {
    fontFamily: `${FontFamily.Steradian}`,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '120%',
  },
  subtitle1: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '140%',
  },
  subtitle2: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '140%',
  },
  body1: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '140%',
  },
  body2: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '140%',
  },
  caption: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '140%',
    color: Color.Slate200,
  },
  overline: {
    fontFamily: `${FontFamily.MessinaSans}`,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '140%',
  },
};
