<ion-list [formGroup]="form">
  <ion-item>
    <ion-label position="floating" color="tertiary">Appointment Type</ion-label>
    <ion-select
      formControlName="appointmentTypeId"
      [(ngModel)]="ngModelSelectedAppointmentTypeId"
      [interfaceOptions]="utilityService.interfaceOptions"
      [placeholder]="'Select an appointment type'"
      required
      (ionChange)="onAppointmentTypeChange()"
    >
      <ion-select-option *ngFor="let type of availableAppointmentTypes | async" [value]="type.id">
        {{ getAppointmentTypeDisplayText(type) }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating" color="tertiary">Provider</ion-label>
    <ion-select
      formControlName="providerId"
      [interfaceOptions]="utilityService.interfaceOptions"
      [placeholder]="
        (availableProviders | async).length === 0 && form.controls['appointmentTypeId'].value
          ? 'No providers available for that appointment type'
          : 'Select a specific provider (optional)'
      "
      [disabled]="(availableProviders | async).length === 0"
      (ionChange)="onSelectedProviderChange($event)"
    >
      <ion-select-option [value]="null">Any available provider</ion-select-option>
      <ion-select-option *ngFor="let provider of availableProviders | async" [value]="provider.providerId">
        {{ provider.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <div class="datepicker">
    <mat-form-field>
      <mat-label> Date </mat-label>
      <input
        (dateChange)="onDateChange($event)"
        [matDatepicker]="datePicker"
        formControlName="appointmentDate"
        matInput
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker aria-label="Appointment Date"></mat-datepicker>
    </mat-form-field>
  </div>

  <ion-item>
    <ion-label color="tertiary" position="stacked">Time Slot</ion-label>
    <div class="loading-container" *ngIf="loadingTimesSlotsForSelection">
      <ion-spinner color="primary"></ion-spinner>
    </div>
    <div class="slot-container" [hidden]="loadingTimesSlotsForSelection">
      <ion-text *ngIf="showNoAppointSlotsAvailableMessage && !displayRetry" color="medium">
        No Time Slots Available For Your Selection
      </ion-text>
      <div *ngIf="displayRetry" (click)="handleRetryRequest()">
        <a href="javascript:void(0);" style="color: red">Retry Availability Request</a>
      </div>
      <ion-chip
        [color]="
          form.controls['appointmentTimeSlot'].value && slot.time === form.controls['appointmentTimeSlot'].value.time
            ? 'success'
            : 'dark'
        "
        *ngFor="let slot of availableTimeSlots | async"
        (click)="form.controls['appointmentTimeSlot'].setValue(slot)"
      >
        {{ slot.time | date : 'shortTime' }}
      </ion-chip>
    </div>
    <ion-note color="tertiary">Choose an appointment type and date, then select a time for your appointment.</ion-note>
  </ion-item>

  <ion-item>
    <ion-label position="floating" color="tertiary">Notes</ion-label>
    <ion-textarea
      clearInput
      formControlName="note"
      rows="5"
      autocomplete="“true”"
      spellcheck="“true”"
      placeholder="Use this space to provide any other relevant information"
    >
    </ion-textarea>
  </ion-item>
</ion-list>
