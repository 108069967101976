import React from 'react';
import Sidebar, { SidebarProps } from './Sidebar';
import ThemedComponent from '../../../react/theme/ThemedComponent';

export function SidebarWrapper({ router }: SidebarProps): React.JSX.Element {
  return (
    <ThemedComponent>
      <Sidebar router={router} />
    </ThemedComponent>
  );
}

export default SidebarWrapper;
