import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../core/services/patient.service';
import { ModalController } from '@ionic/angular';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { Patient } from '@models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-patients',
  templateUrl: './patients-page.component.html',
  styleUrls: ['./patients-page.component.scss'],
})
export class PatientsPageComponent implements OnInit {
  public patients: Observable<Patient[]>;
  public activePatient: Observable<Patient>;

  constructor(private modalController: ModalController, private patientService: PatientService) {}

  ngOnInit() {
    this.patients = this.patientService.patients;
    this.activePatient = this.patientService.activePatient;
  }

  async presentAddPatientModal() {
    const modal = await this.modalController.create({
      component: AddPatientComponent,
    });
    modal.onDidDismiss().then((result) => {
      // Trigger a refresh of the patient list only on successful patient creation
      if (result.data?.refresh) {
        this.patientService.refreshPatientList();
      }
    });
    return await modal.present();
  }
}
