import { NgModule } from '@angular/core';
import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LinkyModule } from 'ngx-linky';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InViewportModule } from 'ng-in-viewport';

import { CoreModule } from '../core/core.module';
import { SignUpFormComponent } from './forms/sign-up-form/sign-up-form.component';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { DocumentFormComponent } from './forms/document-form/document-form.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { PatientFormComponent } from './forms/patient-form/patient-form.component';
import { AppointmentConfirmationFormComponent } from './forms/appointment-confirmation-form/appointment-confirmation-form.component';
import { ProgressButtonComponent } from './progress-button/progress-button.component';
import { IntakeFormComponent } from './forms/intake-form/intake-form.component';
import { InvalidStateFormComponent } from './forms/invalid-state-form/invalid-state-form.component';
import { SelectStateFormComponent } from './forms/select-state-form/select-state-form.component';
import { SelectServiceLineFormComponent } from './forms/select-service-line-form/select-service-line-form.component';
import { SelectPatientFormComponent } from './forms/select-patient-form/select-patient-form.component';
import { AppointmentSchedulingFormComponent } from './forms/appointment-scheduling-form/appointment-scheduling-form.component';
import { ChangeAppointmentTypeFormComponent } from './forms/change-appointment-type-form/change-appointment-type-form.component';
import { ApplyMonospaceToZeroDirective } from './directives/apply-monospace-to-zero.directive';
import { LongPressDirective } from './directives/long-press.directive';
import { PageNavComponent } from './page-nav/page-nav.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { FormlyOpinionQuestionWrapperComponent } from './forms/formly-opinion-question-wrapper/formly-opinion-question-wrapper.component';
import { ReactComponentDirective } from '../react/react-component.directive';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    AddressFormComponent,
    SignUpFormComponent,
    TitleBarComponent,
    SidebarComponent,
    PatientFormComponent,
    DocumentFormComponent,
    AppointmentConfirmationFormComponent,
    ProgressButtonComponent,
    IntakeFormComponent,
    InvalidStateFormComponent,
    SelectStateFormComponent,
    SelectServiceLineFormComponent,
    SelectPatientFormComponent,
    AppointmentSchedulingFormComponent,
    ChangeAppointmentTypeFormComponent,
    ApplyMonospaceToZeroDirective,
    LongPressDirective,
    ReactComponentDirective,
    PageNavComponent,
    FormlyOpinionQuestionWrapperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule,
    CoreModule,
    RouterModule,
    LinkyModule,
    FormlyModule.forRoot({
      wrappers: [{ name: 'opinion-wrapper', component: FormlyOpinionQuestionWrapperComponent }],
    }),
    MatNativeDateModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FlexLayoutModule,
    InViewportModule,
    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatListModule,
    NgForOf,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule,
    AddressFormComponent,
    SignUpFormComponent,
    TitleBarComponent,
    SidebarComponent,
    PatientFormComponent,
    DocumentFormComponent,
    AppointmentConfirmationFormComponent,
    ProgressButtonComponent,
    LinkyModule,
    InViewportModule,
    IntakeFormComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    InvalidStateFormComponent,
    SelectStateFormComponent,
    SelectServiceLineFormComponent,
    SelectPatientFormComponent,
    AppointmentSchedulingFormComponent,
    ChangeAppointmentTypeFormComponent,
    ApplyMonospaceToZeroDirective,
    LongPressDirective,
    ReactComponentDirective,
    PageNavComponent,
  ],
})
export class SharedModule {}
