import { standardOptions } from '../enums/standard-options';
import * as InHouseLabResults from '../enums/in-house-lab-results';
import * as PhysicalExam from '../enums/physical-exam';
import * as ReviewOfSystems from '../enums/review-of-systems';
import * as Objective from '../enums/patient-chart-objective';
import { ChartInHouseLabFormGroup } from '../dto/chart-in-house-lab';
import { PhysicalExamFormGroup } from '../dto/chart-physical-exam';
import { ReviewOfSystemsGroup } from '../dto/chart-review-of-systems';
import { ChartObjectiveGroup } from '../dto/chart-objective';
import * as MentalStatus from '../enums/patient-chart-mental-status';

// Mirrors the shape of a standardOption
interface Option {
  label: string;
  value: boolean;
}

export type FormSectionConfiguration<T> = {
  heading: string;
  formGroupName: keyof T;
  formControlName: string;
  options: Record<string, string>;
  deferrable?: boolean;
};

export type FieldDefinition =
  | {
      header: string;
      toggleOptions: Option[];
      formControlName: string;
    }
  | {
      header: string;
      optionsEnum: Record<string, string>;
      formControlName: string;
    };

/**
 * Generates a section of a form tied to a FormControl that uses one of the standardOptions from MultiToggleOptions
 * @param header The text in the label above the section
 * @param toggleOptions One of the standardOptions in the MultiToggleOptions
 * @param formControlName The name of the FormControl this section is connected to
 */
function optionsSection(header: string, toggleOptions: Option[], formControlName: string) {
  return { header, toggleOptions, formControlName };
}
/**
 * Generates a section of a form tied to a FormControl that uses any enum and provides it to the optionsEnum of the multiToggleComponent
 * @param header The text in the label above the section
 * @param optionsEnum Any enum to be used as an array of options to select from
 * @param formControlName The name of the FormControl this section is connected to
 */
function enumKeySection(header: string, optionsEnum: Record<string, string>, formControlName: string) {
  return { header, optionsEnum, formControlName };
}

const strepFields = [
  optionsSection('POC Rapid Strep Completed', standardOptions.yesNoAnswer, 'pocRapidStrepCompleted'),
  optionsSection('Rapid Strep Test', standardOptions.positiveNegativeAnswer, 'rapidStrepTest'),
  optionsSection('Strep Culture Sent', standardOptions.yesNoAnswer, 'strepCultureSent'),
];
const urineFields = [
  optionsSection('POC Urinalysis Completed', standardOptions.yesNoAnswer, 'pocUrinalysisCompleted'),
  optionsSection('Urine Culture Sent', standardOptions.yesNoAnswer, 'urineCultureSent'),
  enumKeySection('Urinalysis Results', InHouseLabResults.UrinalysisResults, 'urinalysisResults'),
];
const glucoseFields = [enumKeySection('Glucose', InHouseLabResults.Glucose, 'glucose')];
const bilirubinFields = [enumKeySection('Bilirubin', InHouseLabResults.Bilirubin, 'bilirubin')];
const ketonesFields = [enumKeySection('Ketones', InHouseLabResults.Ketones, 'ketones')];
const specificGravityFields = [
  enumKeySection('Specific Gravity', InHouseLabResults.SpecificGravity, 'specificGravity'),
];
const bloodFields = [enumKeySection('Blood', InHouseLabResults.Blood, 'blood')];
const phFields = [enumKeySection('pH', InHouseLabResults.pH, 'ph')];
const proteinFields = [enumKeySection('Protein', InHouseLabResults.Protein, 'protein')];
const urobilinogenFields = [enumKeySection('Urobilinogen', InHouseLabResults.Urobilinogen, 'urobilinogen')];
const nitriteFields = [optionsSection('Nitrite', standardOptions.yesNoAnswer, 'nitrite')];
const leukocytesFields = [enumKeySection('Leukocytes', InHouseLabResults.Leukocytes, 'leukocytes')];
const urineHcgFields = [
  optionsSection('POC Urine HCG Completed', standardOptions.yesNoAnswer, 'pocUrineHcgCompleted'),
  optionsSection('Urine HCG', standardOptions.positiveNegativeAnswer, 'urineHcg'),
];
const monoSpotFields = [
  optionsSection('POC Mono Spot Completed', standardOptions.yesNoAnswer, 'pocMonoSpotCompleted'),
  optionsSection('Mono Spot', standardOptions.positiveNegativeAnswer, 'monoSpot'),
];
const influenzaFields = [
  optionsSection('POC Rapid Influenza A & B Completed', standardOptions.yesNoAnswer, 'pocRapidInfluenzaAAndBCompleted'),
  enumKeySection('Rapid Influenza A & B', InHouseLabResults.RapidInfluenzaAAndB, 'rapidInfluenzaAAndB'),
];

const albuminCreatinineFields = [
  optionsSection('POC Microalbumin Completed', standardOptions.yesNoAnswer, 'pocMicroalbuminCompleted'),
  enumKeySection('Microalbumin', InHouseLabResults.Microalbumin, 'microalbumin'),
  enumKeySection('Creatinine', InHouseLabResults.Creatinine, 'creatinine'),
  enumKeySection('Albumin/Creatinine Ration', InHouseLabResults.AlbuminCreatinineRation, 'albuminCreatinineRation'),
];

type FieldConfiguration = {
  header: string;
  formControlName: string;
} & ({ toggleOptions: Option[] } | { optionsEnum: Record<string, string> });

export interface LabFormSection {
  formGroupName: keyof Omit<ChartInHouseLabFormGroup, 'bloodDrawGroup'>;
  fields: FieldConfiguration[];
  subGroup?: string;
}

export const inHouseLabsFormConfiguration: LabFormSection[] = [
  {
    formGroupName: 'strepGroup',
    fields: strepFields,
    subGroup: 'strep',
  },
  {
    formGroupName: 'urineGroup',
    fields: urineFields,
    subGroup: 'urine',
  },
  {
    formGroupName: 'glucoseGroup',
    fields: glucoseFields,
  },
  {
    formGroupName: 'bilirubinGroup',
    fields: bilirubinFields,
  },
  {
    formGroupName: 'ketonesGroup',
    fields: ketonesFields,
  },
  {
    formGroupName: 'specificGravityGroup',
    fields: specificGravityFields,
  },
  {
    formGroupName: 'bloodGroup',
    fields: bloodFields,
  },
  {
    formGroupName: 'phGroup',
    fields: phFields,
  },
  {
    formGroupName: 'proteinGroup',
    fields: proteinFields,
  },
  {
    formGroupName: 'urobilinogenGroup',
    fields: urobilinogenFields,
  },
  {
    formGroupName: 'nitriteGroup',
    fields: nitriteFields,
  },
  {
    formGroupName: 'leukocytesGroup',
    fields: leukocytesFields,
  },
  {
    formGroupName: 'urineHcgGroup',
    fields: urineHcgFields,
    subGroup: 'urineHcg',
  },
  {
    formGroupName: 'monoSpotGroup',
    fields: monoSpotFields,
    subGroup: 'monoSpot',
  },
  {
    formGroupName: 'influenzaGroup',
    fields: influenzaFields,
    subGroup: 'influenza',
  },
  {
    formGroupName: 'albuminCreatinineGroup',
    fields: albuminCreatinineFields,
    subGroup: 'albuminCreatinine',
  },
];

export const PhysicalExamFormConfiguration: FormSectionConfiguration<
  Omit<PhysicalExamFormGroup, 'otherGroup' | 'formsCompletedGroup'>
>[] = [
  {
    heading: 'General',
    formGroupName: 'generalGroup',
    formControlName: 'general',
    options: PhysicalExam.General,
    deferrable: false,
  },
  {
    heading: 'Head',
    formGroupName: 'headGroup',
    formControlName: 'head',
    options: PhysicalExam.Head,
    deferrable: false,
  },
  {
    heading: 'Eyes',
    formGroupName: 'eyesGroup',
    formControlName: 'eyes',
    options: PhysicalExam.Eyes,
    deferrable: false,
  },
  {
    heading: 'Ears',
    formGroupName: 'earsGroup',
    formControlName: 'ears',
    options: PhysicalExam.Ears,
    deferrable: false,
  },
  {
    heading: 'Nose',
    formGroupName: 'noseGroup',
    formControlName: 'nose',
    options: PhysicalExam.Nose,
    deferrable: false,
  },
  {
    heading: 'Mouth & Throat',
    formGroupName: 'mouthAndThroatGroup',
    formControlName: 'mouthAndThroat',
    options: PhysicalExam.MouthAndThroat,
    deferrable: false,
  },
  {
    heading: 'Neck',
    formGroupName: 'neckGroup',
    formControlName: 'neck',
    options: PhysicalExam.Neck,
    deferrable: false,
  },
  {
    heading: 'Breast',
    formGroupName: 'breastGroup',
    formControlName: 'breast',
    options: PhysicalExam.Breast,
    deferrable: true,
  },
  {
    heading: 'Respiratory',
    formGroupName: 'respiratoryGroup',
    formControlName: 'respiratory',
    options: PhysicalExam.Respiratory,
    deferrable: false,
  },
  {
    heading: 'Cardiovascular',
    formGroupName: 'cardiovascularGroup',
    formControlName: 'cardiovascular',
    options: PhysicalExam.Cardiovascular,
    deferrable: false,
  },
  {
    heading: 'Abdominal',
    formGroupName: 'gastrointestinalGroup',
    formControlName: 'gastrointestinal',
    options: PhysicalExam.Gastrointestinal,
    deferrable: false,
  },
  {
    heading: 'Genitourinary - Female',
    formGroupName: 'genitourinaryFemaleGroup',
    formControlName: 'genitourinaryFemale',
    options: PhysicalExam.GenitourinaryFemale,
    deferrable: true,
  },
  {
    heading: 'Genitourinary - Male',
    formGroupName: 'genitourinaryMaleGroup',
    formControlName: 'genitourinaryMale',
    options: PhysicalExam.GenitourinaryMale,
    deferrable: true,
  },
  {
    heading: 'Musculoskeletal',
    formGroupName: 'musculoskeletalGroup',
    formControlName: 'musculoskeletal',
    options: PhysicalExam.Musculoskeletal,
    deferrable: false,
  },
  {
    heading: 'Extremities',
    formGroupName: 'extremitiesGroup',
    formControlName: 'extremities',
    options: PhysicalExam.Extremities,
    deferrable: false,
  },
  {
    heading: 'Skin',
    formGroupName: 'integumentaryGroup',
    formControlName: 'integumentary',
    options: PhysicalExam.Integumentary,
    deferrable: false,
  },

  {
    heading: 'Neurological',
    formGroupName: 'neurologicalGroup',
    formControlName: 'neurological',
    options: PhysicalExam.Neurological,
    deferrable: false,
  },
  {
    heading: 'Psychiatric',
    formGroupName: 'psychiatricGroup',
    formControlName: 'psychiatric',
    options: PhysicalExam.Psychiatric,
    deferrable: false,
  },
];

export const ReviewOfSystemsFormConfiguration: FormSectionConfiguration<
  Omit<ReviewOfSystemsGroup, 'hpiGroup' | 'otherGroup'>
>[] = [
  {
    heading: 'Constitutional/General',
    formGroupName: 'constitutionalGeneralGroup',
    formControlName: 'constitutionalGeneral',
    options: ReviewOfSystems.ConstitutionalGeneralReview,
    deferrable: false,
  },
  {
    heading: 'Head',
    formGroupName: 'headGroup',
    formControlName: 'head',
    options: ReviewOfSystems.HeadReview,
    deferrable: false,
  },
  {
    heading: 'Eyes',
    formGroupName: 'eyesGroup',
    formControlName: 'eyes',
    options: ReviewOfSystems.EyesReview,
    deferrable: false,
  },
  {
    heading: 'Ears, Nose, Mouth, & Throat',
    formGroupName: 'earsNoseMouthThroatGroup',
    formControlName: 'earsNoseMouthThroat',
    options: ReviewOfSystems.EarsNoseMouthThroatReview,
    deferrable: false,
  },
  {
    heading: 'Neck',
    formGroupName: 'neckGroup',
    formControlName: 'neck',
    options: ReviewOfSystems.NeckReview,
    deferrable: false,
  },
  {
    heading: 'Breast',
    formGroupName: 'breastGroup',
    formControlName: 'breast',
    options: ReviewOfSystems.BreastReview,
    deferrable: false,
  },
  {
    heading: 'Respiratory',
    formGroupName: 'respiratoryGroup',
    formControlName: 'respiratory',
    options: ReviewOfSystems.RespiratoryReview,
    deferrable: false,
  },
  {
    heading: 'Cardiovascular',
    formGroupName: 'cardiovascularGroup',
    formControlName: 'cardiovascular',
    options: ReviewOfSystems.CardiovascularReview,
    deferrable: false,
  },

  {
    heading: 'Abdominal',
    formGroupName: 'gastrointestinalGroup',
    formControlName: 'gastrointestinal',
    options: ReviewOfSystems.GastrointestinalReview,
    deferrable: false,
  },
  {
    heading: 'Genitourinary - Female',
    formGroupName: 'genitourinaryFemaleGroup',
    formControlName: 'genitourinaryFemale',
    options: ReviewOfSystems.GenitourinaryFemaleReview,
    deferrable: false,
  },
  {
    heading: 'Genitourinary - Male',
    formGroupName: 'genitourinaryMaleGroup',
    formControlName: 'genitourinaryMale',
    options: ReviewOfSystems.GenitourinaryMaleReview,
    deferrable: false,
  },
  {
    heading: 'Musculoskeletal',
    formGroupName: 'musculoskeletalGroup',
    formControlName: 'musculoskeletal',
    options: ReviewOfSystems.MusculoskeletalReview,
    deferrable: false,
  },
  {
    heading: 'Hematologic/Lymphatic',
    formGroupName: 'hematologicLymphaticGroup',
    formControlName: 'hematologicLymphatic',
    options: ReviewOfSystems.HematologicLymphaticReview,
    deferrable: false,
  },
  {
    heading: 'Skin',
    formGroupName: 'integumentaryGroup',
    formControlName: 'integumentary',
    options: ReviewOfSystems.IntegumentaryReview,
    deferrable: false,
  },
  {
    heading: 'Endocrine',
    formGroupName: 'endocrineGroup',
    formControlName: 'endocrine',
    options: ReviewOfSystems.EndocrineReview,
    deferrable: false,
  },
  {
    heading: 'Neurological',
    formGroupName: 'neurologicalGroup',
    formControlName: 'neurological',
    options: ReviewOfSystems.NeurologicalReview,
    deferrable: false,
  },
  {
    heading: 'Psychiatric',
    formGroupName: 'psychiatricGroup',
    formControlName: 'psychiatric',
    options: ReviewOfSystems.PsychiatricReview,
    deferrable: false,
  },
];

export const ObjectiveConfiguration: FormSectionConfiguration<ChartObjectiveGroup>[] = [
  {
    heading: 'Depression',
    formGroupName: 'depressionGroup',
    formControlName: 'depression',
    options: Objective.Depression,
  },
  {
    heading: 'Anxiety',
    formGroupName: 'anxietyGroup',
    formControlName: 'anxiety',
    options: Objective.Anxiety,
  },
  {
    heading: 'Panic',
    formGroupName: 'panicGroup',
    formControlName: 'panic',
    options: Objective.Panic,
  },
  {
    heading: 'Acute Stress/PTSD',
    formGroupName: 'acuteStressPtsdGroup',
    formControlName: 'acuteStressPtsd',
    options: Objective.AcuteStressPtsd,
  },
  {
    heading: 'Mania',
    formGroupName: 'maniaGroup',
    formControlName: 'mania',
    options: Objective.Mania,
  },
  {
    heading: 'Attention/Hyperactivity',
    formGroupName: 'attentionHyperactivityGroup',
    formControlName: 'attentionHyperactivity',
    options: Objective.AttentionHyperactivity,
  },
  {
    heading: 'Delusions',
    formGroupName: 'delusionsGroup',
    formControlName: 'delusions',
    options: Objective.Delusions,
  },
  {
    heading: 'Hallucinations',
    formGroupName: 'hallucinationsGroup',
    formControlName: 'hallucinations',
    options: Objective.Hallucinations,
  },
  {
    heading: 'Eating Disorders',
    formGroupName: 'eatingDisordersGroup',
    formControlName: 'eatingDisorders',
    options: Objective.EatingDisorders,
  },
];

export const MentalStatusConfiguration = [
  {
    heading: 'Appearance',
    formControlName: 'appearance',
    noteControl: 'appearanceNote',
    options: MentalStatus.Appearance,
  },
  {
    heading: 'Speech',
    formControlName: 'speech',
    noteControl: 'speechNote',
    options: MentalStatus.Speech,
  },
  {
    heading: 'Eye Contact',
    formControlName: 'eyeContact',
    noteControl: 'eyeContactNote',
    options: MentalStatus.EyeContact,
  },
  {
    heading: 'Motor Activity',
    formControlName: 'motorActivity',
    noteControl: 'motorActivityNote',
    options: MentalStatus.MotorActivity,
  },
  {
    heading: 'Affect',
    formControlName: 'affect',
    noteControl: 'affectNote',
    options: MentalStatus.Affect,
  },
  {
    heading: 'Mood',
    formControlName: 'mood',
    noteControl: 'moodNote',
    options: MentalStatus.Mood,
  },
  {
    heading: 'Orientation',
    formControlName: 'orientation',
    noteControl: 'orientationNote',
    options: MentalStatus.Orientation,
  },
  {
    heading: 'Safety Concerns',
    formControlName: 'thoughtContent',
    noteControl: 'safetyConcernsNote',
    options: MentalStatus.SafetyConcerns,
  },
  {
    heading: 'Attitude/Behavior',
    formControlName: 'attitudeBehavior',
    noteControl: 'attitudeBehaviorNote',
    options: MentalStatus.AttitudeBehavior,
  },
  // Removed relationalStyle form input - 5/12/2022.
  {
    heading: 'Insight',
    formControlName: 'insight',
    noteControl: 'insightNote',
    options: MentalStatus.Insight,
  },
  {
    heading: 'Judgement',
    formControlName: 'judgement',
    noteControl: 'judgementNote',
    options: MentalStatus.Judgement,
  },
];
