import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const params = next.queryParams;
    if (params?.email) {
      return this.router.navigate([], {
        queryParams: {
          email: null,
        },
        queryParamsHandling: 'merge',
      });
    }
    return true;
  }
}
