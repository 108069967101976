import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';

import { UserDto } from '../../../../../core/dto/user';
import { User } from '../../../../../core/models/user';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user = new BehaviorSubject<User>(null);
  public user = this._user.asObservable();

  constructor(private authService: AuthService, private httpClient: HttpClient) {
    this.authService.authState
      .pipe(
        switchMap((authState) => {
          if (this.authService.isAuthenticated()) {
            return this.getSelf();
          } else {
            return of(null);
          }
        })
      )
      .subscribe((user) => {
        this._user.next(user);
      });
  }

  get currentUser() {
    return this._user.getValue();
  }

  public getSelf() {
    const url = environment.apiUrl.concat('/users/me');
    return this.httpClient.get<UserDto>(url).pipe(map((user) => new User(user)));
  }

  updateUserEmail(email: string) {
    const userId = this.authService.currentAuthState.profile.sub;
    const url = environment.apiV3Url.concat(`/users/${userId}/email`);

    return this.httpClient.put(url, { email });
  }

  sendUserDeletionRequest() {
    const url = environment.apiV3Url.concat('/requests/me/account-delete');
    return this.httpClient.post(url, {});
  }
}
