import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../../shared/shared.module';

import { AppointmentsPageComponent } from './appointments-page.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { RescheduleAppointmentComponent } from './reschedule-appointment/reschedule-appointment.component';
import { BookAppointmentDialogComponent } from './book-appointment-dialog/book-appointment-dialog.component';
import { AppointmentListPaginatedComponent } from './appointment-list-paginated/appointment-list-paginated.component';

const routes: Routes = [
  {
    path: '',
    component: AppointmentsPageComponent,
  },
  {
    path: 'reschedule-appointment',
    component: RescheduleAppointmentComponent,
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModule],
  declarations: [
    AppointmentListPaginatedComponent,
    AppointmentsPageComponent,
    SearchBoxComponent,
    AppointmentCardComponent,
    ConfirmComponent,
    RescheduleAppointmentComponent,
    BookAppointmentDialogComponent,
  ],
})
export class AppointmentsPageModule {}
