<div>
  <div class="patient-app-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div>
      <app-title-bar></app-title-bar>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="patient-app-content">
        <div class="ion-text-center">
          <ion-card class="ion-text-center" *ngIf="!documents || !documents.length">
            <ion-card-content>
              <p>No saved documents for {{ (patientService.activePatient | async)?.name }}.</p>
            </ion-card-content>
          </ion-card>
          <div class="document-container">
            <app-page-nav
              *ngIf="documents?.length; pageSize"
              [pageCount]="pageCount"
              (pageChange)="pageChange($event)"
            ></app-page-nav>
            <app-document-card
              *ngFor="let document of documents | slice : page * pageSize : (page + 1) * pageSize"
              [document]="document"
            >
            </app-document-card>
          </div>
        </div>

        <input
          #file
          (change)="onFilesAdded()"
          type="file"
          accept="image/png, image/jpeg, application/pdf, .jpg, .png, .pdf"
          style="display: none"
        />
        <ion-fab vertical="bottom" horizontal="start" slot="fixed">
          <ion-fab-button color="accent" [disabled]="(activePatient | async)?.active === false" (click)="refresh()">
            <ion-icon name="refresh"></ion-icon>
          </ion-fab-button>
          <ion-badge color="accent">Refresh List</ion-badge>
        </ion-fab>
        <ion-fab vertical="bottom" horizontal="end" slot="fixed">
          <ion-fab-button color="accent" [disabled]="(activePatient | async)?.active === false" (click)="addFiles()">
            <ion-icon name="add"></ion-icon>
          </ion-fab-button>
          <ion-badge color="accent">Add Document</ion-badge>
        </ion-fab>
      </div>
    </div>
  </div>
</div>
