<ion-grid [formGroup]="form">
  <ion-note *ngIf="!editMode">
    Use this form to add children aged 0-17. Adults should create their own accounts.
  </ion-note>

  <ion-item>
    <ion-input
      label="Legal First Name*"
      labelPlacement="floating"
      type="text"
      autocapitalize="words"
      formControlName="firstName"
    ></ion-input>
  </ion-item>
  <span
    class="input-error"
    *ngIf="
      form.controls.firstName.invalid &&
      form.controls.firstName.touched &&
      !form.controls.firstName.hasError('forbiddenCharacters')
    "
    >First Name is required
  </span>
  <span class="input-error" *ngIf="form.controls.firstName.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>

  <ion-item>
    <ion-input
      label="Legal Last Name*"
      labelPlacement="floating"
      type="text"
      autocapitalize="words"
      formControlName="lastName"
    ></ion-input>
  </ion-item>
  <span
    class="input-error"
    *ngIf="
      form.controls.lastName.invalid &&
      form.controls.lastName.touched &&
      !form.controls.lastName.hasError('forbiddenCharacters')
    "
    >Last Name is required</span
  >
  <span class="input-error" *ngIf="form.controls.lastName.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>

  <ion-item>
    <ion-input
      label="Preferred Name"
      label-placement="floating"
      type="text"
      autocapitalize="words"
      formControlName="preferredName"
    ></ion-input>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.preferredName.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>

  <div [formGroup]="form" class="datepicker">
    <mat-form-field>
      <mat-label
        [ngClass]="{
          'mat-danger': form.controls.dateOfBirth.invalid && form.controls.dateOfBirth.touched,
          'mat-tertiary': !form.controls.dateOfBirth.invalid || !form.controls.dateOfBirth.touched
        }"
      >
        Date of Birth
      </mat-label>
      <input
        [max]="maxDateOfBirth"
        (dateChange)="handleDateOfBirthChanges($event)"
        [matDatepicker]="datePicker"
        formControlName="dateOfBirth"
        matInput
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker aria-label="Date of Birth"> </mat-datepicker>
      <mat-error *ngIf="form.controls.dateOfBirth.hasError('tooOld')">
        Patient must be aged 0-17. Adults should create their own accounts
      </mat-error>
      <mat-error *ngIf="form.controls.dateOfBirth.hasError('matDatepickerMax')"> Please select a valid date </mat-error>
    </mat-form-field>
  </div>

  <ion-item>
    <ion-select label="Sex Assigned at Birth*" labelPlacement="floating" formControlName="sexAssignedAtBirth">
      <ion-select-option [value]="'FEMALE'">Female</ion-select-option>
      <ion-select-option [value]="'MALE'">Male</ion-select-option>
    </ion-select>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.sexAssignedAtBirth.invalid && form.controls.sexAssignedAtBirth.touched"
    >Sex Assigned at Birth is required</span
  >

  <ion-item>
    <ion-select label="Gender*" labelPlacement="floating" formControlName="gender">
      <ion-select-option [value]="0">Female</ion-select-option>
      <ion-select-option [value]="1">Male</ion-select-option>
      <ion-select-option [value]="3">Transgender Woman</ion-select-option>
      <ion-select-option [value]="4">Transgender Man</ion-select-option>
      <ion-select-option [value]="5">Gender Nonconforming</ion-select-option>
      <ion-select-option [value]="2">Something Else</ion-select-option>
    </ion-select>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.gender.invalid && form.controls.gender.touched"
    >Gender is required</span
  >

  <ion-item>
    <ion-select label="Race*" labelPlacement="floating" formControlName="race">
      <ion-select-option *ngFor="let raceOption of race | enum" [value]="raceOption.key">{{
        raceOption.value
      }}</ion-select-option>
    </ion-select>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.race.invalid && form.controls.race.touched">Race is required</span>

  <!-- Only display the membership relationship option for non-account holders. -->
  <ion-item *ngIf="Number.parseInt(form.controls.relationToAccountHolder.value) !== relationship.Self">
    <ion-select
      *ngIf="editingAdultPatient"
      label="Relationship to Primary Account Holder*"
      label-placement="floating"
      formControlName="relationToAccountHolder"
    >
      <ion-select-option *ngFor="let relation of relationship | enum" [value]="relation.key">{{
        relation.value
      }}</ion-select-option>
    </ion-select>

    <ion-select
      *ngIf="!editingAdultPatient"
      label="Relationship to Primary Account Holder*"
      label-placement="floating"
      formControlName="relationToAccountHolder"
    >
      <ion-select-option *ngFor="let relate of addPatientRelationship | enum" [value]="relate.key">{{
        relate.value
      }}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-label>
    <h3>Contact Information</h3>
  </ion-label>

  <ion-item>
    <ion-input
      label="Mobile Phone*"
      labelPlacement="floating"
      formControlName="phone"
      (ionChange)="handlePhoneNumberChange($event)"
      minlength="14"
      maxlength="14"
      type="tel"
    >
    </ion-input>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.phone.invalid && form.controls.phone.touched">Phone is required</span>

  <ion-item>
    <ion-input label="Email*" labelPlacement="floating" formControlName="email" type="email"></ion-input>
  </ion-item>
  <span
    class="input-error"
    *ngIf="
      form.controls.email.invalid && form.controls.email.touched && !form.controls.email.hasError('forbiddenDomain')
    "
    >Email is required</span
  >

  <ion-item>
    <ion-input label="Confirm Email*" labelPlacement="floating" formControlName="confirmEmail" type="email"></ion-input>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.confirmEmail.invalid && form.controls.confirmEmail.touched"
    >Confirm email is required.</span
  >
  <span
    class="input-error"
    *ngIf="form.controls.email.value !== form.controls.confirmEmail.value && form.controls.confirmEmail.touched"
    >Email addresses must match.</span
  >

  <ion-item lines="none">
    <ion-text color="medium" class="ion-margin">
      This is the email we will use by default to send appointment confirmations and reminders. To update your login
      information, please click “Login Information” on the side navigation in the app. Changes made here will not update
      your login.
    </ion-text>
  </ion-item>

  <app-address-form formControlName="address"></app-address-form>
</ion-grid>
