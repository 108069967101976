import { Event } from '@amplitude/analytics-types/lib/esm/event';

/**
 * Overrides the current analytics {@link Promise} wrapper in order to provide an abstraction from the current
 * analytics vendor implementation.
 */
export interface LogEventRequest {
  promise: Promise<LogEventResult>;
}

/**
 * Overrides the current analytics log event result object in order to provide an abstraction from the current
 * analytics vendor implementation.
 */
export interface LogEventResult {
  event: Event;
  code: number;
  message: string;
}

/**
 * Defines the key / value pair type use for event properties.
 */
// export type AnalyticEventPropertySet<T extends string, U extends unknown> = Record<T, U>;
export type AnalyticEventPropertySet = Record<string, unknown>;

/**
 * Defines the key / value pair type use for event properties.
 */
export class AnalyticEventProperties {
  private readonly eventProperties: AnalyticEventPropertySet;

  constructor(eventProperties?: AnalyticEventPropertySet) {
    if (eventProperties) {
      this.eventProperties = AnalyticEventProperties.getValidProperties(eventProperties);
    } else {
      this.eventProperties = {};
    }
  }

  /**
   * Ensures all given properties are valid.  Returns a set of properties with any needed items filtered out.
   *
   * @param eventProperties the event properties to validate.
   *
   * @return a set of valid {@link AnalyticEventProperties}.
   */
  static getValidProperties(eventProperties: AnalyticEventPropertySet | null | undefined): AnalyticEventPropertySet {
    const validProperties = new AnalyticEventProperties();

    if (eventProperties) {
      const propertyKeys = Object.keys(eventProperties);
      // This will merge new event properties with existing ones, overwriting any existing items.
      // We also ensure each property is validated according to any rules we have in place, like not allowing empties,
      // vs. just applying the properties: Object.assign(this.eventProperties, eventProperties.eventProperties);
      for (let i = 0; i < propertyKeys.length; i++) {
        validProperties.addProperty(propertyKeys[i], eventProperties[propertyKeys[i]]);
      }
    }

    return validProperties.eventProperties;
  }

  private static isValidProperty(propertyValue: any): boolean {
    let validProperty = true;

    if (propertyValue !== undefined && propertyValue !== null) {
      if (propertyValue === '') {
        validProperty = false;
      } else if (Array.isArray(propertyValue) && propertyValue.length === 0) {
        validProperty = false;
      }
    } else {
      validProperty = false;
    }

    return validProperty;
  }

  /**
   * Adds a property to the current property map.  If the value is <code>null</code> or <code>undefined</code>,
   * no property will be added.
   *
   * @param propertyName the name of the property.
   * @param propertyValue the value of the property.
   */
  addProperty(propertyName: string, propertyValue: any): void {
    if (AnalyticEventProperties.isValidProperty(propertyValue)) {
      this.eventProperties[propertyName] = propertyValue;
    }
  }

  /**
   * Apply event properties in bulk.  This will overwrite any existing items with the same property names.
   *
   * @param eventProperties the new properties to add to the object.
   */
  addProperties(eventProperties: AnalyticEventProperties | null | undefined): void {
    if (eventProperties) {
      // Merge in new event properties to existing ones.  This will overwrite any existing items.
      Object.assign(this.eventProperties, AnalyticEventProperties.getValidProperties(eventProperties.eventProperties));
    }
  }

  removeProperty(propertyName: string): void {
    delete this.eventProperties[propertyName];
  }

  getProperty(propertyName: string): any | undefined {
    return this.eventProperties[propertyName];
  }

  getProperties(): AnalyticEventPropertySet {
    return this.eventProperties;
  }

  getPropertiesAsString(): string {
    const propertyNames = Object.keys(this.eventProperties);
    let propertiesAsString = '';

    propertyNames.forEach((propertyName: string) => {
      propertiesAsString += `${propertyName}::${this.eventProperties[propertyName]} ** `;
    });

    // Trim off trailing separator.
    if (propertiesAsString.length > 0) {
      propertiesAsString = propertiesAsString.substring(0, propertiesAsString.length - 4);
    }

    return propertiesAsString;
  }

  clearAll(): void {
    (this.eventProperties as any) = {};
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnalyticEventPropertiesObject {}
