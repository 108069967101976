<div *ngIf="appointments && patients && activePatient">
  <ion-grid>
    <ion-row *ngIf="appointments.data.length === 0">
      <ion-card>
        <ion-card-content>
          <p>
            No
            <span *ngIf="view === 'future'">appointments scheduled</span>
            <span *ngIf="view === 'past'">past appointments</span>
            for {{ activePatient.name }}.
          </p>
        </ion-card-content>
      </ion-card>
    </ion-row>
    <ion-row *ngIf="appointments.count > pageSize">
      <ion-toolbar>
        <ion-buttons slot="start" class="prev-page-button">
          <ion-button
            [disabled]="!enableShowPrevious"
            class="pagination-control"
            fill="clear"
            (click)="incrementPage(-1)"
          >
            Previous
            <ion-icon aria-hidden="true" slot="start" name="chevron-back-outline"> </ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <span class="pagination-control"> {{ selectedPage + 1 }} of {{ numberOfPages }} </span>
        </ion-title>
        <ion-buttons slot="end" class="next-page-button">
          <ion-button [disabled]="!enableShowNext" class="pagination-control" fill="clear" (click)="incrementPage(1)">
            Next
            <ion-icon aria-hidden="true" slot="end" name="chevron-forward-outline"> </ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-row>
    <ion-row>
      <div fxLayout="column" class="appointment-list patient-app-content-ion-row">
        <app-appointment-card
          *ngFor="let appointment of appointments.data"
          [appointment]="appointment"
          [upcomingAppointment]="view === 'future'"
        >
        </app-appointment-card>
      </div>
    </ion-row>
  </ion-grid>
</div>
