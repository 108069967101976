import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DisplayComponent } from '../display/display.component';
import { DocumentService } from '../../../core/services/document.service';
import { AlertService } from '../../../core/services/alert.service';
import { Document } from '@models';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent {
  @Input() document: Document;

  constructor(
    private alertService: AlertService,
    private modalController: ModalController,
    private documentService: DocumentService
  ) {}

  get documentType() {
    const documentType = <string>this.document.metadata.contentType;
    return documentType.substr(documentType.lastIndexOf('/') + 1) || documentType;
  }

  async presentModal() {
    try {
      this.alertService.loading();

      const fileType = this.getFileType(this.document.metadata.contentType);
      const fileName = this.splitFileName(this.document.metadata.name);
      const url: any = await this.documentService.getReadUrl(fileType, fileName.file, fileName.id).toPromise();

      const modal = await this.modalController.create({
        component: DisplayComponent,
        componentProps: {
          url,
          document: this.document,
        },
      });
      modal.onDidDismiss().then((res) => {});
      return await modal.present();
    } catch (error) {
      this.alertService.error('Failed to load document.  Document may be restricted.');
    } finally {
      this.alertService.hideLoading();
    }
  }

  getFileType(fileType: string) {
    const split = fileType.split(/\/(?=[^\/]+$)/);
    const [content, type] = split;
    return { content, type };
  }

  splitFileName(fileName: string) {
    const split = fileName.split(/\/(?=[^\/]+$)/);
    const [id, file] = split;
    return { id, file };
  }
}
