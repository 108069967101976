interface BooleanMapping {
  true: string;
  false: string;
  else: string;
}

const defaultBooleanMapping: BooleanMapping = {
  true: 'Yes',
  false: 'No',
  else: '',
};

export function booleanToString(
  value: TsoaBoolean | boolean | null | undefined,
  mapping = defaultBooleanMapping,
): string {
  if (value === true) {
    return mapping.true;
  } else if (value === false) {
    return mapping.false;
  } else {
    return mapping.else;
  }
}

// Tsoa will interpret null values of boolean | null as false, this forces some sort of conformity
// https://github.com/lukeautry/tsoa/issues/761
export type TsoaBoolean = null | boolean;
