import { AnalyticsEvent } from '../models/analytics/analyticsEvent';
import { AnalyticEventProperties } from '../models/analytics/analytics';
import { AnalyticsProperty } from '../models/analytics/analyticsProperty';
import { logAnalyticsEvent } from './analytics';
import { Gesture } from '../models/analytics/analyticsValues';

/**
 * Helper for logging events relating to the appointment table.  Breaks down the details into event properties.
 *
 * @param successful true if the login is successful, false otherwise.
 */
export function logUserLoggedInEvent(successful: boolean): void {
  const eventProperties = new AnalyticEventProperties();

  // Required properties
  eventProperties.addProperty(AnalyticsProperty.GESTURE, Gesture.CLICKED);
  eventProperties.addProperty(AnalyticsProperty.SUCCESSFUL, successful);

  logAnalyticsEvent(AnalyticsEvent.USER_LOGGED_IN, eventProperties);
}
