import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { State } from '@enums';

export interface SelectStateFormValues {
  selectedState: State;
}

@Component({
  selector: 'app-select-state-form',
  templateUrl: './select-state-form.component.html',
  styleUrls: ['./select-state-form.component.scss'],
})
export class SelectStateFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() states: State[];

  public header = 'What state will you be in at the time of this visit?';
  public placeholder = 'Select a state';
  public hint = 'We’ll find providers licensed in that state.';

  public selectedState: State;

  constructor(public utilityService: UtilityService) {}

  static formModel(initValues?: SelectStateFormValues) {
    return new UntypedFormGroup({
      selectedState: new UntypedFormControl(initValues ? initValues.selectedState : null, Validators.required),
    });
  }

  ngOnInit() {}
}
