<div>
  <div class="patient-app-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div>
      <app-title-bar [hidePatientSelector]="true"></app-title-bar>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="patient-app-content-no-header-or-footer">
        <div class="ph-no-capture" *ngFor="let patient of patients | async; let i = index">
          <app-patient-card *ngIf="(activePatient | async)?.id === patient.id" [patient]="patient" [active]="true">
          </app-patient-card>
          <app-patient-card *ngIf="(activePatient | async)?.id !== patient.id" [patient]="patient" [active]="false">
          </app-patient-card>
        </div>
        <ion-fab vertical="bottom" horizontal="end" slot="fixed">
          <ion-fab-button color="accent" (click)="presentAddPatientModal()">
            <ion-icon name="person-add"></ion-icon>
          </ion-fab-button>
          <ion-badge color="accent">Add Patient</ion-badge>
        </ion-fab>
      </div>
    </div>
  </div>
</div>
