import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum',
})
export class EnumPipe implements PipeTransform {
  transform(inputEnum: any, args?: any): any {
    const values = [];
    for (const key in inputEnum) {
      if (inputEnum.hasOwnProperty(key) && typeof inputEnum[key] === 'string') {
        values.push({ key, value: inputEnum[key] });
      }
    }
    return values;
  }
}
