import { ChannelType } from '../enums/message';
import { assertUnreachable } from '../utility/unreachable';

const chatNamespaceKeys = ['provider', 'careCoordinator', 'internal'] as const;
const [providerChat, careCoordinatorChat, internalChat] = chatNamespaceKeys.map(
  (channel) => {
    const regExp = new RegExp(`\^/patient/\\d+/chat/${channel}$`);
    return {
      getServerPath: () => regExp,
      getClientPath: (patientId: string | number) =>
        `/patient/${patientId}/chat/${channel}`,
    };
  },
);

export const SocketNamespaces = {
  dashboard: '/dashboard',
  providerChat,
  internalChat,
  careCoordinatorChat,
} as const;

export function getSocketNameSpaceForChannel(channel: ChannelType) {
  switch (channel) {
    case ChannelType.coordinator:
      return SocketNamespaces.careCoordinatorChat;
    case ChannelType.internal:
      return SocketNamespaces.internalChat;
    case ChannelType.provider:
      return SocketNamespaces.providerChat;
    default:
      assertUnreachable(channel);
  }
}
