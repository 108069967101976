export enum Depression {
  sadnessDepression = 'Sadness/Depression',
  anhedonia = 'Anhedonia',
  diminishedMotivation = 'Diminished Motivation',
  insomnia = 'Insomnia',
  hypersomnia = 'Hypersomnia',
  appetiteDisturbance = 'Appetite Disturbance',
  weightChange = 'Weight Change',
  psychomotorRetardationAgitation = 'Psychomotor Retardation/Agitation',
  fatigueLethargy = 'Fatigue/Lethargy',
  lowSelfEsteem = 'Low Self-Esteem',
  guilt = 'Guilt',
  irritability = 'Irritability',
  suicidalIdeation = 'Suicidal Ideation',
}

export enum Anxiety {
  excessiveWorry = 'Excessive Worry',
  difficultyControllingWorry = 'Difficulty Controlling Worry',
  feelingTenseKeyedUp = 'Feeling Tense/Keyed Up',
  poorConcentration = 'Poor Concentration',
  nervousness = 'Nervousness',
  restlessness = 'Restlessness',
  irritability = 'Irritability',
  sleepDisturbance = 'Sleep Disturbance',
}

export enum Panic {
  heartPalpations = 'Heart Palpations',
  sweating = 'Sweating',
  trembling = 'Trembling',
  shortnessOfBreath = 'Shortness of Breath',
  chestPressure = 'Chest Pressure',
  nausea = 'Nausea',
  difficultySwallowing = 'Difficulty Swallowing',
  dizziness = 'Dizziness',
  derealization = 'Derealization',
  fearOfDying = 'Fear of Dying',
  fearOfLosingControl = 'Fear of Losing Control',
  numbness = 'Numbness',
  chills = 'Chills',
  feelingHot = 'Feeling Hot',
  agoraphobia = 'Agoraphobia',
  worriedAboutHavingPanicAttacks = 'Worried About Having Panic Attacks',
  avoidanceOfTriggers = 'Avoidance of Triggers',
}

export enum AcuteStressPtsd {
  historyOfTraumaticEvent = 'History of Traumatic Event',
  flashbacks = 'Flashbacks',
  nightmares = 'Nightmares',
  intrusiveMemories = 'Intrusive Memories',
  detachmentEstrangement = 'Detachment/Estrangement',
  avoidanceOfStimuliAssociatedWithTrauma = 'Avoidance of Stimuli Associated with Trauma',
}

export enum ChartSmokingStatus {
  currentSmoker = 'Current Smoker',
  formerSmoker = 'Former Smoker',
  never = 'Never',
}

export enum SubstanceUse {
  marijuana = 'Marijuana',
  illicit = 'Illicit',
  rxMisuse = 'Rx Misuse',
  caffeine = 'Caffeine',
}

export enum Mania {
  grandiosity = 'Grandiosity',
  decreasedNeedForSleep = 'Decreased Need for Sleep',
  pressuredSpeech = 'Pressured Speech',
  racingThoughts = 'Racing Thoughts',
  increasedActivityProductivity = 'Increased Activity/Productivity',
  irritability = 'Irritability',
  euphoria = 'Euphoria',
  impulsivity = 'Impulsivity',
  highRiskBehavior = 'High Risk Behavior',
  distractibility = 'Distractibility',
  moodSwings = 'Mood Swings',
}

export enum AttentionHyperactivity {
  difficultySustainingAttention = 'Difficulty Sustaining Attention',
  carelessMistakes = 'Careless Mistakes',
  poorListening = 'Poor Listening',
  poorFollowThrough = 'Poor Follow-Through',
  disorganization = 'Disorganization',
  avoidanceOfTimeConsumingTasks = 'Avoidance of Time Consuming Tasks',
  losingThings = 'Losing Things',
  easilyDistracted = 'Easily Distracted',
  forgetfulness = 'Forgetfulness',
  fidgets = 'Fidgets',
  restlessness = 'Restlessness',
  alwaysOnTheGo = 'Always on the go',
  talkingExcessively = 'Talking Excessively',
  difficultyWaitingTurnInLines = 'Difficulty Waiting Turn/In Lines',
  interrupts = 'Interrupts',
}

export enum Delusions {
  religiousDelusions = 'Religious Delusions',
  grandioseDelusions = 'Grandiose Delusions',
  paranoidDelusions = 'Paranoid Delusions',
  somaticDelusions = 'Somatic Delusions',
  persecutoryDelusions = 'Persecutory Delusions',
  selfDepreciativeDelusions = 'Self-Depreciative Delusions',
}

export enum Hallucinations {
  auditory = 'Auditory',
  command = 'Command',
  olfactory = 'Olfactory',
  tactile = 'Tactile',
  visual = 'Visual',
}

export enum EatingDisorders {
  binging = 'Binging',
  purging = 'Purging',
  fearOfWeightGain = 'Fear of Weight Gain',
  compulsiveOverEating = 'Compulsive Over-Eating',
}
