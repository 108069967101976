<ion-app>
  <ion-menu
    contentId="my-content"
    type="overlay"
    class="account-menu"
    swipeGesture="false"
  >
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title slot="start">Account Menu</ion-title>
        <ion-menu-toggle slot="end">
          <ion-item
            button
            color="primary"
            class="menu-item"
          >
            <ion-icon name="close-outline"></ion-icon>
          </ion-item>
        </ion-menu-toggle>
      </ion-toolbar>
    </ion-header>
    <ion-content color="secondary">
      <ion-list class="account-list">
        <ion-list-header>
          <ion-label color="tertiary">Patients</ion-label>
        </ion-list-header>
        <ion-menu-toggle
          auto-hide="false"
          *ngFor="let patient of patients | async"
        >
          <ion-item
            button
            class="menu-item patient-item"
            (click)="setActivePatient(patient)"
            [ngClass]="{ active: (activePatient | async)?.id === patient.id }"
          >
            <ion-text [color]="patient.active ? 'light' : 'dark'">
              <span class="ph-no-capture">
                {{ patient.name }}
              </span>
            </ion-text>
            <span
              style="color: red; padding-left: 5px"
              *ngIf="
                (unreadObject | async) && (unreadObject | async)[patient.id] > 0
              "
              >●</span
            >
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-list class="footer-list">
        <ion-list-header color="primary">
          <ion-label color="tertiary">SETTINGS</ion-label>
        </ion-list-header>
        <ion-item
          class="menu-item"
          routerLink="/billing"
          (click)="closeMenu()"
          color="primary"
        >
          <ion-text color="light"> Billing </ion-text>
        </ion-item>
        <ion-item
          *ngIf="showPharmacyCard | async"
          class="menu-item"
          routerLink="/pharmacy-card"
          (click)="closeMenu()"
          color="primary"
        >
          <ion-text color="light"> Pharmacy Card </ion-text>
        </ion-item>
        <ion-item
          class="menu-item"
          routerLink="/user"
          (click)="closeMenu()"
          color="primary"
        >
          <ion-text color="light"> Login Information </ion-text>
        </ion-item>
        <ion-item
          button
          color="primary"
          class="menu-item"
          (click)="goToPrivacyPolicy()"
        >
          <ion-text color="light">
            Privacy Policy
            <ion-icon name="open-outline"></ion-icon>
          </ion-text>
        </ion-item>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            button
            (click)="authService.logout()"
            class="menu-item"
            color="primary"
          >
            <ion-text color="light"> Logout </ion-text>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-footer>
  </ion-menu>

  <ion-router-outlet id="my-content" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
