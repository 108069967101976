import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackPageComponent } from './callback/callback-page.component';
import { LoginGuard } from '../core/guards/login.guard';
import { SignUpGuard } from '../core/guards/sign-up.guard';
import { RedirectGuard } from '../core/guards/redirect.guard';
import { environment } from '../../environments/environment';
import { LogoutPageComponent } from './logout/logout-page.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuard, RedirectGuard],
    loadChildren: () => import('../pages/redirect/redirect.module').then((m) => m.RedirectPageModule),
    data: {
      target: environment.newWebUrl,
    },
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'sign-up',
    canActivate: [SignUpGuard, RedirectGuard],
    loadChildren: () => import('../pages/redirect/redirect.module').then((m) => m.RedirectPageModule),
    data: {
      target: environment.newWebUrl + '/sign-up',
    },
  },
  {
    path: 'callback',
    component: CallbackPageComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SessionRoutingModule {}
