import { createInstance } from '@amplitude/analytics-browser';
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser';
import { BrowserClient, BrowserOptions, LogLevel, TrackingOptions } from '@amplitude/analytics-types';
import { AnalyticEventProperties, LogEventRequest } from '../models/analytics/analytics';
import { environment } from '../../../environments/environment';
import { version } from '../../../../../core/version';

/**
 * Log an analytics event.
 *
 * @param eventName the name of the event.
 * @param additionalEventProperties any additional event properties to log with the event.
 *
 * @return the {@link Promise} to be executed when the event is sent.
 */
export function logAnalyticsEvent(
  eventName: string,
  additionalEventProperties?: AnalyticEventProperties
): LogEventRequest {
  return getClient().track(eventName, additionalEventProperties?.getProperties());
}

/**
 * Set the user ID for all events going forward.
 *
 * @param userId the new user ID.
 */
export function setUserId(userId: string): void {
  if (userId && userId !== '') {
    // Remove the 'auth0|' user ID prefix when identifying the user for analytics as determined by committee.
    getClient().setUserId(userId.replace('auth0|', ''));
  } else {
    console.error('Invalid analytics user ID.');
  }
}

const analyticsClient: BrowserClient = initializeInstance();

function getClient(): BrowserClient {
  return analyticsClient;
}

function initializeInstance(): BrowserClient {
  const trackedDataOptions: TrackingOptions = {
    ipAddress: false,
    language: true,
    platform: true,

    // NOT tracked properties...
    // country: true,
    // carrier: true,
    // city: false,
    // dma: false,
    // ipAddress: false,
    // region: false,
  };

  const analyticsInstance = createInstance();

  const uaPlugin = userAgentEnrichmentPlugin({
    osName: true,
    osVersion: true,
    deviceManufacturer: true,
    deviceModel: true,
  });
  analyticsInstance.add(uaPlugin);

  analyticsInstance.init(environment.amplitudeApiKey, undefined, {
    // General configuration.  See https://www.docs.developers.amplitude.com/data/sdks/typescript-browser for details.
    appVersion: `${version.current}`,
    logLevel: LogLevel.Warn,
    cookieOptions: {
      sameSite: 'Lax',
      secure: true,
    },
    serverZone: 'US',

    // The tracked values define what is sent to Amplitude.  Not all data is collected for privacy reasons.
    trackingOptions: trackedDataOptions,

    // Plan configuration.  This is only used if integrating with the Amplitude CLI.
    plan: {
      branch: 'main',
      source: 'web',
      version: '1',
    },

    // Event queue configuration.
    flushIntervalMillis: 1000,
    flushMaxRetries: 5,
    flushQueueSize: 30,

    // Tracking configuration.  This is set up to capture all possible references. In the deprecated JavaScript SDK,
    // it was option to collect UTM, Google Click ID and referring URL.  With the browser TypeScript SDK, the tracking
    // of all correlation types is grouped under the "disabled" flag, which is on by default (in v2).
    // See the AttributionOptions in the SDK documentation for additional details.
    // OPTIONAL:
    //   attribution: true,
    //   pageViews: false,
    //   sessions: false,
    //   fileDownloads: false,
    //   formInteractions: false,
    defaultTracking: true,
  });

  return analyticsInstance;
}
