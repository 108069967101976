<div class="container">
  <form [formGroup]="form">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
    ></formly-form>
  </form>
</div>
