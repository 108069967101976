export enum Appearance {
  disheveled = 'Disheveled',
  neat = 'Neat',
  casual = 'Casual',
  inappropriate = 'Inappropriate',
  bizarre = 'Bizarre',
}

export enum Speech {
  normal = 'Normal',
  tangential = 'Tangential',
  pressured = 'Pressured',
  slow = 'Slow',
  soft = 'Soft',
  loud = 'Loud',
}

export enum EyeContact {
  avoidant = 'Avoidant',
  fair = 'Fair',
  good = 'Good',
  relaxed = 'Relaxed',
}

export enum MotorActivity {
  normal = 'Normal',
  restless = 'Restless',
  slow = 'Slow',
  relaxed = 'Relaxed',
}

export enum Affect {
  full = 'Full',
  constricted = 'Constricted',
  flat = 'Flat',
  labile = 'Labile',
}

export enum Mood {
  angry = 'Angry',
  anxious = 'Anxious',
  depressed = 'Depressed',
  distressed = 'Distressed',
  dysthymic = 'Dysthymic',
  euphoric = 'Euphoric',
  neutral = 'Neutral',
  irritable = 'Irritable',
}

export enum Orientation {
  place = 'Place',
  object = 'Object',
  person = 'Person',
  time = 'Time',
  self = 'Self',
}

export enum SafetyConcerns {
  si = 'SI',
  hi = 'HI',
  shi = 'SHI',
  sib = 'SIB',
  none = 'None',
}

export enum AttitudeBehavior {
  cooperative = 'Cooperative',
  guarded = 'Guarded',
  skeptical = 'Skeptical',
  interested = 'Interested',
  distracted = 'Distracted',
  withdrawn = 'Withdrawn',
  indifferent = 'Indifferent',
  aggressive = 'Aggressive',
  engaged = 'Engaged',
}

export enum RelationalStyle {
  codependent = 'Codependent',
  cooperative = 'Cooperative',
  dependent = 'Dependent',
  distant = 'Distant',
  interdependent = 'Interdependent',
  poorBoundaries = 'Poor Boundaries',
}

export enum Insight {
  aboveAverage = 'Above Average',
  fair = 'Fair',
  good = 'Good',
  poor = 'Poor',
}

export enum Judgement {
  intact = 'Intact',
  notIntact = 'Not Intact',
}
