import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Patient } from '@models';
import { PatientAppointment } from '../../../core/services/appointment.service';

@Component({
  selector: 'app-appointment-list-paginated',
  templateUrl: './appointment-list-paginated.component.html',
  styleUrls: ['./appointment-list-paginated.component.scss'],
})
export class AppointmentListPaginatedComponent implements OnInit, OnChanges {
  @Input() appointments: { data: PatientAppointment[]; count: number };
  @Input() patients: Patient[];
  @Input() activePatient: Patient;
  @Input() pageSize: number;
  @Input() view: 'future' | 'past';
  @Output() public presentMenu = new EventEmitter();
  @Output() public updatePage = new EventEmitter();

  public otherMemberButtonText: string;
  public selectedPage = 0;
  public numberOfPages: number;
  public enableShowPrevious = false;
  public enableShowNext = false;

  private lastLoadedView: 'future' | 'past';

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.view !== this.lastLoadedView) {
      this.selectedPage = 0;
    }
    this.lastLoadedView = this.view;
    if (this.view === 'future') {
      this.otherMemberButtonText = 'View appointments and join video for another member';
    } else {
      this.otherMemberButtonText = 'View past appointments for another member';
    }
    if (this.appointments && this.appointments.count > this.pageSize) {
      this.evaluateNextPreviousPagingButtons();
    }
  }

  public incrementPage(increment: number) {
    this.selectedPage = this.selectedPage + increment;
    this.updatePage.emit(this.selectedPage);
    this.evaluateNextPreviousPagingButtons();
  }

  private evaluateNextPreviousPagingButtons() {
    this.numberOfPages = Math.ceil(this.appointments.count / this.pageSize);
    const isLastPage = this.selectedPage === this.numberOfPages - 1;
    this.enableShowPrevious = this.selectedPage > 0;
    this.enableShowNext = this.appointments.count > this.pageSize && !isLastPage;
  }
}
