<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Book Appointment</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="content-override" overflow-scroll="false">
  <div class="container">
    <div class="form-view" #content>
      <mat-horizontal-stepper [linear]="true" #stepper labelPosition="bottom" (selectionChange)="stepChanged()">
        <mat-step *ngIf="isInitialized" [completed]="isVisitInfoComplete">
          <ng-template matStepLabel>Visit Info</ng-template>
          <app-select-state-form
            *ngIf="showStateSelect"
            [form]="selectStateFormGroup"
            [states]="displayStates"
          ></app-select-state-form>
          <app-invalid-state-form
            *ngIf="showInvalidStateSelected"
            [form]="invalidStateFormGroup"
          ></app-invalid-state-form>
          <app-select-service-line-form
            *ngIf="showServiceLineSelect"
            [form]="selectServiceLineFormGroup"
            [serviceLinesEligibility]="serviceLinesEligibility"
          ></app-select-service-line-form>
          <app-select-patient-form
            *ngIf="showPatientSelect"
            [form]="selectPatientFormGroup"
            [patients]="patients"
            [serviceLinesEligibility]="serviceLinesEligibility"
            [selectedServiceLine]="this.selectServiceLineFormGroup.value.selectedServiceLine"
          ></app-select-patient-form>
        </mat-step>
        <mat-step *ngIf="isInitialized" [stepControl]="patientServiceLineIntakeFormGroup">
          <ng-template matStepLabel>
            <div appLongPress (longPressOccurred)="handleLongPressToSkipIntake()">Intake</div>
          </ng-template>
          <p>Please complete an intake form for each patient who will be a part of this visit.</p>
          <mat-accordion class="ph-no-capture">
            <mat-expansion-panel
              *ngFor="let patient of selectedPatients; let i = index"
              (opened)="openPanel(i)"
              (closed)="closePanel()"
              [expanded]="i === expandedIntakeFormIndex"
            >
              <mat-expansion-panel-header>
                <ion-row class="ion-align-items-center">
                  <ion-col>
                    <mat-icon
                      [color]="getIntakeFormIconColor(patient.id)"
                      [ngStyle]="getIntakeFormIconColor(patient.id) === 'gray' ? { color: 'lightgray' } : {}"
                      style="position: relative; top: 3px; left: -3px"
                    >
                      {{ getIntakeFormIcon(patient.id) }}
                    </mat-icon>
                  </ion-col>
                  <ion-col size="auto">
                    <h3>
                      <span class="ph-no-capture">
                        {{ patient.name }}
                      </span>
                    </h3>
                  </ion-col>
                </ion-row>
              </mat-expansion-panel-header>
              <p
                style="color: red"
                *ngIf="patientServiceLineIntakeFormGroup.controls[patient.id].errors?.eligiblePatient"
              >
                This patient is not eligible for Nice Healthcare in their current location
              </p>
              <app-intake-form
                [hidden]="activeHealthHistoryIntakeForm === null"
                #healthHistoryIntakeForm
                [formSchema]="healthHistoryIntakeFormSchema"
                [model]="this.healthHistoryPrepopulatedAnswers[patient.id]"
                [patient]="patient"
                (formState)="handleHealthHistoryIntakeFormChange(patient.id, $event)"
              >
              </app-intake-form>
              <app-intake-form
                [hidden]="activeHealthHistoryIntakeForm !== null"
                #serviceLineIntakeForm
                [model]="this.serviceLinePrepopulatedAnswers[patient.id]"
                [formSchema]="serviceLineIntakeFormSchema"
                [patient]="patient"
                (formState)="handleServiceLineIntakeFormChange(patient.id, $event)"
              >
              </app-intake-form>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="margin-maker"></div>
        </mat-step>
        <mat-step *ngIf="isInitialized" [stepControl]="confirmAppointmentFormGroup">
          <ng-template matStepLabel>Contact</ng-template>
          <app-appointment-confirmation-form [form]="confirmAppointmentFormGroup" [inputColor]="'primary'">
          </app-appointment-confirmation-form>
          <ion-row class="ion-justify-content-end">
            <ion-col class="ion-text-end">
              <button
                mat-button
                [disabled]="confirmAppointmentFormGroup.invalid"
                mat-raised-button
                matStepperNext
                color="accent"
              >
                Next
              </button>
            </ion-col>
          </ion-row>
        </mat-step>

        <mat-step *ngIf="isInitialized" [stepControl]="scheduleAppointmentFormGroup">
          <ng-template matStepLabel>
            <div appLongPress (longPressOccurred)="handleLongPressToSimulateError()">Schedule</div>
          </ng-template>
          <app-appointment-scheduling-form
            [form]="scheduleAppointmentFormGroup"
            [selectedState]="this.selectStateFormGroup.value.selectedState"
            [intakeSubmissionId]="serviceLineIntakeSubmissionId"
            [schedulingErrorState]="schedulingErrorState"
            (resetErrorState)="handleErrorStateReset()"
          >
          </app-appointment-scheduling-form>

          <ion-row class="ion-justify-content-end">
            <ion-col class="ion-text-end">
              <button
                mat-button
                [disabled]="!readyToSubmit"
                mat-raised-button
                matStepperNext
                color="accent"
                (click)="submit()"
              >
                Submit
              </button>
            </ion-col>
          </ion-row>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="nav-view">
      <!-- Select state button. -->
      <div class="navigation-buttons-container" *ngIf="this.showStateSelect">
        <ion-button
          color="accent"
          [disabled]="!this.selectStateFormGroup.value.selectedState"
          (click)="handleCustomFormNext()"
        >
          Next
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- Invalid state button. -->
      <div class="navigation-buttons-container" *ngIf="this.showInvalidStateSelected">
        <ion-button color="accent" (click)="closeDialog()">
          Done
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- Invalid mental health selection button. -->
      <div class="navigation-buttons-container" *ngIf="this.showInvalidMentalHealthSelected">
        <ion-button color="accent" (click)="closeDialog()">
          Done
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- Select service line buttons. -->
      <div class="navigation-buttons-container" *ngIf="this.showServiceLineSelect">
        <ion-button color="medium" (click)="handleCustomFormBack()">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Back
        </ion-button>
        <ion-button
          color="accent"
          [disabled]="!this.selectServiceLineFormGroup.value.selectedServiceLine"
          (click)="handleCustomFormNext()"
        >
          Next
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- Patient select buttons. -->
      <div class="navigation-buttons-container" *ngIf="this.showPatientSelect">
        <ion-button color="medium" (click)="handleCustomFormBack()">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Back
        </ion-button>
        <ion-button
          color="accent"
          [disabled]="
            !this.selectPatientFormGroup.value.patientIds || this.selectPatientFormGroup.value.patientIds.length === 0
          "
          (click)="handleCustomFormNext()"
        >
          Next
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- The buttons for the health history intake. -->
      <div
        class="navigation-buttons-container"
        *ngIf="
          !this.showStateSelect &&
          !this.showServiceLineSelect &&
          !this.showPatientSelect &&
          activeHealthHistoryIntakeForm !== null &&
          activeHealthHistoryIntakeForm !== undefined &&
          activeHealthHistoryIntakeForm.form !== null &&
          intakeActive
        "
      >
        <ion-button color="medium" (click)="back()">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Back
        </ion-button>
        <ion-button
          color="accent"
          [disabled]="
            activeHealthHistoryIntakeForm &&
            activeHealthHistoryIntakeForm.form &&
            activeHealthHistoryIntakeForm.form.invalid
          "
          (click)="next()"
        >
          {{ currentButtonText }}
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>

      <!-- The buttons for the service line intake steps. -->
      <div
        class="navigation-buttons-container"
        *ngIf="
          !this.showStateSelect &&
          !this.showServiceLineSelect &&
          !this.showPatientSelect &&
          !this.showInvalidMentalHealthSelected &&
          activeHealthHistoryIntakeForm === null &&
          activeServiceLineIntakeForm !== null &&
          activeServiceLineIntakeForm.form !== null &&
          intakeActive
        "
      >
        <ion-button color="medium" (click)="back()">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Back
        </ion-button>
        <ion-button
          color="accent"
          [disabled]="
            activeServiceLineIntakeForm && activeServiceLineIntakeForm.form && activeServiceLineIntakeForm.form.invalid
          "
          (click)="next()"
        >
          {{ currentButtonText }}
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
