import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isSupported } from 'twilio-video';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  // Default interface options. Results in:
  // 1) Support of text wrapping for long labels
  // 2) Always showing Android styling to accomplish left-sided check per Thompson's request
  public interfaceOptions = {
    cssClass: 'alert-select-options-wrap-text',
    mode: 'md',
  };

  constructor(private platform: Platform) {}

  public checkIfPlatformSupported(): {
    isSupported: boolean;
    message?: string;
  } {
    // Skip for local environment or unit testing.
    if (environment.returnToUrl === 'http://localhost:8100') {
      return { isSupported: true };
    } else if (!isSupported) {
      if (this.platform.is('ios')) {
        // Skip the Twilio nested checks for native iOS (iPad failure).
        if (window && window.navigator && /AppleWebKit|Safari|iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
          return { isSupported: true };
        } else {
          return {
            isSupported: false,
            message: 'Video visits are not supported in your browser. Please use Safari on your iOS device.',
          };
        }
      } else if (this.platform.is('android')) {
        // Skip the Twilio nested checks for native Android.
        if (window && window.navigator && /Chrome|CriOS/.test(window.navigator.userAgent)) {
          return { isSupported: true };
        } else {
          return {
            isSupported: false,
            message: 'Video visits are not supported in your browser. Please use Chrome on your Android device.',
          };
        }
      } else {
        return {
          isSupported: false,
          message: 'Video visits are not supported in your browser. Please use Chrome or Safari.',
        };
      }
    } else {
      return { isSupported: true };
    }
  }
}
