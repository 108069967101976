<ion-list [formGroup]="form">
  <ion-label>
    <h3>Address (Required)</h3>
  </ion-label>
  <div class="ion-margin-vertical" *ngIf="showAddressReset">
    <ion-button shape="round" color="secondary" class="copy-button" (click)="setAddressToAccountDefault()">
      <!-- This actually copies the current _USER_ address -->
      Copy Primary Account Address
    </ion-button>
  </div>
  <ion-item>
    <ion-input label="Address Line 1*" labelPlacement="floating" required formControlName="line1"></ion-input>
  </ion-item>
  <span
    class="input-error"
    *ngIf="
      form.controls.line1.invalid && form.controls.line1.touched && !form.controls.line1.hasError('forbiddenCharacters')
    "
    >Address is required</span
  >
  <span class="input-error" *ngIf="form.controls.line1.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>
  <ion-item>
    <ion-input label="Address Line 2" labelPlacement="floating" formControlName="line2"></ion-input>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.line2.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>
  <ion-item>
    <ion-input
      label="City*"
      labelPlacement="floating"
      required
      type="text"
      autocapitalize="words"
      formControlName="city"
    ></ion-input>
  </ion-item>
  <span
    class="input-error"
    *ngIf="
      form.controls.city.invalid && form.controls.city.touched && !form.controls.city.hasError('forbiddenCharacters')
    "
    >City is required</span
  >
  <span class="input-error" *ngIf="form.controls.city.hasError('forbiddenCharacters')">
    {{ forbiddenErrorMessage }}
  </span>
  <ion-item>
    <ion-input label="Zip*" labelPlacement="floating" required formControlName="zip" inputmode="numeric"></ion-input>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.zip.invalid && form.controls.zip.touched"
    >Zip code is required and should be a 5 digit number</span
  >
  <ion-item>
    <ion-select required label="State*" labelPlacement="floating" formControlName="state" placeholder="Select a state">
      <ion-select-option *ngFor="let state of states | enum" [value]="state.key">{{ state.value }}</ion-select-option>
    </ion-select>
  </ion-item>
  <span class="input-error" *ngIf="form.controls.state.invalid && form.controls.state.touched">State is required</span>
</ion-list>
