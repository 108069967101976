export enum Urgency {
  sameDay = 'Same day',
  twoToThreeDays = '2-3 days',
  fourToFiveDays = '4-5 days',
  oneWeek = '1 week',
  oneToTwoWeeks = '1-2 weeks',
  twoToThreeWeeks = '2-3 weeks',
  oneMonth = '1 month',
  fiveToSixWeeks = '5-6 weeks',
  twoMonths = '2 months',
  threeMonths = '3 months',
  sixMonths = '6 months',
  oneYear = '1 year',
}

export type UrgencyKeys = keyof typeof Urgency;
