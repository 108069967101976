import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-nav',
  templateUrl: './page-nav.component.html',
  styleUrls: ['./page-nav.component.scss'],
})
export class PageNavComponent implements OnInit {
  @Input() showText = true;
  @Input() pageCount: number;
  @Output() pageChange = new EventEmitter();

  public page = 0;

  constructor() {}

  ngOnInit() {}

  nextPage() {
    if (this.page < this.pageCount) {
      this.page++;
      this.pageChange.emit(this.page);
    }
  }

  prevPage() {
    if (this.page > 0) {
      this.page--;
      this.pageChange.emit(this.page);
    }
  }

  resetPage() {
    if (this.page !== 0) {
      this.page = 0;
      this.pageChange.emit(this.page);
    }
  }
}
