import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap, throttleTime } from 'rxjs/operators';

import { PatientService } from '../../core/services/patient.service';

import { BookAppointmentDialogComponent } from './book-appointment-dialog/book-appointment-dialog.component';
import { IntakeFormService } from '../../core/services/intake-form.service';
import { AlertService } from '../../core/services/alert.service';
import { Patient } from '@models';
import { AppointmentService, PatientAppointment } from '../../core/services/appointment.service';
import { PaginatedResponse } from '../../core/types/appointments';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments-page.component.html',
  styleUrls: ['./appointments-page.component.scss'],
})
export class AppointmentsPageComponent implements OnInit, OnDestroy {
  public futureAppointments: { data: PatientAppointment[]; count: number };
  public pastAppointments: { data: PatientAppointment[]; count: number };
  public pageSize = 3;

  public patients: Observable<Patient[]>;
  public activePatient: Observable<Patient>;
  public activePatientId: number;

  public appointmentView: 'past' | 'future' = 'future';
  public loadingIntakeForm = false;

  public bookingOnly = false;

  private triggerRefresh = new Subject<boolean>();
  private destroyed = new Subject<boolean>();

  constructor(
    private alertService: AlertService,
    public patientService: PatientService,
    public appointmentService: AppointmentService,
    private menu: MenuController,
    private modalController: ModalController,
    public route: ActivatedRoute,
    public router: Router,
    private intakeFormService: IntakeFormService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.patients = this.patientService.patients;
    this.activePatient = this.patientService.activePatient;

    this.bookingOnly = this.route.snapshot.queryParamMap.get('booking') === 'true';

    this.patientService.activePatient.pipe(takeUntil(this.destroyed)).subscribe((patient: Patient) => {
      if (patient) {
        this.activePatientId = patient.id;
        this.refresh(0);
      }
    });

    this.appointmentService.triggerAppointmentRefresh
      .pipe(
        takeUntil(this.destroyed),
        tap(() => {
          this.triggerRefresh.next(true);
        })
      )
      .subscribe();

    this.triggerRefresh
      .pipe(
        throttleTime(2 * 1000),
        takeUntil(this.destroyed),
        tap(() => {
          this.refresh(0);
        })
      )
      .subscribe();

    // Trigger appointment refresh when the app is resumed from being in the background
    this.platform.resume.pipe(takeUntil(this.destroyed)).subscribe(() => this.triggerRefresh.next(true));

    // Initialize intake forms in the background.  This will cache results for when the user actually clicks on
    // appointments button and reaches that form in the flow.
    this.intakeFormService.getHealthHistoryForm();
    this.intakeFormService.getPrimaryCareForm();
    this.intakeFormService.getMentalHealthTherapyForm();
    this.intakeFormService.getPhysicalTherapyForm();

    if (this.bookingOnly) {
      this.startAppointmentBookingFlow();
    }
  }

  public async refresh(page: number, viewToRefresh?: 'future' | 'past') {
    if (viewToRefresh) {
      this.refreshAppointments(page, viewToRefresh);
    } else {
      // Refresh both
      this.refreshAppointments(page, 'future');
      this.refreshAppointments(page, 'past');
    }
  }

  async startAppointmentBookingFlow() {
    try {
      this.loadingIntakeForm = true;
      // We really don't need to load this here, but this is done to ensure that we will have the first intake form
      // ready when the user gets there.  This spinner UI and logic is legacy code and should be moved into the dialog.
      const healthHistoryForm = await this.intakeFormService.getHealthHistoryForm();
      const dynamicFormRetrieved = !!healthHistoryForm;

      if (dynamicFormRetrieved) {
        const bookingModal = await this.modalController.create({
          component: BookAppointmentDialogComponent,
          componentProps: {
            healthHistoryIntakeForm: healthHistoryForm,
          },
        });

        await bookingModal.present();
        await bookingModal.onDidDismiss().then((data) => {
          this.refreshAppointments(0, 'future');
          if (this.bookingOnly) {
            window.open(environment.newWebUrl + '/appointments', '_self');
          }
        });
      } else {
        console.error('Error with book appointment flow: unable to retrieve intake form.');
        this.alertService.error('Error with book appointment flow, please contact Nice support if this persists');
      }
    } catch (error) {
      console.error('Error with book appointment flow', error);
      this.alertService.error('Error with book appointment flow, please contact Nice support if this persists');
    } finally {
      this.loadingIntakeForm = false;
    }
  }

  presentMenu() {
    this.menu.open();
  }

  updatePage(page: number) {
    this.refresh(page, this.appointmentView);
  }

  public async setView(view: 'future' | 'past') {
    this.appointmentView = view;
    this.refreshAppointments(0, view);
  }

  public handleRefreshEvent(event) {
    this.triggerRefresh.next(true);
    event.target.complete();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  private setPaginatedAppointments(
    view: 'future' | 'past',
    appointments: { data: PatientAppointment[]; count: number }
  ) {
    if (view === 'future') {
      this.futureAppointments = appointments;
    } else {
      this.pastAppointments = appointments;
    }
  }

  private refreshAppointments(page: number, viewToRefresh?: 'future' | 'past') {
    if (this.activePatient) {
      this.appointmentService
        .getAppointments(this.activePatientId, page, this.pageSize, viewToRefresh)
        .subscribe((paginatedAppointments: PaginatedResponse<PatientAppointment>) => {
          this.setPaginatedAppointments(viewToRefresh, {
            data: paginatedAppointments.content,
            count: paginatedAppointments.totalElements,
          });
        });
    } else {
      console.error('Unable to refresh appointments for unknown patient.');
    }
  }
}
