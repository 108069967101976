import { UserDto } from '../dto/user';
import { UserAddressDto } from '../dto/user-address';
import { MembershipDto } from '../dto/membership';

export class User {
  public readonly id: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly phone: string;
  public readonly membership?: MembershipDto;
  public readonly addresses: UserAddressDto[];

  constructor(data: UserDto) {
    Object.assign(this, data);
  }

  get membershipType() {
    return this.membership ? this.membership.membershipType : undefined;
  }
}
