import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { Patient } from '@models';
import { ServiceLineEligibility } from '@dto';
import { ServiceLine } from '@enums';

export interface SelectPatientFormValues {
  patientIds: number[] | number;
}

@Component({
  selector: 'app-select-patient-form',
  templateUrl: './select-patient-form.component.html',
  styleUrls: ['./select-patient-form.component.scss'],
})
export class SelectPatientFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() patients: Patient[];
  @Input() serviceLinesEligibility: ServiceLineEligibility[];
  @Input() selectedServiceLine: ServiceLine;
  @Input() public placeholder = 'Who is this appointment for?';
  @Input() public hint = 'Please select every person that needs to be seen.';

  public eligibilityMap: any;
  public isMultiPatientCapable: boolean = true;

  constructor(public utilityService: UtilityService) {}

  static formModel(initValues?: SelectPatientFormValues) {
    return new UntypedFormGroup({
      patientIds: new UntypedFormControl(initValues ? initValues.patientIds : [], Validators.required),
    });
  }

  ngOnInit() {
    this.eligibilityMap = {};

    for (let i = 0; i < this.serviceLinesEligibility.length; i++) {
      if (ServiceLine[this.serviceLinesEligibility[i].serviceLine] === this.selectedServiceLine) {
        // Set service line eligibility.
        this.isMultiPatientCapable = this.serviceLinesEligibility[i].isMultiPatientCapable;

        // Set up individual patient eligibility.
        for (let j = 0; j < this.serviceLinesEligibility[i].patientEligibility.length; j++) {
          this.eligibilityMap[this.serviceLinesEligibility[i].patientEligibility[j].patientId] = {
            eligible: this.serviceLinesEligibility[i].patientEligibility[j].eligible,
            reason: this.getIneligibilityReason(this.serviceLinesEligibility[i].patientEligibility[j].reasons),
          };
        }
        break;
      }
    }
  }

  private getSelectedServiceLineName() {
    switch (this.selectedServiceLine) {
      case ServiceLine.PRIMARY_CARE:
        return 'Primary Care';
      case ServiceLine.PHYSICAL_THERAPY:
        return 'Physical Therapy';
      case ServiceLine.MENTAL_HEALTH:
        return 'Mental Health Therapy';
      default:
        console.error('Invalid service line: ' + this.selectedServiceLine);
    }
  }

  private getIneligibilityReason(reasons: string[] | null | undefined): string | null {
    const ineligibilityReasonCode: string | null = reasons && reasons.length > 0 ? reasons[0] : null;

    if (!ineligibilityReasonCode) {
      return null;
    } else if (ineligibilityReasonCode === 'NOT_YET_8_WEEKS') {
      return this.getSelectedServiceLineName() + ' is not available for patients under 8 weeks';
    } else if (ineligibilityReasonCode === 'NOT_YET_12') {
      return this.getSelectedServiceLineName() + ' is not available for patients under 12';
    } else if (ineligibilityReasonCode === 'NOT_YET_18') {
      return this.getSelectedServiceLineName() + ' is not available for patients under 18';
    } else {
      return null;
    }
  }
}
