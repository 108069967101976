import { Component, Input, Output, EventEmitter } from '@angular/core';

export type MatColor = 'primary' | 'accent' | 'warn';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
})
export class ProgressButtonComponent {
  @Input() public disabled: boolean;
  @Input() public color: MatColor = 'primary';
  @Input() public submitting: boolean;
  @Input() public text = 'Submit';
  @Input() public shape = 'round';

  @Output() public submit = new EventEmitter();

  public onSubmit() {
    this.submit.emit('click');
  }
}
