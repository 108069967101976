import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../../core/services/alert.service';
import { Router } from '@angular/router';
import { PatientService } from '../../../core/services/patient.service';
import { ModalController } from '@ionic/angular';
import { Patient, PatientAddressDto } from '@dto';
import { Patient as LegacyPatient } from '@models';
import { UntypedFormControl } from '@angular/forms';
import { PhoneNumberPipe } from '../../../core/pipes/phone-number.pipe';

const phoneNumberTransformer = new PhoneNumberPipe();

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss'],
})
export class EditPatientComponent implements OnInit {
  public form = new UntypedFormControl(null);
  @Input() public editForm;
  @Input() patientId: string; // from modal componentProps
  public patient: LegacyPatient;
  private existingAddress: PatientAddressDto;

  constructor(
    private alertService: AlertService,
    private modalController: ModalController,
    private patientService: PatientService,
    public router: Router
  ) {}

  ngOnInit() {
    this.patient = this.patientService.getPatient(parseInt(this.patientId, 10));
    this.form.patchValue({
      ...this.patient,
      confirmEmail: this.patient.email,
      sexAssignedAtBirth:
        this.patient.sexAssignedAtBirth && this.patient.sexAssignedAtBirth.toString() !== 'UNSPECIFIED'
          ? this.patient.sexAssignedAtBirth.toString()
          : null,
      race: this.patient.race?.toString() || null,
      relationToAccountHolder: this.patient.relationToAccountHolder?.toString() || null,
      address: this.patient.addresses[0]
        ? {
            line1: this.patient.addresses[0].address1,
            line2: this.patient.addresses[0].address2,
            city: this.patient.addresses[0].city,
            state: this.patient.addresses[0].state,
            zip: this.patient.addresses[0].zip,
          }
        : null,
      phone: this.patient.phone ? phoneNumberTransformer.transform(this.patient.phone) : null,
    });

    this.existingAddress =
      this.patient && this.patient.addresses && this.patient.addresses.length ? this.patient.addresses[0] : null;
  }

  public async submit() {
    try {
      this.alertService.loading();
      const patientData: Patient = {
        id: parseInt(this.patientId, 10),
        address: this.form.value.address,
        dateOfBirth: this.form.value.dateOfBirth,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        preferredName: this.form.value.preferredName,
        sexAssignedAtBirth: this.form.value.sexAssignedAtBirth,
        gender: this.form.value.gender,
        race: this.form.value.race,
        relationToAccountHolder: this.form.value.relationToAccountHolder,
        phone: this.form.value.phone?.replace(/[^A-Za-z0-9]+/gi, ''),
        email: this.form.value.email,
      };

      const updatedPatient = await this.patientService.updatePatient(patientData).toPromise();
      this.alertService.success(`${updatedPatient.firstName} ${updatedPatient.lastName} updated!`);
      this.dismiss();
    } catch (error) {
      console.error(error);
      this.alertService.error('Error updating account. Please try again.');
    } finally {
      this.alertService.hideLoading();
    }
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
