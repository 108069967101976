import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PatientDto } from '../../../../../../core/dto/patient';

export interface AppointmentConfirmationFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

@Component({
  selector: 'app-appointment-confirmation-form',
  templateUrl: './appointment-confirmation-form.component.html',
  styleUrls: ['./appointment-confirmation-form.component.scss'],
})
export class AppointmentConfirmationFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() inputColor: 'primary' | 'tertiary' = 'tertiary';

  constructor() {}

  static formModel(form: Partial<AppointmentConfirmationFormValues> | PatientDto | Partial<PatientDto> | any = {}) {
    return new UntypedFormGroup({
      firstName: new UntypedFormControl(form ? form.firstName : null, Validators.required),
      lastName: new UntypedFormControl(form ? form.lastName : null, Validators.required),
      phone: new UntypedFormControl(form ? form.phone : null, Validators.required),
      email: new UntypedFormControl(
        form ? form.email : null,
        Validators.compose([Validators.required, Validators.email])
      ),
    });
  }
  ngOnInit() {}
}
