import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

import { Document } from '@models';
import { DocumentService } from '../../../core/services/document.service';

@Component({
  selector: 'app-display-component',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent implements OnInit {
  @Input() document: Document;
  @Input() url;

  dataUrl: SafeUrl;
  isLoading = true;
  hasError = false;
  isImage = true;

  public zoom = 100;
  public zoomMax = 300;
  private zoomIncrement = 25;

  constructor(
    private documentService: DocumentService,
    private sanitize: DomSanitizer,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    const type = this.document.metadata.contentType;
    if (type.startsWith('image')) {
      this.isImage = true;
    } else {
      this.isImage = false;
    }

    this.documentService
      .viewFile(this.url, this.document.metadata)
      .then((responseUrl) => {
        if (responseUrl) {
          // the assumption here is that the document uploaded is considered safe.
          return this.sanitize.bypassSecurityTrustUrl(responseUrl); // nosemgrep: typescript.angular.security.audit.angular-domsanitizer.angular-bypasssecuritytrust
        }
      })
      .then((trustedUrl) => {
        this.isLoading = false;
        this.dataUrl = trustedUrl;
      })
      .catch((err) => {
        console.error('Error rendering document.', err);
        this.isLoading = false;
        this.hasError = true;
      });
  }

  public async downloadFile() {
    const name = this.document.name;
    const file = await fetch((this.dataUrl as any).changingThisBreaksApplicationSecurity);
    const blob = await file.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.setAttribute('download', name);
    downloadLink.click();
  }

  /**
   * Necessary workaround as ng2-pdf-viewer doesn't properly set target attribute on link
   * see: https://github.com/VadimDez/ng2-pdf-viewer/issues/215
   */
  public openExternalLinksInNewTab(event: any): void {
    const textLayer = event.source.textLayerDiv;
    const annotationLayer = textLayer.nextElementSibling;
    const anchorElements = annotationLayer?.getElementsByTagName('a');
    if (anchorElements?.length) {
      for (let i = 0; i < anchorElements.length; i++) {
        anchorElements[i].setAttribute('target', '_blank');
      }
    }
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public zoomIn() {
    this.zoom = Math.round(Math.min(this.zoom + this.zoomIncrement, this.zoomMax));
  }

  public zoomOut() {
    this.zoom = Math.round(Math.max(this.zoom - this.zoomIncrement, 100));
  }

  public resetZoom() {
    this.zoom = 100;
  }
}
