import { NgModule } from '@angular/core';

import { SessionRoutingModule } from './session-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoginPageComponent } from './login/login-page.component';
import { CallbackPageComponent } from './callback/callback-page.component';
import { SignUpPageComponent } from './sign-up/sign-up-page.component';
import { LogoutPageComponent } from './logout/logout-page.component';

@NgModule({
  declarations: [LoginPageComponent, SignUpPageComponent, CallbackPageComponent, LogoutPageComponent],
  imports: [SharedModule, SessionRoutingModule],
})
export class SessionModule {}
